export default {
  label: {
    activate: "Activer",
    addMember: "Ajouter membre",
    all: "Tout",
    allTabsWithOrders: "Tout, y compris les commandes",
    amount: "Montant",
    applicationVersion: "Version de l'application",
    calculateReturn: "Calculer retour",
    cancel: "Annuler",
    cashier: "Caisse",
    cashierSettings: "Paramètres de la caisse",
    cashReceived: "Argent reçu",
    cashToReturn: "Argent retour",
    check: "Vérifier",
    close: "Fermer",
    closeApp: "Fermer",
    configuration: "Paramètres",
    confirm: "Oui, je suis sûr",
    confirm_pickup: "Collection OK",
    confirm_service: "Livraison OK",
    correction: "Correction",
    currentOrders: "Commandes en cours",
    delegatePreparation: "Déléguer préparation",
    delegateServiceDelivery: "Déléguer livraison",
    delivered: "Delivré",
    deviceName: "Nom de l'appareil",
    disabledProduct: "Produit désactivé",
    emailAddress: "Adresse email",
    factoryReset: "Réinitialiser",
    forceQuit: "Arrêt forcé",
    freePlan: "Plan gratuit",
    initiator: "Initiateur",
    locale: "Langue",
    login: "Connexion",
    name: "Nom",
    newWaiter: "Nouveau serveur",
    noFavorite: "Aucune préférence",
    noTabs: "Aucun",
    orderedOnline: "Commandé en ligne",
    orderReference: "Référence",
    orderShortId: "Numéro",
    otherCashier: "Autre caisse",
    password: "Mot de passe",
    pay: "Payer",
    pickup_ongoing: "Collection en cours",
    pincode: "Code PIN",
    preparation_ongoing: "Préparation en cours",
    preparedAndDelivered: "Prêt et delivré",
    product: "Produit",
    products: "Produits",
    proposeAll: "Suggérer tous les lecteurs de cartes disponibles pour actions Topupz",
    proposeFavorites: "Ne suggérer que ces lecteurs de cartes s'ils sont disponibles",
    refund: "Retour",
    register: "S'inscrire",
    requestPickup: "A collecter",
    reset: "Zone de danger",
    save: "Enregistrer",
    scanners: "Lecteurs de cartes",
    search: "Rechercher",
    selectProducts: "Sélection de produits",
    serial: "Numéro de série",
    service_ongoing: "Service en cours",
    settings: "Paramètres",
    status: "Statut",
    switchDeliveryTo_pickup: "Changer en 'a collecter'",
    switchDeliveryTo_service: "Changer en 'a servir'",
    tabsOngoing: "Onglets avec traitement ou retour d'information",
    tabsWithOrders: "Onglets avec commandes",
    tenantId: "Identifiant du club",
    tenantShortName: "Nom du club raccourci",
    timestamp: 'Horodatage',
    to_enter: "A Entrer",
    to_prepare: "A Préparer",
    to_serve: "A servir",
    topup: "Recharge",
    topupAmount: "Recharger la carte: € {amount}",
    updateLater: "Plus tard",
    updateNow: "Mettre à jour maintenant",
    username: "Nom d'utilisateur",
    waiting_for_card_scan: "Scanner la carte",
    waiting_for_online_payment: "Paiement en ligne",
    waiting_for_qr_payment: "Paiement par QR",
    waiting_for_pickup: "A Collecter",
    whichScanner: "Sélectionnez le lecteur de carte à utiliser pour le paiement."
  },
  message: {
    activityDuringClose: "O-oh! Il y avait encore du traitement à la clôture",
    allTabsUsed: "Tous les onglets sont utilisés!",
    autoRetry: "Nous continuons d'essayer...",
    canceled_by_cashier: "Annulé par le serveur",
    canceled_by_user: "Annulé par l'utilisateur",
    cancelFailed: "L'annulation a échoué, veuillez réessayer",
    card_scan_timeout: "Le temps s'est écoulé",
    changeSubscription: "Vous pouvez modifier votre plan d'abonnement dans Topupz pour les clubs.",
    chooseRestoreMethod: "Quels onglets doivent être restaurés?",
    confirmClosePendingProcesses: "Il y a encore du traitement en cours ou des retours d'information qui n'ont pas encore été confirmés!",
    confirmTransfer: "Êtes-vous sûr de vouloir reprendre la commande ici? Cette commande disparaîtra sur l'onglet existant du serveur actuel.",
    connectionIssue: "Erreur de connexion",
    disabledByTenant: "Option inactive",
    disabled_card: "Carte non active",
    disabledProduct: "Le produit sélectionné a été désactivé et ne peut pas être vendu. Veuillez cliquer sur le bouton ci-dessous pour le réactiver.",
    errorTryAgain: "Erreur! Veuillez réessayer",
    insufficient_funds: "Solde insuffisant",
    lastTimestampBeforeClose: "Le dernier traitement a eu lieu le {timestamp}.",
    limitedOfflineActions: "La caisse est hors ligne, seules des actions limitées sont possibles",
    membershipRequestAlreadySent: "Une demande d'adhésion a déjà été envoyée",
    membershipRequestError: "Une erreur s'est produite lors de l'envoi de la demande d'adhésion",
    membershipRequestForMember: "Quelqu'un avec la même adresse e-mail est déjà membre",
    membershipRequestSent: "Une demande d'adhésion a été envoyée",
    noCurrentOrders: "Pas de commandes en cours",
    noProducts: "En attente de produits",
    noProductsInCategory: "Aucun produit dans cette catégorie. Ceux-ci peuvent être définis via Topupz pour les clubs.",
    noQrCodePayment: "Pas de QR lié",
    noScanners: "Il n'y a actuellement aucun scanneur. S'il devrait y en avoir, veuillez vérifier la connexion",
    noScannersConfigured: "Pas de scanneur",
    offlineOrderCancel: "La commande sera annulé dès que la connexion sera rétablie",
    offlineOrderProcessing: "Commande enregistrée hors ligne pour un traitement ultérieur ...",
    offlineTopupCancel: "La recharge sera annulé dès que la connexion sera rétablie",
    online_payment_canceled: "Le paiement en ligne a été annulé",
    online_payment_failed: "Le paiement en ligne a échoué",
    online_payment_timeout: "Le paiement en ligne a expiré",
    onlyOffline: "Veuillez effectuer uniquement des paiements en CASH (mode hors ligne)",
    orderIsBeingProcessed: "La commande est déjà en cours de traitement",
    orderIsNotTransferable: "La commande ne peut pas être transférée car la caisse correspondante est hors ligne",
    orderOk: "La commande a été traitée et le paiement est OK",
    performOfflineCash: "Demandez au client de payer en espèces",
    pickup_ongoing: "Veuillez vérifier le numéro ou la référence avant que le client collecte la commande",
    preparation_ongoing: "Veuillez préparer la commande",
    processedByOtherCashier: "La commande ne peut plus être traitée lors de cette caisse",
    productUpdate: "Vous avez reçu une notification de produits! Voulez-vous les mettre à jour immédiatement?",
    registration1: "Veuillez enregistrer la caisse en vous connectant en tant qu'administrateur.",
    registration2: "Ensuite, vous pouvez définir le nom de la caisse, d'autres paramètres sont disponibles dans Topupz pour les clubs.",
    registration3: "Le code PIN par défaut est '1111'.",
    remainingFreeOrders: "Toutes les commandes de l'abonnement gratuit ont été traitées pour aujourd'hui et seront à nouveau disponibles demain. | Aujourd'hui, il reste 1 commande à passer dans l'abonnement gratuit. | Aujourd'hui, il reste {remainingFreeOrders} commandes à passer dans l'abonnement gratuit.",
    removeWaiter: "Supprimer le serveur",
    required: "Obligatoire",
    resetExplanation: "Tous les paramètres seront perdus, ainsi que toute synchronisation et retours d'information des transactions en cours!",
    restoreNbrNotStarted: "Il y avait {nbrNotStarted} onglets avec des commandes introduites.",
    restoreNbrNotStartedOne: "Il y avait 1 onglet avec une commandes introduite.",
    restoreNbrOngoing: "{nbrOngoing} traitements ou retours d'information non confirmés.",
    restoreNbrOngoingOne: "1 traitement ou retour d'information non confirmé.",
    restoreTopupBusy: "Une recharge de carte était en cours",
    retry: "Veuillez réessayer",
    retryOrReturnCash: "Veuillez réessayer ou rembousez le client",
    returnCash: "Veuillez retourner l'argent au client",
    scanner_busy: "Le lecteur de carte est occupé",
    scannerBusy: "Scanneur occupé",
    scanner_gone: "Lecteur de carte non disponible",
    selectWaiterToContinueOrder: "Sélectionnez le serveur (onglet) pour poursuivre le traitement de la commande sur ce caisse",
    service_ongoing: "Veuillez apporter la commande au client",
    to_prepare: "Veuillez préparer la commande ou la déléguer",
    topupOk: "La recharge a été traité et le paiement est OK",
    unknown: "Erreur inconnue",
    unknown_card: "Carte inconnue",
    unremovableWaiter: {
      error: "Le serveur ne peut pas être supprimé",
      reason_pendingOrders: "Il y a des traitements en cours ou des retours d'information non confirmés."
    },
    waiting_for_card_scan: "Demandez au client de scanner la carte",
    waiting_for_online_payment: "En attente de paiement en ligne",
    waiting_for_qr_payment: "En attente de paiement QR",
    waitingForMatchingCashierId: "Veuillez attendre que la commande soit transférée vers cette caisse",
    warning: "Attention!"
  }
}
