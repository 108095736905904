<template>
  <div class="d-flex flex-column pa-1">
    <div class="orderTabs">
      <v-tabs
        v-if="!correctionMode"
        v-model="currentOrderTab"
        active-class="activeTab"
        color="primary"
        :hide-slider="true"
        :grow="true"
      >
        <v-tab
          v-for="n in nbrOfTabs"
          :key="n"
          class="pa-0 title orderTab"
          v-on:click="orderTabSelected(n)"
        >
          <v-badge
            :content="findOrder(n).totalItems"
            :value="findOrder(n).totalItems"
            left
            offset-y="12"
            offset-x="-5"
            :color="currentTab === n ? 'grey darken-1' : 'grey darken-3'"
          >
            <v-badge
              :color="showBadgeColor(n)"
              :value="showBadge(n)"
              dot
              offset-x="-5"
            >
              {{ n }}
            </v-badge>
          </v-badge>
        </v-tab>
      </v-tabs>
      <v-tabs v-else color="white" :hide-slider="true" :grow="true">
        <v-tab
          class="pa-0 grey--text text--lighten-2 title correctionTab"
          :ripple="false"
        >
          <v-badge
            :content="findOrder(-1).totalItems"
            :value="findOrder(-1).totalItems"
            left
            offset-y="12"
            offset-x="-5"
            color="grey lighten-2 black--text"
          >
            {{ $t("label.correction") }}
          </v-badge>
        </v-tab>
      </v-tabs>
    </div>
    <v-simple-table
      class="orderItemsTable"
      :class="className('orderItemsTable')"
      :height="orderTableHeight"
    >
      <template
        v-if="
          orders && orders.length === nbrOfTabs + 1 && currentOrder.orderItems
        "
        v-slot:default
      >
        <tbody>
          <tr
            v-for="(orderItem, index) in currentOrder.orderItems"
            :key="index"
            v-on:click.stop="selectOrderItem(orderItem)"
            :class="{
              'grey--text': currentOrder.paymentRequested === true,
              lastAdded: currentOrder.lastAddedProductId === orderItem.id,
            }"
            class="order-item-row"
          >
            <template
              v-if="
                !currentOrder.selectedOrderItem ||
                currentOrder.selectedOrderItem.id !== orderItem.id
              "
            >
              <td
                :class="
                  $vuetify.breakpoint.mdAndUp
                    ? 'subtitle-1 font-weight-regular'
                    : ''
                "
                class="order-item-quantity-label"
              >
                {{ orderItem.total }}
              </td>
              <td
                :class="
                  $vuetify.breakpoint.mdAndUp
                    ? 'subtitle-1 font-weight-regular'
                    : ''
                "
                class="order-item-name-label"
              >
                {{ orderItem.name }}
              </td>
              <td
                :class="
                  $vuetify.breakpoint.mdAndUp
                    ? 'subtitle-1 font-weight-regular'
                    : ''
                "
                class="order-item-amount-label"
              >
                €{{ orderItem.totalAmount.toFixed(2).replace(".", ",") }}
              </td>
            </template>
            <template v-else>
              <td class="pa-2 selectedOrderItem" colspan="3">
                <v-card
                  class="d-flex flex-column pa-1 title"
                  v-bind:id="`adjust-${index}`"
                  v-click-outside="unselectOrderItem"
                >
                  <div class="text-center">
                    {{ currentOrder.selectedOrderItem.name }}
                  </div>
                  <div class="d-flex justify-space-between">
                    <v-btn
                      class="order-item-minus-button"
                      color="primary"
                      v-on:click.stop="$emit('minusOrderItem', orderItem)"
                      ><v-icon color="black">mdi-minus</v-icon></v-btn
                    >
                    <span>{{ orderItem.total }}</span>
                    <v-btn
                      class="order-item-plus-button"
                      color="primary"
                      v-on:click="$emit('plusOrderItem', orderItem)"
                      ><v-icon color="black">mdi-plus</v-icon></v-btn
                    >
                  </div>
                </v-card>
              </td>
            </template>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <div class="d-flex align-center" :class="className('orderBtnGroup')">
      <v-btn
        color="primary"
        class="order-pay-button black--text flex-grow-1 pa-0 mr-1 mt-1"
        :class="className('payButton')"
        v-on:click="$emit('paymentRequested')"
        :disabled="
          !orders ||
          orders.length !== nbrOfTabs + 1 ||
          currentOrder.paymentRequested === true ||
          !currentOrder.orderItems ||
          currentOrder.orderItems.length === 0 ||
          (isFreePlan && remainingFreeOrders === 0)
        "
        >{{ $t(`${this.correctionMode ? "label.refund" : "label.pay"}`) }} €
        {{ orderAmount }}</v-btn
      >
      <v-btn
        color="#424242"
        class="order-clear-button mt-1"
        :class="className('deleteOrderButton')"
        v-on:click="$emit('deleteOrder')"
        :disabled="
          !orders ||
          orders.length !== nbrOfTabs + 1 ||
          currentOrder.paymentRequested === true ||
          !currentOrder.orderItems ||
          currentOrder.orderItems.length === 0 ||
          (isFreePlan && remainingFreeOrders === 0)
        "
        ><v-icon v-if="$vuetify.breakpoint.mdAndUp" x-large color="primary"
          >mdi-delete</v-icon
        >
        <v-icon v-else color="primary">mdi-delete</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";

export default {
  name: "WaiterOrders",
  props: {
    nbrOfTabs: {
      type: Number,
      default: 3,
    },
    orders: {
      type: Array,
      required: true,
    },
    currentTab: {
      type: Number,
      default: 1,
    },
    correctionMode: {
      type: Boolean,
      default: false,
    },
    isFreePlan: {
      type: Boolean,
      default: true,
    },
    remainingFreeOrders: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      currentOrderTab: this.currentTab - 1,
      currentOrder: {},
    };
  },
  methods: {
    findOrder(tab) {
      const theTab = tab ? tab : this.currentTab;
      const order = this.orders
        ? this.orders.find((i) => i.tabId === theTab)
        : undefined;
      return order ? order : {};
    },
    orderTabSelected(nbr) {
      this.currentOrder = this.findOrder(nbr);
      this.$emit("orderTabSelected", nbr);
    },
    selectOrderItem(orderItem) {
      if (
        (!this.currentOrder.selectedOrderItem ||
          this.currentOrder.selectedOrderItem.id !== orderItem.id) &&
        this.currentOrder.paymentRequested === false
      ) {
        this.$emit("selectOrderItem", orderItem);
      }
    },
    unselectOrderItem() {
      if (this.currentOrder.selectedOrderItem) {
        this.$emit("unselectOrderItem");
      }
    },
    showBadge(nbr) {
      let result = 0;
      if (nbr !== this.currentTab) {
        result =
          this.orders &&
          this.orders.length === this.nbrOfTabs + 1 &&
          (this.findOrder(nbr).status === "success" ||
            this.findOrder(nbr).status === "error")
            ? 1
            : 0;
      }
      return result;
    },
    showBadgeColor(nbr) {
      return this.orders &&
        this.orders.length === this.nbrOfTabs + 1 &&
        this.findOrder(nbr).status === "success"
        ? "green"
        : "red";
    },
    className(name) {
      return `${name}-${this.$vuetify.breakpoint.name} ${
        this.$vuetify.breakpoint.mdAndUp ? "title" : ""
      }`;
    },
    prepareCurrentOrder() {
      this.currentOrder = this.findOrder();
      this.$nextTick(() => {
        this.currentOrderTab = this.currentTab - 1;
        const lastAddedEl = this.$el.querySelector(".lastAdded");
        const selectedEl = this.$el.querySelector(".selectedOrderItem");
        if (lastAddedEl) {
          lastAddedEl.scrollIntoView();
        }
        if (selectedEl) {
          selectedEl.scrollIntoView();
        }
      });
    },
  },
  computed: {
    orderAmount() {
      const order = this.findOrder(this.currentTab);
      return this.orders &&
        this.orders.length === this.nbrOfTabs + 1 &&
        order.totalAmount
        ? order.totalAmount.toFixed(2).replace(".", ",")
        : parseInt(0).toFixed(2).replace(".", ",");
    },
    orderTableHeight() {
      const factor = this.$vuetify.breakpoint.mdAndUp ? 1.5 : 1;
      return `calc(100vh - var(--var-header-height) - var(--var-order-tabs-height) - ${factor} * var(--var-order-btn-group-height) - 8px)`;
    },
  },
  watch: {
    currentTab(value) {
      this.currentOrderTab = value - 1;
    },
    orders: {
      deep: true,
      handler() {
        this.prepareCurrentOrder();
      },
    },
    correctionMode() {
      this.prepareCurrentOrder();
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>

<style>
.orderTabs {
  height: var(--var-order-tabs-height);
  margin-left: -1px;
  margin-right: -1px;
}

.orderTab,
.correctionTab {
  min-width: 0;
  background-color: #303030;
  border-left: 1px solid #121212;
  border-right: 1px solid #121212;
  border-bottom: 4px solid #121212;
}

.correctionTab {
  background-color: #f44336 !important;
}

.activeTab {
  background-color: #424242;
}

.orderItemsTable > .v-data-table__wrapper::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 25px #424242 !important;
  background-color: #424242 !important;
}
.orderItemsTable > .v-data-table__wrapper::-webkit-scrollbar {
  width: 25px !important;
  background-color: #424242 !important;
}
.orderItemsTable > .v-data-table__wrapper::-webkit-scrollbar-thumb {
  background-color: #757575 !important;
}

.orderBtnGroup-sm {
  height: var(--var-order-btn-group-height);
}

.orderBtnGroup-md,
.orderBtnGroup-lg,
.orderBtnGroup-xl {
  height: calc(1.5 * var(--var-order-btn-group-height));
}

.selectedOrderItem {
  background-color: #616161;
}

.lastAdded {
  background-color: #616161;
}

.payButton-sm,
.deleteOrderButton-sm {
  min-height: calc(var(--var-order-btn-group-height) - 4px);
}

.payButton-md,
.payButton-lg,
.payButton-xl {
  min-height: calc(1.5 * var(--var-order-btn-group-height) - 4px);
}

.deleteOrderButton-md,
.deleteOrderButton-lg,
.deleteOrderButton-xl {
  min-height: calc(1.5 * var(--var-order-btn-group-height) - 4px);
  width: calc(2 * var(--var-order-btn-group-height) - 4px);
}
</style>
