let buildTimestamp = window.localStorage.getItem('buildTimestamp')

export async function initServiceWorker() {
  await navigator.serviceWorker.register('/service-worker.js')
  const cache = await caches.open('app')
  const cachedApp = await cache.match(new Request(window.location.origin))
  if (!cachedApp) {
    window.location.reload()
  }
}

export async function checkForUpdate() {
  try {
    const buildTimestampResponse = await fetch(`${window.location.origin}/build-timestamp.txt`, {
      headers: {
        'pragma': 'no-cache',
        'cache-control': 'no-cache'
      }
    })
    const serverBuildTimestamp = await buildTimestampResponse.text()
    if (buildTimestamp !== serverBuildTimestamp) {
      buildTimestamp = serverBuildTimestamp
      window.localStorage.setItem('buildTimestamp', buildTimestamp)

      await caches.delete('app')
      window.location.reload()
    }
  } catch (e) {
    console.log(e)
  }
}