<template>
  <v-dialog
    v-model="show"
    fullscreen
    persistent
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card :loading="fetching">
      <v-toolbar color="grey darken-3">
        <v-toolbar-title class="headline"
          >{{ this.$t("label.currentOrders") }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-alert v-if="!connected" class="pa-2 ma-2" type="warning"
          >{{ this.$t("message.limitedOfflineActions") }}
        </v-alert>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn class="title" color="primary" text @click.stop="closeDialog"
            >{{ this.$t("label.close") }}
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <div v-if="!initialLoading" class="orders d-flex flex-column pa-5">
        <div
          class="d-flex"
          :class="{ 'flex-column': this.$vuetify.breakpoint.name == 'sm' }"
        >
          <v-btn-toggle v-model="orderStatusFilter" mandatory color="primary">
            <v-btn>
              {{ this.$t("label.all") }}
            </v-btn>
            <v-btn>
              <v-badge
                :content="ordersToPrepareCount"
                :value="ordersToPrepareCount > 0"
                color="red"
                class="font-weight-bold"
                inline
              >
                <div>{{ this.$t("label.to_prepare") }}</div>
              </v-badge>
            </v-btn>
            <v-btn>
              <v-badge
                :content="ordersToServeCount"
                :value="ordersToServeCount > 0"
                color="orange"
                class="font-weight-bold"
                inline
              >
                {{ this.$t("label.to_serve") }}
              </v-badge>
            </v-btn>
            <v-btn>
              <v-badge
                :content="ordersWaitingForPickupCount"
                :value="ordersWaitingForPickupCount > 0"
                color="blue"
                class="font-weight-bold"
                inline
              >
                {{ this.$t("label.waiting_for_pickup") }}
              </v-badge>
            </v-btn>
          </v-btn-toggle>
          <v-spacer></v-spacer>
          <v-btn-toggle
            v-model="orderInitiatorFilter"
            mandatory
            color="primary"
            :class="{ 'mt-4': this.$vuetify.breakpoint.name == 'sm' }"
          >
            <v-btn>
              {{ this.$t("label.all") }}
            </v-btn>
            <v-btn>
              {{ this.$t("label.orderedOnline") }}
            </v-btn>
            <v-btn>
              {{ this.$t("label.otherCashier") }}
            </v-btn>
          </v-btn-toggle>
        </div>
        <div class="d-flex flex-column">
          <v-card flat>
            <div v-click-outside="hideKeyboard" class="d-flex flex-column">
              <v-row no-gutters>
                <v-col sm="8" lg="10" />
                <v-col sm="4" lg="2">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    :label="$t('label.search')"
                    single-line
                    hide-details
                    @click="
                      () => {
                        search = '';
                        displayKeyboard = true;
                      }
                    "
                    class="pb-4 pt-7"
                  />
                </v-col>
              </v-row>
              <div v-if="displayKeyboard" class="d-flex justify-end">
                <div class="orderSearchKeyboard">
                  <SimpleKeyboard
                    @onChange="onChange"
                    @onKeyPress="onKeyPress"
                    :inputName="search"
                    :inputValue="search"
                    classname="order-search-keyboard"
                  />
                </div>
              </div>
            </div>
            <v-data-table
              :headers="headers"
              :items="filteredOrders"
              item-key="instanceId"
              disable-pagination
              hide-default-footer
              fixed-header
              single-expand
              show-expand
              :expanded.sync="expandedOrders"
              @click:row="orderClicked"
              :height="`${
                $vuetify.breakpoint.height - (displayKeyboard ? 475 : 275)
              }px`"
              class="currentOrdersTable"
              :no-data-text="$t('message.noCurrentOrders')"
            >
              <template v-slot:[`item.dateTime`]="{ item }">
                {{ formattedTime(item.dateTime) }}
              </template>
              <template v-slot:[`item.instanceId`]="{ item }">
                {{ item.instanceId ? item.instanceId.substring(0, 7) : "???" }}
              </template>
              <template v-slot:[`item.numberOfProducts`]="{ item }">
                {{ item.numberOfProducts }}
                {{
                  item.numberOfProducts === 1
                    ? $t("label.product")
                    : $t("label.products")
                }}
                (€{{ `${item.amount}`.replace(".", ",") }})
              </template>
              <template v-slot:[`item.initiator`]="{ item }">
                <v-icon>
                  {{
                    item.initiator === "CASHIER"
                      ? "mdi-cash-register"
                      : "mdi-account"
                  }}
                </v-icon>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <v-chip
                  :color="getStatusColor(item.status)"
                  class="font-weight-bold"
                >
                  {{ $t(`label.${item.status.toLowerCase()}`) }}
                </v-chip>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <v-card
                    @click="orderClicked(item)"
                    class="pa-5 pt-2 pb-2"
                    color="grey darken-2"
                  >
                    <div class="d-flex flex-column">
                      <div
                        v-for="productLine in item.items"
                        :key="`${item.instanceId}_${productLine.productId}`"
                        :id="`${item.instanceId}_${productLine.productId}`"
                      >
                        {{ productLine.quantity }}x
                        {{ productLine.productName }}
                      </div>
                    </div>
                  </v-card>
                </td>
              </template>
            </v-data-table>
          </v-card>
        </div>
      </div>
      <div v-else class="orders d-flex flex-column justify-center align-center">
        <v-progress-circular
          :size="250"
          :width="3"
          indeterminate
          color="primary"
        />
      </div>
    </v-card>
    <v-dialog v-model="showWaiterSelection" persistent max-width="480">
      <v-card
        :disabled="waiterActionLoading"
        :loading="waiterActionLoading"
        v-if="this.selectedOrder"
      >
        <v-card-title class="text-break">
          {{ $t("message.selectWaiterToContinueOrder") }}
        </v-card-title>
        <v-card-text>
          <div class="d-flex flex-column">
            <v-alert
              v-if="waiterActionError"
              color="red"
              type="error"
              class="ma-0"
              >{{ $t("message.errorTryAgain") }}</v-alert
            >
            <div class="pa-4">
              <div class="d-flex justify-lg-space-between">
                <div>
                  {{
                    this.selectedOrder.instanceId
                      ? this.selectedOrder.instanceId.substring(0, 7)
                      : "???"
                  }}
                </div>
                <div>
                  {{ this.selectedOrder.numberOfProducts }}
                  {{
                    this.selectedOrder.numberOfProducts === 1
                      ? $t("label.product")
                      : $t("label.products")
                  }}
                  (€{{ `${this.selectedOrder.amount}`.replace(".", ",") }})
                </div>
                <v-chip
                  :color="getStatusColor(this.selectedOrder.status)"
                  class="font-weight-bold"
                >
                  {{
                    this.selectedOrder.status
                      ? $t(`label.${this.selectedOrder.status.toLowerCase()}`)
                      : ""
                  }}
                </v-chip>
              </div>
            </div>
            <v-list
              :key="waiterListKey"
              flat
              elevation="5"
              :max-height="
                $vuetify.breakpoint.height < 800 ? '250px' : undefined
              "
              class="overflow-auto waiterList pa-0"
            >
              <v-list-item-group>
                <template v-for="(waiter, index) in getWaiters()">
                  <v-list-item
                    :key="`waiter_${waiter.waiterId}`"
                    :disabled="waiter.tabId === '!'"
                    @click="selectWaiter(waiter)"
                    style="height: 75px"
                    :aria-selected="false"
                  >
                    <template>
                      <v-list-item-content>
                        <v-list-item-title
                          class="text-center subtitle-1"
                          v-text="
                            selectedOrder.amount < 0
                              ? waiter.nameAndCorrection
                              : waiter.nameAndTab
                          "
                        >
                        </v-list-item-title>

                        <v-list-item-subtitle
                          v-if="waiter.tabId === '!'"
                          class="text-center subtitle-2"
                          v-text="$t('message.allTabsUsed')"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-list-item>
                  <v-divider
                    v-if="index < getWaiters().length - 1"
                    :key="`divider_${index}`"
                  ></v-divider>
                </template>
              </v-list-item-group>
            </v-list>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="closeWaiterSelection">{{
            $t("label.cancel")
          }}</v-btn>
          <v-btn
            :disabled="
              this.$estore.get('tenant.activeWaiters').length ===
              this.$estore.get('tenant.availableWaiters').length
            "
            text
            color="primary"
            @click="selectWaiter(null)"
            >{{ $t("label.newWaiter") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="orderBusyDialog" max-width="520" persistent>
      <v-card>
        <v-card-title class="headline">
          {{ $t("message.orderIsBeingProcessed") }}
        </v-card-title>

        <v-card-text>
          {{ $t("message.confirmTransfer") }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            text
            @click="
              () => {
                this.orderBusyDialog = false;
                this.closeWaiterSelection();
              }
            "
          >
            {{ $t("label.cancel") }}
          </v-btn>

          <v-btn
            color="primary"
            text
            @click="
              () => {
                this.orderBusyDialog = false;
                this.orderToWaiter(selectedWaiter);
              }
            "
          >
            {{ $t("label.confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showNotTransferable" max-width="520" persistent>
      <v-card>
        <v-card-title class="headline">
          {{ $t("message.warning") }}
        </v-card-title>

        <v-card-text>
          {{ $t("message.orderIsNotTransferable") }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            text
            @click="
              () => {
                this.showNotTransferable = false;
                this.fetchCurrentOrders();
              }
            "
          >
            {{ $t("label.cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import Vue from "vue";
import moment from "moment-timezone";
import SimpleKeyboard from "@/components/Utils/SimpleKeyboard.vue";
import ClickOutside from "vue-click-outside";

export default {
  name: "running-orders-dialog",
  components: {
    SimpleKeyboard,
  },
  directives: {
    ClickOutside,
  },
  props: {
    connected: {
      type: Boolean,
      required: true,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    cashierBook: {
      type: Array,
      required: true,
    },
    log: {
      type: Function,
      default: (msg) => console.log(msg),
    },
  },
  data() {
    return {
      displayKeyboard: false,
      cashierId: this.$estore.get("deviceId"),
      waiterListKey: 0,
      initialLoading: true,
      fetching: true,
      currentOrders: [],
      filteredOrders: [],
      fetchCurrentOrdersCancel: undefined,
      fetchCurrentOrdersTimeoutId: undefined,
      orderInitiatorFilter: this.$estore.get("tenant.orderFilters.initiator"),
      orderStatusFilter: this.$estore.get("tenant.orderFilters.status"),
      search: "",
      expandedOrders: [],
      showWaiterSelection: false,
      selectedOrder: undefined,
      selectedWaiter: undefined,
      orderBusyDialog: false,
      showNotTransferable: false,
      waiterActionLoading: false,
      waiterActionError: false,
    };
  },
  methods: {
    onChange(input) {
      this.search = input;
    },
    onKeyPress(button) {
      if (button === "{ent}") {
        this.displayKeyboard = false;
      }
    },
    hideKeyboard() {
      if (this.displayKeyboard) {
        this.displayKeyboard = false;
      }
    },
    cancelFetch() {
      if (this.fetchCurrentOrdersCancel) {
        this.log(`About to cancel request for fetchCurrentOrders`);
        try {
          this.fetchCurrentOrdersCancel.cancel();
        } catch (e) {
          this.log("Cancel issue for fetchCurrentOrders, ignoring... " + e);
        }
        this.fetchCurrentOrdersCancel = undefined;
      }
      if (this.fetchCurrentOrdersTimeoutId) {
        this.log(`About to cancel timeout for fetchCurrentOrders`);
        clearTimeout(this.fetchCurrentOrdersTimeoutId);
        this.fetchCurrentOrdersTimeoutId = undefined;
      }
    },
    filterCurrentOrders() {
      let combinedFilter = undefined;
      if (this.orderInitiatorFilter && this.orderStatusFilter) {
        combinedFilter = (o) =>
          (o.initiator === "CASHIER"
            ? o.cashierId &&
              o.cashierId !== this.cashierId &&
              o.initiator === this.orderInitatorValue
            : o.initiator === this.orderInitatorValue) &&
          o.status === this.orderStatusValue &&
          !o.cashierId;
      } else {
        if (this.orderInitiatorFilter) {
          combinedFilter = (o) =>
            o.initiator === "CASHIER"
              ? o.cashierId &&
                o.cashierId !== this.cashierId &&
                o.initiator === this.orderInitatorValue
              : o.initiator === this.orderInitatorValue;
        } else {
          if (this.orderStatusFilter) {
            combinedFilter = (o) =>
              o.status === this.orderStatusValue && !o.cashierId;
          }
        }
      }
      this.filteredOrders = combinedFilter
        ? this.currentOrders.filter(combinedFilter)
        : this.currentOrders;
      this.filteredOrders = this.filteredOrders.filter(
        (o) =>
          !(o.initiator === "CUSTOMER" && !o.accepted) &&
          !(o.status && o.status.startsWith("CANCELED"))
      );
      this.$app.sortItems(this.filteredOrders, (item) => item.dateTime);
    },
    fetchCurrentOrders() {
      this.cancelFetch();

      this.log(`About to send request for fetchCurrentOrders`);

      let url = this.$app.urls.process.currentOrders
        .replace("{tenantId}", this.$estore.get("tenant.tenantId"))
        .replace("{cashierId}", this.cashierId)
        .replace("{processName}", "order");

      this.fetchCurrentOrdersCancel = Vue.prototype.$http.CancelToken.source();

      this.fetching = true;

      this.$http({
        url: url,
        method: "get",
        cancelToken: this.fetchCurrentOrdersCancel.token,
      }).then(
        (response) => {
          this.log(
            `Response received for fetchCurrentOrders: ${response.status} - ${response.statusText}`
          );
          this.fetchCurrentOrdersCancel = undefined;

          this.currentOrders = response.data;
          this.filterCurrentOrders();
          this.fetchCurrentOrdersTimeoutId = setTimeout(
            () => this.fetchCurrentOrders(),
            5000
          );
          this.initialLoading = false;
          this.fetching = false;
        },
        (error) => {
          this.log(
            `Error response for fetchCurrentOrders, retrying in 5s: ${error}`
          );
          this.fetchCurrentOrdersTimeoutId = setTimeout(
            () => this.fetchCurrentOrders(),
            5000
          );
        }
      );
    },
    closeDialog() {
      this.show = false;
    },
    getStatusColor(status) {
      let color;
      switch (status) {
        case "TO_PREPARE":
          color = "red";
          break;
        case "TO_SERVE":
          color = "orange darken-2";
          break;
        case "WAITING_FOR_PICKUP":
          color = "blue";
          break;
        default:
          color = "grey darken-1";
      }

      return color;
    },
    formattedTime(timestamp) {
      return moment.utc(timestamp).local().format("DD/MM/YYYY HH:mm:ss");
    },
    orderClicked(order) {
      this.cancelFetch();
      if (
        order.cashierId &&
        order.cashierId !== this.cashierId &&
        !order.cashierOnline
      ) {
        this.showNotTransferable = true;
      } else {
        this.selectedOrder = order;
        this.showWaiterSelection = true;
      }
    },
    selectWaiter(waiter) {
      this.selectedWaiter = waiter;
      if (
        !["TO_PREPARE", "TO_SERVE", "WAITING_FOR_PICKUP"].includes(
          this.selectedOrder.status
        )
      ) {
        this.orderBusyDialog = true;
      } else {
        this.orderToWaiter(this.selectedWaiter);
      }
    },
    orderToWaiter(waiter) {
      this.waiterActionError = false;
      const order = this.selectedOrder;

      let action;
      switch (order.status) {
        case "TO_PREPARE":
          action = "startPreparation";
          break;
        case "WAITING_FOR_PICKUP":
          action = "startDelivery";
          break;
        case "TO_SERVE":
          action = "startDelivery";
          break;
        default:
          action = "transfer";
      }

      if (action === "transfer" && order.cashierId === this.cashierId) {
        // local transfer
        if (waiter) {
          this.$emit("orderToWaiter", { waiter, order, localTransfer: true });
        } else {
          this.$emit("orderToNewWaiter", { order, localTransfer: true });
        }
        this.closeDialog();
      } else {
        this.waiterActionLoading = true;
        let url = this.$app.urls.process[action]
          .replace("{tenantId}", this.$estore.get("tenant.tenantId"))
          .replace("{cashierId}", this.cashierId)
          .replace("{processName}", "order")
          .replace("{processUuid}", order.instanceId);

        this.$http({
          url: url,
          method: "post",
        }).then(
          (response) => {
            this.log(
              `Response received for ${action}: ${response.status} - ${response.statusText}`
            );
            if (waiter) {
              this.$emit("orderToWaiter", {
                waiter,
                order,
                remoteTransfer: action === "transfer",
              });
            } else {
              this.$emit("orderToNewWaiter", {
                order,
                remoteTransfer: action === "transfer",
              });
            }
            this.closeDialog();
          },
          (error) => {
            this.waiterActionError = true;
            this.waiterActionLoading = false;
            this.log(`Error response for ${action}: ${error}`);
          }
        );
      }
    },
    closeWaiterSelection() {
      this.showWaiterSelection = false;
      this.fetchCurrentOrders();
      this.selectedWaiter = undefined;
    },
    getWaiters() {
      const waiters = [];
      this.cashierBook.forEach((w) => {
        const waiterData = this.$estore
          .get("tenant.activeWaiters", [])
          .find((aw) => aw.id === w.waiterId);
        const waiterName = waiterData ? waiterData.name : undefined;
        if (waiterName) {
          const orderTabs = w.openOrders.filter(
            (o) => o.tabId !== -1 && o.totalItems === 0
          );
          const waiter = {};
          waiter.waiterId = w.waiterId;
          if (orderTabs.length > 0) {
            this.$app.sortItems(orderTabs, "tabId");
            const firstTab = orderTabs[0];
            waiter.tabId = firstTab.tabId;
          } else {
            waiter.tabId = "!";
          }
          waiter.nameAndCorrection = `${waiterName} (${this.$t(
            "label.correction"
          )})`;
          waiter.nameAndTab = `${waiterName} (${waiter.tabId})`;
          waiters.push(waiter);
        }
      });
      return waiters;
    },
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
    orderInitatorValue() {
      let result;
      switch (this.orderInitiatorFilter) {
        case 1:
          result = "CUSTOMER";
          break;
        case 2:
          result = "CASHIER";
          break;
        default:
          result = undefined;
      }
      return result;
    },
    orderStatusValue() {
      let result;
      switch (this.orderStatusFilter) {
        case 1:
          result = "TO_PREPARE";
          break;
        case 2:
          result = "TO_SERVE";
          break;
        case 3:
          result = "WAITING_FOR_PICKUP";
          break;
        default:
          result = undefined;
      }
      return result;
    },
    ordersToPrepareCount() {
      return this.currentOrders.filter(
        (o) => o.status === "TO_PREPARE" && !o.cashierId
      ).length;
    },
    ordersToServeCount() {
      return this.currentOrders.filter(
        (o) => o.status === "TO_SERVE" && !o.cashierId
      ).length;
    },
    ordersWaitingForPickupCount() {
      return this.currentOrders.filter(
        (o) => o.status === "WAITING_FOR_PICKUP" && !o.cashierId
      ).length;
    },
    headers() {
      return [
        {
          text: this.$t("label.timestamp"),
          sortable: false,
          value: "dateTime",
        },
        {
          text: this.$t("label.orderShortId"),
          sortable: false,
          value: "instanceId",
          filter: (value, unsetTableSearch, item) => {
            return (
              value != null &&
              this.search != null &&
              typeof value === "string" &&
              (value
                .toString()
                .toLowerCase()
                .indexOf(this.search.toLowerCase()) !== -1 ||
                (item.cashierName &&
                  item.cashierName
                    .toLowerCase()
                    .indexOf(this.search.toLowerCase()) !== -1) ||
                (item.orderReference &&
                  item.orderReference
                    .toLowerCase()
                    .indexOf(this.search.toLowerCase()) !== -1))
            );
          },
        },
        {
          text: this.$t("label.cashier"),
          sortable: false,
          value: "cashierName",
        },
        {
          text: this.$t("label.orderReference"),
          sortable: false,
          value: "orderReference",
        },
        {
          text: this.$t("label.products"),
          sortable: false,
          value: "numberOfProducts",
        },
        {
          text: this.$t("label.initiator"),
          align: "center",
          sortable: false,
          value: "initiator",
        },
        {
          text: this.$t("label.status"),
          align: "end",
          sortable: false,
          value: "status",
        },
        { text: "", value: "data-table-expand" },
      ];
    },
  },
  watch: {
    visible(newValue) {
      if (newValue) {
        this.initialLoading = true;
        this.fetchCurrentOrders();
        this.selectedOrder = undefined;
        this.selectedWaiter = undefined;
        this.expandedOrders = [];
        this.waiterListKey += 1;
        this.waiterActionLoading = false;
        this.search = "";
        this.displayKeyboard = false;
      } else {
        this.initialLoading = false;
        this.cancelFetch();
      }
    },
    orderInitiatorFilter(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$estore.set("tenant.orderFilters.initiator", newValue);
      }
      this.filterCurrentOrders();
    },
    orderStatusFilter(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$estore.set("tenant.orderFilters.status", newValue);
      }
      this.filterCurrentOrders();
    },
  },
};
</script>

<style>
.orders {
  height: calc(100vh - 64px);
}
.waiterList::-webkit-scrollbar-track,
.v-data-table__wrapper::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 25px #424242 !important;
  background-color: #424242 !important;
}
.waiterList::-webkit-scrollbar,
.v-data-table__wrapper::-webkit-scrollbar {
  width: 25px !important;
  background-color: #424242 !important;
}
.waiterList::-webkit-scrollbar-thumb,
.v-data-table__wrapper::-webkit-scrollbar-thumb {
  background-color: #757575 !important;
}

.currentOrdersTable > div > table > tbody > tr > td {
  height: 75px !important;
}

.orderSearchKeyboard > .simple-keyboard {
  height: 200px;
  background-color: #424242 !important;
}

.orderSearchKeyboard > .simple-keyboard > .hg-row > .hg-standardBtn,
.orderSearchKeyboard > .simple-keyboard > .hg-row > .hg-button-abc,
.orderSearchKeyboard > .simple-keyboard > .hg-row > .hg-button-ent,
.orderSearchKeyboard > .simple-keyboard > .hg-row > .hg-button-sym,
.orderSearchKeyboard > .simple-keyboard > .hg-row > .hg-button-numbers,
.orderSearchKeyboard > .simple-keyboard > .hg-row > .hg-button-shift,
.orderSearchKeyboard > .simple-keyboard > .hg-row > .hg-button-space,
.orderSearchKeyboard > .simple-keyboard > .hg-row > .hg-functionBtn,
.orderSearchKeyboard > .simple-keyboard > .hg-row > .hg-button-backspace {
  min-width: 55px !important;
  max-width: 55px !important;
  width: 55px !important;
  min-height: 45px !important;
  max-height: 45px !important;
  height: 45px !important;
  color: #424242 !important;
  font-size: 1.7em !important;
}

.orderSearchKeyboard > .simple-keyboard > .hg-row > .hg-functionBtn {
  color: #424242 !important;
  font-size: 1.7em !important;
}

.orderSearchKeyboard > .simple-keyboard > .hg-row > .hg-button-backspace {
  min-width: 175px !important;
  max-width: 175px !important;
  width: 175px !important;
}

.orderSearchKeyboard > .simple-keyboard > .hg-row > .hg-button-sym {
  min-width: 115px !important;
  max-width: 115px !important;
  width: 115px !important;
}
.orderSearchKeyboard > .simple-keyboard > .hg-row > .hg-button-numbers,
.orderSearchKeyboard > .simple-keyboard > .hg-row > .hg-button-abc {
  min-width: 115px !important;
  max-width: 115px !important;
  width: 115px !important;
}

.orderSearchKeyboard > .simple-keyboard > .hg-row > .hg-button-space {
  min-width: 355px !important;
  max-width: 355px !important;
  width: 355px !important;
}

.orderSearchKeyboard > .simple-keyboard > .hg-row > .hg-button-ent {
  min-width: 115px !important;
  max-width: 115px !important;
  width: 115px !important;
}
</style>
