<template>
  <div class="d-flex flex-column pa-1 pl-0">
    <div class="categoryTabs">
      <v-tabs
        v-model="currentCategoryTab"
        active-class="activeCategoryTab"
        color="primary"
        :hide-slider="true"
        :grow="true"
        :show-arrows="true"
        :key="tabBarKey"
      >
        <v-tab
          class="favorites-product-category pa-2 pl-2 pr-2 categoryTab"
          v-on:click="categoryTabSelected(undefined)"
        >
          <v-icon>mdi-heart</v-icon>
        </v-tab>
        <v-tab
          v-for="category in categories"
          v-bind:key="category.id"
          class="product-category-tab pa-2 categoryTab"
          v-on:click="categoryTabSelected(category)"
        >
          {{ category.name }}
        </v-tab>
      </v-tabs>
    </div>

    <v-card
      v-resize="calcMaxProducts"
      flat
      height="calc(100vh - var(--var-header-height) - var(--var-order-tabs-height) - 8px)"
    >
      <div
        ref="viewingMode"
        class="d-flex justify-space-between align-center pr-2 viewingMode"
      >
        <div class="d-flex pl-3">
          <div v-if="orderId" class="font-weight-bold pr-1">
            {{ $t("label.orderShortId") }}:
          </div>
          <div>{{ orderId ? orderId.substring(0, 7) : "" }}</div>
        </div>
        <div
          :disabled="!products || products.length > 0"
          class="d-flex flex-column align-end"
        >
          <v-btn-toggle mandatory v-model="viewingMode">
            <v-btn
              class="product-tile-view-button"
              small
              @click="prepareGridList"
            >
              <v-icon
                :color="
                  viewingMode === 0 ? 'primary' : 'rgba(255, 255, 255, 0.6)'
                "
                >mdi-view-comfy</v-icon
              >
            </v-btn>

            <v-btn
              class="product-table-view-button"
              small
              @click="prepareSubProductList"
            >
              <v-icon
                :color="
                  viewingMode === 1 ? 'primary' : 'rgba(255, 255, 255, 0.6)'
                "
                >mdi-view-list</v-icon
              >
            </v-btn>
          </v-btn-toggle>
          <img :ref="`p00`" :class="`productPlaceholder${classSuffix}`" />
        </div>
      </div>
      <div v-if="products && products.length > 0" ref="productsPage">
        <div v-if="viewingMode === 0">
          <agile
            v-if="isSlideLoaded"
            ref="carousel"
            class="d-flex flex-column justify-space-between"
            :swipe-distance="slides && slides.length > 1 ? 50 : 5000"
            :key="carouselKey"
          >
            <v-card
              flat
              v-for="(slide, index) in slides"
              :key="index"
              class="flex-wrap justify-space-around"
              min-height="calc(100vh - var(--var-header-height) - 2*var(--var-order-tabs-height) - 8px - 15px)"
              max-height="calc(100vh - var(--var-header-height) - 2*var(--var-order-tabs-height) - 8px - 15px)"
            >
              <div
                v-if="slide.length > 0"
                class="productsSlide flex-wrap justify-space-around"
              >
                <div
                  :key="p.id"
                  :ref="`p${index}`"
                  v-for="(p, index) in slide"
                  class="product-tile"
                  :class="`productImage${classSuffix}`"
                  @click.stop="
                    p.disabled
                      ? openDisabledProductDialog(p)
                      : $emit('productSelected', {
                          id: p.id,
                          name: p.name,
                          price: p.price,
                        })
                  "
                  v-long-press="500"
                  @long-press-start.stop="
                    p.disabled ? () => {} : disableProduct(p, true)
                  "
                >
                  <img
                    :src="getImgUrl(p.logoId)"
                    draggable="false"
                    disabled="true"
                  />
                  <div
                    v-if="p.disabled"
                    :class="`productDisabled${classSuffix}`"
                    class="d-flex justify-end align-end"
                  >
                    <v-icon class="ma-1 grey--text text--lighten-1"
                      >mdi-food-off</v-icon
                    >
                  </div>
                </div>
              </div>
              <div v-else class="pa-6">
                {{ $t("message.noProductsInCategory") }}
              </div>
            </v-card>
            <template slot="prevButton"
              ><i class="fas fa-chevron-left"></i
            ></template>
            <template slot="nextButton"
              ><i class="fas fa-chevron-right"></i
            ></template>
          </agile>
        </div>
        <v-simple-table
          v-else-if="subProductList && subProductList.length > 0"
          class="productsTable"
          height="calc(100vh - var(--var-header-height) - 2*var(--var-order-tabs-height) - 8px)"
          max-height="calc(100vh - var(--var-header-height) - 2*var(--var-order-tabs-height) - 8px)"
        >
          <template v-slot:default>
            <tbody>
              <tr
                v-for="(p, index) in subProductList"
                :key="index"
                @click.stop="
                  p.disabled
                    ? openDisabledProductDialog(p)
                    : $emit('productSelected', {
                        id: p.id,
                        name: p.name,
                        price: p.price,
                      })
                "
                v-long-press="500"
                @long-press-start.stop="
                  p.disabled ? () => {} : disableProduct(p, true)
                "
                class="product-row"
                :class="p.disabled ? 'grey--text' : ''"
              >
                <td>
                  <div class="d-flex align-center productImageInListContainer">
                    <img
                      :src="getImgUrl(p.logoId)"
                      draggable="false"
                      disabled="true"
                      class="productImageInList"
                    />
                    <div
                      v-if="p.disabled"
                      class="d-flex justify-end align-end productDisabled"
                    >
                      <v-icon class="ma-1 grey--text text--lighten-1"
                        >mdi-food-off</v-icon
                      >
                    </div>
                  </div>
                </td>
                <td
                  :class="
                    $vuetify.breakpoint.mdAndUp
                      ? 'subtitle-1 font-weight-regular'
                      : ''
                  "
                >
                  {{ p.name }}
                </td>
                <td
                  :class="
                    $vuetify.breakpoint.mdAndUp
                      ? 'subtitle-1 font-weight-regular'
                      : ''
                  "
                >
                  {{ p.price.toFixed(2).replace(".", ",") }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div v-else>
          <div class="pa-6">{{ $t("message.noProductsInCategory") }}</div>
        </div>
      </div>
      <div
        v-else
        class="productsSpinner d-flex flex-column justify-center align-center"
      >
        <v-progress-circular
          :size="250"
          :width="3"
          indeterminate
          color="primary"
          >{{ $t("message.noProducts") }}</v-progress-circular
        >
      </div>
    </v-card>
    <v-dialog v-model="disabledProductDialog" width="350">
      <v-card>
        <v-card-title class="headline primary black--text">
          <v-icon class="mr-2" color="black" large>mdi-food-off</v-icon
          >{{ $t("label.disabledProduct") }}
        </v-card-title>

        <v-card-text class="mt-4 subtitle-1">
          {{ $t("message.disabledProduct") }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary black--text"
            @click="disableProduct(selectedProduct, false)"
          >
            {{ $t("label.activate") }}
          </v-btn>
          <v-btn color="primary" text @click="disabledProductDialog = false">
            {{ $t("label.close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { VueAgile } from "vue-agile";

export default {
  name: "Products",
  components: {
    agile: VueAgile,
  },
  props: {
    products: {
      type: Array,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
    tabNr: {
      type: Number,
      default: 0,
    },
    productViewingMode: {
      type: Number,
      default: 0,
    },
    orderId: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      disabledProductDialog: false,
      selectedProduct: undefined,
      isSlideLoaded: false,
      classSuffix: "",
      currentCategoryTab: 0,
      nbrOfMaxProducts: 0,
      viewingMode: 0,
      slides: [],
      subProductList: [],
      tabBarKey: 0,
      carouselKey: 0,
    };
  },

  methods: {
    categoryTabSelected(category) {
      const index = category
        ? this.categories.findIndex((c) => c.id === category.id)
        : -1;
      this.$emit("categorySelected", index + 1);
      if (this.viewingMode === 0) {
        this.setupSlides(category ? category.id : undefined);
      } else {
        this.prepareSubProductList();
      }
    },
    getImgUrl(logoId) {
      return `${this.$app.urls.productLogos}/${logoId}`;
    },
    calcMaxProducts() {
      if (
        this.$refs.p00 &&
        this.$refs.productsPage &&
        this.viewingMode === 0 &&
        this.categories &&
        this.categories.length > 0 &&
        this.products &&
        this.products.length > 0
      ) {
        const productsPanelWidth = this.$refs.productsPage.clientWidth;
        let imgPadding = 10;
        if (productsPanelWidth < 675) {
          this.classSuffix = "-xs";
          imgPadding = 10;
        } else {
          this.classSuffix = productsPanelWidth > 950 ? "-lg" : "-md";
          imgPadding = 20;
        }
        const imgWidth = this.$refs.p00.clientWidth + imgPadding;
        const nbrOfProductCols = parseInt(productsPanelWidth / imgWidth);
        const height =
          this.$refs.productsPage.parentElement.clientHeight -
          this.$refs.viewingMode.clientHeight -
          32;
        const nbrOfProductRows = parseInt(height / imgWidth);
        this.nbrOfMaxProducts = nbrOfProductRows * nbrOfProductCols;
        this.setupSlides(
          this.currentCategoryTab === 0
            ? undefined
            : this.categories[this.currentCategoryTab - 1].id
        );
      }
    },
    setupSlides(categoryId) {
      if (this.categories && this.categories.length > 0) {
        this.isSlideLoaded = false;
        this.slides.splice(0, this.slides.length);
        if (categoryId) {
          const cat = this.categories.find((c) => c.id === categoryId);
          if (cat) {
            this.setupIndividualSlices(cat);
          }
        } else {
          this.setupIndividualSlices(undefined);
        }
        this.isSlideLoaded = true;
        this.carouselKey = this.$uuid.v4();
        this.$nextTick(() => {
          if (this.$refs.carousel) {
            this.$refs.carousel.goTo(0);
          }
        });
      }
    },
    setupIndividualSlices(category) {
      const productsForCategory = this.filterProducts(
        category ? category.id : undefined
      );
      let productsSlideForCategory = [];
      for (let i = 0; i < productsForCategory.length; i++) {
        productsSlideForCategory.push(productsForCategory[i]);
        if (productsSlideForCategory.length === this.nbrOfMaxProducts) {
          this.slides.push(productsSlideForCategory);
          productsSlideForCategory = [];
        }
      }

      if (this.slides.length === 0 || productsSlideForCategory.length > 0) {
        this.slides.push(productsSlideForCategory);
      }
    },
    filterProducts(categoryId) {
      return categoryId
        ? this.products
            .slice()
            .filter((p) => p.categoryId === categoryId)
            .sort((p1, p2) => p1.sequenceNumber - p2.sequenceNumber)
        : this.products
            .slice()
            .filter((p) => p.favorite)
            .sort(
              (p1, p2) => p1.favoriteSequenceNumber - p2.favoriteSequenceNumber
            );
    },
    prepareGridList() {
      this.$nextTick(() => {
        this.$emit("productViewingModeChanged", this.viewingMode);
        this.calcMaxProducts();
      });
    },
    prepareSubProductList() {
      this.subProductList = this.filterProducts(
        this.currentCategoryTab === 0
          ? undefined
          : this.categories[this.currentCategoryTab - 1].id
      );
      this.$nextTick(() => {
        this.$emit("productViewingModeChanged", this.viewingMode);
      });
    },
    init() {
      this.tabBarKey += 1;
      this.$nextTick(() => {
        this.$nextTick(() => {
          if (this.viewingMode === 0) {
            this.calcMaxProducts();
          } else {
            this.prepareSubProductList();
          }
        });
      });
    },
    openDisabledProductDialog(product) {
      this.selectedProduct = product;
      this.disabledProductDialog = true;
    },
    disableProduct(product, disabled) {
      let url = this.$app.urls.disableProduct
        .replace("{tenantId}", this.$estore.get("tenant.tenantId"))
        .replace("{cashierId}", this.$estore.get("deviceId"))
        .replace("{productId}", product.id);

      this.$http({
        url: url,
        method: "put",
        data: { disabled: disabled },
      }).then(
        (response) => {
          if (response.status === 200) {
            this.disabledProductDialog = disabled;
          }
        },
        (error) => {
          console.log(
            `Error response for ${
              disabled ? "disabling" : "enabling"
            } product '${product.name}' (${product.id}): ${error}`
          );
        }
      );
    },
  },
  mounted() {
    this.init();
  },
  watch: {
    tabNr(value) {
      if (this.categories && this.categories.length > 0) {
        this.currentCategoryTab =
          value >= 0 && value < this.categories.length + 1 ? value : 0;
        this.categoryTabSelected(
          value === 0 ? undefined : this.categories[this.currentCategoryTab - 1]
        );
      }
    },
    productViewingMode(value) {
      this.viewingMode = value;
    },
    categories(newCategories, oldCategories) {
      const oldSelectedCategory =
        this.currentCategoryTab === 0
          ? undefined
          : oldCategories[this.currentCategoryTab - 1];
      const oldSelectedCategoryId = oldSelectedCategory
        ? oldSelectedCategory.id
        : null;
      const newSelectedCategory = oldSelectedCategoryId
        ? newCategories.find(
            (newCategory) => newCategory.id === oldSelectedCategoryId
          )
        : undefined;

      this.init();
      this.currentCategoryTab = newSelectedCategory
        ? newCategories.findIndex(
            (newCategory) => newCategory.id === newSelectedCategory.id
          ) + 1
        : 0;
      this.categoryTabSelected(newSelectedCategory);
    },
    products() {
      const oldSelectedTab = this.currentCategoryTab;

      this.init();
      this.currentCategoryTab = oldSelectedTab;
      this.categoryTabSelected(
        oldSelectedTab === 0 ? undefined : this.categories[oldSelectedTab - 1]
      );
    },
  },
};
</script>

<style>
/* has to be global, not scoped because of tabs */
.categoryTabs {
  height: var(--var-order-tabs-height);
  margin-left: -1px;
  margin-right: -1px;
}

.categoryTab {
  min-width: 0;
  background-color: #303030;
  border-bottom: 4px solid #121212;
  border-left: 1px solid #121212;
  border-right: 1px solid #121212;
}

.activeCategoryTab {
  background-color: #424242;
}

.productsSpinner {
  height: calc(
    100vh - var(--var-header-height) - 2 * var(--var-order-tabs-height) - 8px
  );
}

.productsSlide {
  margin: 0 !important;
  padding: 0 !important;
}

.viewingMode {
  height: var(--var-order-tabs-height);
}

.productsTable > .v-data-table__wrapper::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 25px #424242 !important;
  background-color: #424242 !important;
}
.productsTable > .v-data-table__wrapper::-webkit-scrollbar {
  width: 25px !important;
  background-color: #424242 !important;
}
.productsTable > .v-data-table__wrapper::-webkit-scrollbar-thumb {
  background-color: #757575 !important;
}

.productImageInListContainer {
  display: inline-block;
  position: relative;
  width: 100px;
}

.productImageInList {
  width: 100px;
  height: auto;
  margin: 0;
  padding: 5px;
}

.productPlaceholder-xs,
.productImage-xs {
  width: 78px;
  height: auto;
  margin: 5px;
  padding: 0;
  display: inline-block;
  position: relative;
}

.productPlaceholder-md,
.productImage-md {
  width: 125px;
  height: auto;
  margin: 10px;
  padding: 0;
  display: inline-block;
  position: relative;
}

.productPlaceholder-lg,
.productImage-lg {
  width: 139px;
  height: auto;
  margin: 10px;
  padding: 0;
  display: inline-block;
  position: relative;
}

.productPlaceholder,
.productPlaceholder-md,
.productPlaceholder-lg {
  height: 1px;
  visibility: hidden;
  margin-top: 0px;
  margin-bottom: 0px;
}

.productRipple {
  border-radius: 10px;
  display: inline;
}

.agile__nav-button {
  background: transparent;
  border: none;
  color: #ccc;
  cursor: pointer;
  font-size: 24px;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.agile__nav-button:hover {
  color: #888;
}
.agile__dot {
  margin: 0 10px;
}
.agile__dot button {
  background-color: #757575;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: block;
  height: 10px;
  font-size: 0;
  line-height: 0;
  margin: 0;
  padding: 0;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  width: 10px;
}

.agile__dot--current button,
.agile__dot:hover button {
  background-color: var(--v-primary-base);
}

ul.agile__dots li:only-child {
  visibility: hidden;
}

.productDisabled,
.productDisabled-xs,
.productDisabled-md,
.productDisabled-lg {
  position: absolute;
  background: rgba(33, 33, 33, 0.65);
  color: white;
  opacity: 1;
  bottom: 10px;
  height: calc(100% - 10px);
  width: 100%;
  border-radius: 8px;
}

.productDisabled {
  width: 86px;
  height: calc(100% - 16px);
  bottom: 8px;
  left: 7px;
  border-radius: 0;
}

.productDisabled-xs {
  bottom: 5px;
  height: calc(100% - 5px);
}

.productDisabled-lg {
  width: calc(100% - 14px);
}
</style>
