<template>
  <v-bottom-sheet v-model="show" persistent class="topupDialog">
    <v-sheet class="text-center">
      <div class="d-flex justify-center">
        <v-card v-if="!topup.paymentOngoing" flat max-width="320">
          <div class="d-flex mb-2">
            <v-list class="pa-0">
              <v-list-item>
                <v-list-item-content class="pa-0">
                  <v-text-field
                    class="centered-input headline pa-3 pl-5 pr-5"
                    color="primary"
                    id="amount"
                    ref="amount"
                    v-model="amount"
                    :full-width="true"
                    prepend-inner-icon="mdi-currency-eur"
                    :prepend-icon="correctionMode ? 'mdi-minus' : ''"
                    outlined
                    :hide-details="true"
                    :autofocus="true"
                    :placeholder="this.$t('label.amount')"
                  ></v-text-field>
                  <div class="topupKeyboard">
                    <SimpleKeyboard
                      @onChange="onChange"
                      :amount="true"
                      :inputName="amount"
                      :inputValue="amount"
                      :layout="amountLayout"
                      classname="topupkeyboard"
                    />
                  </div>
                </v-list-item-content>
              </v-list-item>
            </v-list>

            <div class="d-flex flex-column-reverse mt-2">
              <v-btn
                color="error"
                class="flex-grow-1 my-4"
                style="color: black; font-weight: bold; max-height: 75px"
                @click="show = false"
                >{{ $t("label.cancel") }}</v-btn
              >

              <v-btn
                v-if="
                  !correctionMode &&
                  !!$estore.get(
                    'tenant.deviceSettings.onlinePaymentAllowedForTopups'
                  )
                "
                :disabled="
                  amountNumber <
                    ($estore.get(
                      'tenant.deviceSettings.onlinePaymentMinimumTopupAmount'
                    ) || 1) || isNoScannerAvailable()
                "
                color="rgba(255,192,69,0.9)"
                class="topup-bancontact-button flex-grow-1 my-4"
                style="color: black; font-weight: bold; max-height: 75px"
                @click="confirmTopupCard('BANCONTACT')"
                >Bancontact</v-btn
              >

              <v-btn
                v-if="correctionMode"
                :disabled="amountNumber === 0 || isNoScannerAvailable()"
                color="rgba(255,192,69,0.9)"
                class="topup-topupz-button flex-grow-1 my-4"
                style="color: black; font-weight: bold; max-height: 75px"
                @click="confirmTopupCard('TOPUPZ')"
                >Topupz</v-btn
              >

              <v-btn
                :disabled="amountNumber === 0 || isNoScannerAvailable()"
                color="rgba(255,192,69,0.9)"
                class="topup-cash-button flex-grow-1 my-4"
                style="color: black; font-weight: bold; max-height: 75px"
                @click="confirmTopupCard('CASH')"
                >Cash</v-btn
              >
            </div>
          </div>
        </v-card>
        <div v-else class="topupProcessPanel">
          <WaiterProcessStatus
            :title="topupAmount"
            processName="topup"
            :process="topup.process"
            :correction-mode="correctionMode"
            :showRemainingFreeOrders="false"
            :connected="connected"
            @cancelPayment="$emit('cancelPayment')"
            @retry="resetTopup"
            @done="closeWithEmit('done')"
          ></WaiterProcessStatus>
        </div>
      </div>
    </v-sheet>
    <SelectScannerDialog
      :visible="scannerDialog"
      :availableScanners="availableScanners"
      @scannerSelected="confirmTopupCardForScanner"
      @close="scannerDialog = false"
    ></SelectScannerDialog>
  </v-bottom-sheet>
</template>

<script>
import SimpleKeyboard from "@/components/Utils/SimpleKeyboard.vue";
import WaiterProcessStatus from "@/components/Cashier/WaiterProcessStatus.vue";
import SelectScannerDialog from "@/components/Cashier/SelectScannerDialog.vue";

export default {
  name: "topup-dialog",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    topup: {
      type: Object,
      required: true,
    },
    availableScanners: {
      type: Array,
      required: true,
    },
    correctionMode: {
      type: Boolean,
      default: false,
    },
    connected: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    SimpleKeyboard,
    WaiterProcessStatus,
    SelectScannerDialog,
  },
  data() {
    return {
      amountLayout: {
        default: ["1 2 3", "4 5 6", "7 8 9", "{backspace} 0 ,"],
      },
      amount: "",
      amountNumber: 0,
      scannerDialog: false,
      paymentMethod: "CASH",
    };
  },
  methods: {
    resetTopup() {
      this.amount = "";
      this.$emit("retry");
    },
    closeWithEmit(event) {
      this.close();
      this.$emit(event);
    },
    close() {
      this.show = false;
    },
    onChange(input) {
      this.amount = input;
    },
    confirmTopupCard(paymentMethod) {
      this.paymentMethod = paymentMethod;
      if (this.availableScanners && this.availableScanners.length > 0) {
        if (
          this.availableScanners.length === 1 &&
          this.availableScanners[0].joinable
        ) {
          this.$emit(
            "topupCard",
            this.availableScanners[0].id,
            this.amountNumber,
            this.paymentMethod
          );
        } else {
          this.scannerDialog = true;
        }
      }
    },
    confirmTopupCardForScanner(id) {
      this.$emit("topupCard", id, this.amountNumber, this.paymentMethod);
    },
    isNoScannerAvailable() {
      return (
        !this.availableScanners ||
        this.availableScanners.length === 0 ||
        (this.availableScanners.length === 1 &&
          !this.availableScanners[0].joinable)
      );
    },
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        this.amount = "";
        if (!value) {
          this.$emit("close");
        }
      },
    },
    topupAmount() {
      return this.$t("label.topupAmount", {
        amount: this.amountNumber.toFixed(2).replace(".", ","),
      });
    },
  },
  watch: {
    amount(newValue, oldValue) {
      // needed in case keyboard is used, touchscreen is handled by SimpleKeyboard
      if (newValue) {
        const regex = /^(0|[1-9]\d*),?(\d{1,2})?$/g;
        const result = newValue.match(regex);
        if (result === null) {
          this.$nextTick(() => (this.amount = oldValue));
        } else {
          this.amountNumber =
            parseFloat(newValue.replace(",", ".")) *
            (this.correctionMode ? -1 : 1);
        }
      } else {
        this.amountNumber = 0;
        this.$nextTick(() => (this.amount = ""));
      }
    },
    topup(newValue) {
      if (newValue && newValue.amount !== 0) {
        this.amount = `${Math.abs(newValue.amount)}`;
      }
    },
  },
};
</script>

<style>
.topupProcessPanel {
  height: 430px;
  width: 800px;
}

.topupKeyboard > .simple-keyboard {
  width: 215px !important;
  background-color: #424242 !important;
  padding: 0;
}
.topupKeyboard > .simple-keyboard > .hg-row > .hg-standardBtn,
.topupKeyboard > .simple-keyboard > .hg-row > .hg-button-ent,
.topupKeyboard > .simple-keyboard > .hg-row > .hg-button-backspace {
  min-width: 65px !important;
  max-width: 65px !important;
  min-height: 65px !important;
  max-height: 65px !important;
  width: 65px !important;
  height: 65px !important;
}

.topupKeyboard > .simple-keyboard > .hg-row > .hg-button {
  color: #424242;
  font-size: 1.7em;
}

.topupKeyboard > .simple-keyboard .hg-row .hg-button:not(:last-child) {
  margin-right: 10px;
}

.topupKeyboard > .simple-keyboard .hg-row:not(:last-child) {
  margin-bottom: 10px;
}

.topupBtnGroup {
  height: var(--var-order-btn-group-height);
}
</style>
