import Vue from "vue";
import VueRouter from "vue-router";
import Cashier from "../views/Cashier.vue";
import Setup from "../views/Setup.vue";
import Loading from "../views/Loading.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/cashier",
    name: "cashier",
    component: Cashier
  },
  {
    path: "/",
    name: "loading",
    component: Loading
  },
  {
    path: "/setup",
    name: "setup",
    component: Setup
  }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

export default router;
