<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
export default {
  name: "App",
  created() {
    console.log(this.$estore.path);
    //set in store so it logic can be implemented as balena also can provide env vars in future and should have priority
    this.$estore.setOrDelete("localDev", process.env.VUE_APP_LOCAL_DEV);
    this.$estore.setOrDelete("envPrefix", process.env.VUE_APP_ENV_PREFIX);
    this.$estore.setOrDelete("apiServer", process.env.VUE_APP_API_SERVER);
    this.$estore.setOrDelete("authServer", process.env.VUE_APP_AUTH_SERVER);

    if (!this.$estore.get("tenant.tenantId") && this.$router.currentRoute.path !== '/setup') {
      this.$router.push("/setup");
    }
  },
};
</script>

<style>
html {
  overflow: hidden !important;
  scroll-behavior: smooth;
  min-height: 480px;
}

body {
  --var-header-height: 80px;
  --var-modal-header-height: 56px;
  --var-order-tabs-height: 48px;
  --var-order-btn-group-height: 48px;
  user-select: none;
}

.v-tabs-bar {
  background-color: unset !important;
}

.v-slide-group__next,
.v-slide-group__prev {
  background-color: #272727;
  min-width: 35px !important;
  max-width: 35px !important;
  margin-bottom: 4px;
}

div.v-dialog:not(.v-dialog--fullscreen) div.v-sheet,
div[role="menu"] div.v-sheet,
div.v-dialog:not(.v-dialog--fullscreen) div.v-card,
div.v-dialog:not(.v-dialog--fullscreen) div.v-card > div.v-list {
  background-color: #424242;
}

.text-primary {
    color: var(--v-primary-base);
}
.text-warning {
    color: var(--v-warning-base);
}
.text-error {
    color: var(--v-error-base);
}
</style>
