var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column pa-1"},[_c('div',{staticClass:"orderTabs"},[(!_vm.correctionMode)?_c('v-tabs',{attrs:{"active-class":"activeTab","color":"primary","hide-slider":true,"grow":true},model:{value:(_vm.currentOrderTab),callback:function ($$v) {_vm.currentOrderTab=$$v},expression:"currentOrderTab"}},_vm._l((_vm.nbrOfTabs),function(n){return _c('v-tab',{key:n,staticClass:"pa-0 title orderTab",on:{"click":function($event){return _vm.orderTabSelected(n)}}},[_c('v-badge',{attrs:{"content":_vm.findOrder(n).totalItems,"value":_vm.findOrder(n).totalItems,"left":"","offset-y":"12","offset-x":"-5","color":_vm.currentTab === n ? 'grey darken-1' : 'grey darken-3'}},[_c('v-badge',{attrs:{"color":_vm.showBadgeColor(n),"value":_vm.showBadge(n),"dot":"","offset-x":"-5"}},[_vm._v(" "+_vm._s(n)+" ")])],1)],1)}),1):_c('v-tabs',{attrs:{"color":"white","hide-slider":true,"grow":true}},[_c('v-tab',{staticClass:"pa-0 grey--text text--lighten-2 title correctionTab",attrs:{"ripple":false}},[_c('v-badge',{attrs:{"content":_vm.findOrder(-1).totalItems,"value":_vm.findOrder(-1).totalItems,"left":"","offset-y":"12","offset-x":"-5","color":"grey lighten-2 black--text"}},[_vm._v(" "+_vm._s(_vm.$t("label.correction"))+" ")])],1)],1)],1),_c('v-simple-table',{staticClass:"orderItemsTable",class:_vm.className('orderItemsTable'),attrs:{"height":_vm.orderTableHeight},scopedSlots:_vm._u([(
        _vm.orders && _vm.orders.length === _vm.nbrOfTabs + 1 && _vm.currentOrder.orderItems
      )?{key:"default",fn:function(){return [_c('tbody',_vm._l((_vm.currentOrder.orderItems),function(orderItem,index){return _c('tr',{key:index,staticClass:"order-item-row",class:{
            'grey--text': _vm.currentOrder.paymentRequested === true,
            lastAdded: _vm.currentOrder.lastAddedProductId === orderItem.id,
          },on:{"click":function($event){$event.stopPropagation();return _vm.selectOrderItem(orderItem)}}},[(
              !_vm.currentOrder.selectedOrderItem ||
              _vm.currentOrder.selectedOrderItem.id !== orderItem.id
            )?[_c('td',{staticClass:"order-item-quantity-label",class:_vm.$vuetify.breakpoint.mdAndUp
                  ? 'subtitle-1 font-weight-regular'
                  : ''},[_vm._v(" "+_vm._s(orderItem.total)+" ")]),_c('td',{staticClass:"order-item-name-label",class:_vm.$vuetify.breakpoint.mdAndUp
                  ? 'subtitle-1 font-weight-regular'
                  : ''},[_vm._v(" "+_vm._s(orderItem.name)+" ")]),_c('td',{staticClass:"order-item-amount-label",class:_vm.$vuetify.breakpoint.mdAndUp
                  ? 'subtitle-1 font-weight-regular'
                  : ''},[_vm._v(" €"+_vm._s(orderItem.totalAmount.toFixed(2).replace(".", ","))+" ")])]:[_c('td',{staticClass:"pa-2 selectedOrderItem",attrs:{"colspan":"3"}},[_c('v-card',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.unselectOrderItem),expression:"unselectOrderItem"}],staticClass:"d-flex flex-column pa-1 title",attrs:{"id":("adjust-" + index)}},[_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.currentOrder.selectedOrderItem.name)+" ")]),_c('div',{staticClass:"d-flex justify-space-between"},[_c('v-btn',{staticClass:"order-item-minus-button",attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('minusOrderItem', orderItem)}}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-minus")])],1),_c('span',[_vm._v(_vm._s(orderItem.total))]),_c('v-btn',{staticClass:"order-item-plus-button",attrs:{"color":"primary"},on:{"click":function($event){return _vm.$emit('plusOrderItem', orderItem)}}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-plus")])],1)],1)])],1)]],2)}),0)]},proxy:true}:null],null,true)}),_c('div',{staticClass:"d-flex align-center",class:_vm.className('orderBtnGroup')},[_c('v-btn',{staticClass:"order-pay-button black--text flex-grow-1 pa-0 mr-1 mt-1",class:_vm.className('payButton'),attrs:{"color":"primary","disabled":!_vm.orders ||
        _vm.orders.length !== _vm.nbrOfTabs + 1 ||
        _vm.currentOrder.paymentRequested === true ||
        !_vm.currentOrder.orderItems ||
        _vm.currentOrder.orderItems.length === 0 ||
        (_vm.isFreePlan && _vm.remainingFreeOrders === 0)},on:{"click":function($event){return _vm.$emit('paymentRequested')}}},[_vm._v(_vm._s(_vm.$t(("" + (this.correctionMode ? "label.refund" : "label.pay"))))+" € "+_vm._s(_vm.orderAmount))]),_c('v-btn',{staticClass:"order-clear-button mt-1",class:_vm.className('deleteOrderButton'),attrs:{"color":"#424242","disabled":!_vm.orders ||
        _vm.orders.length !== _vm.nbrOfTabs + 1 ||
        _vm.currentOrder.paymentRequested === true ||
        !_vm.currentOrder.orderItems ||
        _vm.currentOrder.orderItems.length === 0 ||
        (_vm.isFreePlan && _vm.remainingFreeOrders === 0)},on:{"click":function($event){return _vm.$emit('deleteOrder')}}},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-icon',{attrs:{"x-large":"","color":"primary"}},[_vm._v("mdi-delete")]):_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-delete")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }