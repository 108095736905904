var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-bottom-sheet',{staticClass:"topupDialog",attrs:{"persistent":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-sheet',{staticClass:"text-center"},[_c('div',{staticClass:"d-flex justify-center"},[(!_vm.topup.paymentOngoing)?_c('v-card',{attrs:{"flat":"","max-width":"320"}},[_c('div',{staticClass:"d-flex mb-2"},[_c('v-list',{staticClass:"pa-0"},[_c('v-list-item',[_c('v-list-item-content',{staticClass:"pa-0"},[_c('v-text-field',{ref:"amount",staticClass:"centered-input headline pa-3 pl-5 pr-5",attrs:{"color":"primary","id":"amount","full-width":true,"prepend-inner-icon":"mdi-currency-eur","prepend-icon":_vm.correctionMode ? 'mdi-minus' : '',"outlined":"","hide-details":true,"autofocus":true,"placeholder":this.$t('label.amount')},model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=$$v},expression:"amount"}}),_c('div',{staticClass:"topupKeyboard"},[_c('SimpleKeyboard',{attrs:{"amount":true,"inputName":_vm.amount,"inputValue":_vm.amount,"layout":_vm.amountLayout,"classname":"topupkeyboard"},on:{"onChange":_vm.onChange}})],1)],1)],1)],1),_c('div',{staticClass:"d-flex flex-column-reverse mt-2"},[_c('v-btn',{staticClass:"flex-grow-1 my-4",staticStyle:{"color":"black","font-weight":"bold","max-height":"75px"},attrs:{"color":"error"},on:{"click":function($event){_vm.show = false}}},[_vm._v(_vm._s(_vm.$t("label.cancel")))]),(
                !_vm.correctionMode &&
                !!_vm.$estore.get(
                  'tenant.deviceSettings.onlinePaymentAllowedForTopups'
                )
              )?_c('v-btn',{staticClass:"topup-bancontact-button flex-grow-1 my-4",staticStyle:{"color":"black","font-weight":"bold","max-height":"75px"},attrs:{"disabled":_vm.amountNumber <
                  (_vm.$estore.get(
                    'tenant.deviceSettings.onlinePaymentMinimumTopupAmount'
                  ) || 1) || _vm.isNoScannerAvailable(),"color":"rgba(255,192,69,0.9)"},on:{"click":function($event){return _vm.confirmTopupCard('BANCONTACT')}}},[_vm._v("Bancontact")]):_vm._e(),(_vm.correctionMode)?_c('v-btn',{staticClass:"topup-topupz-button flex-grow-1 my-4",staticStyle:{"color":"black","font-weight":"bold","max-height":"75px"},attrs:{"disabled":_vm.amountNumber === 0 || _vm.isNoScannerAvailable(),"color":"rgba(255,192,69,0.9)"},on:{"click":function($event){return _vm.confirmTopupCard('TOPUPZ')}}},[_vm._v("Topupz")]):_vm._e(),_c('v-btn',{staticClass:"topup-cash-button flex-grow-1 my-4",staticStyle:{"color":"black","font-weight":"bold","max-height":"75px"},attrs:{"disabled":_vm.amountNumber === 0 || _vm.isNoScannerAvailable(),"color":"rgba(255,192,69,0.9)"},on:{"click":function($event){return _vm.confirmTopupCard('CASH')}}},[_vm._v("Cash")])],1)],1)]):_c('div',{staticClass:"topupProcessPanel"},[_c('WaiterProcessStatus',{attrs:{"title":_vm.topupAmount,"processName":"topup","process":_vm.topup.process,"correction-mode":_vm.correctionMode,"showRemainingFreeOrders":false,"connected":_vm.connected},on:{"cancelPayment":function($event){return _vm.$emit('cancelPayment')},"retry":_vm.resetTopup,"done":function($event){return _vm.closeWithEmit('done')}}})],1)],1)]),_c('SelectScannerDialog',{attrs:{"visible":_vm.scannerDialog,"availableScanners":_vm.availableScanners},on:{"scannerSelected":_vm.confirmTopupCardForScanner,"close":function($event){_vm.scannerDialog = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }