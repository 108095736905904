<template>
  <div class="d-flex">
    <v-tabs v-model="currentWaiterTabId" color="#ffc045" :show-arrows="true">
      <v-tab
        v-for="(n, index) in activeWaiters.length"
        :key="index"
        :id="`waiterTab-${index}`"
        :disabled="(waiterMenu && index !== currentWaiterTabId) || disabled"
        class="title"
        :class="{
          indicateOrders:
            currentWaiterTabId + 1 !== n &&
            findWaiterInfo(n - 1).totalOrderItems > 0,
        }"
        v-long-press="500"
        v-on:long-press-start="showWaiterMenu(index)"
        v-on:click="selectWaiterId(index)"
      >
        <v-badge
          :color="showBadgeColor(index)"
          :value="showBadge(index)"
          dot
          offset-x="-5"
        >
          {{ getWaiterName(index) }}
        </v-badge>
      </v-tab>
    </v-tabs>
    <v-btn
      class="add-waiter-button"
      icon
      :disabled="waiterMenu || disabled"
      v-if="showAddWaiters"
      v-on:click="addWaiter()"
    >
      <v-icon color="primary">mdi-account-plus</v-icon>
    </v-btn>

    <v-menu
      v-model="waiterMenu"
      :open-on-click="false"
      :close-on-click="false"
      :close-on-content-click="false"
      :activator="querySelectorForTab"
      offset-y
      :bottom="true"
    >
      <v-card max-width="640">
        <v-list>
          <v-list-item
            v-if="activeWaiters.length > 1"
            @click="isDeletable ? removeWaiter() : {}"
          >
            <v-list-item-icon v-if="!isDeletable">
              <v-icon large color="primary">mdi-progress-alert</v-icon>
            </v-list-item-icon>

            <v-list-item-content v-if="!isDeletable">
              <v-list-item-title class="title">{{
                $t("message.unremovableWaiter.error")
              }}</v-list-item-title>
              <v-list-item-subtitle class="subtitle-1">{{
                $t("message.unremovableWaiter.reason_" + unremovableReason)
              }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-content v-else>
              <v-list-item-title class="title">{{
                $t("message.removeWaiter")
              }}</v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-icon
                :disabled="!isDeletable"
                :color="isDeletable ? 'primary' : ''"
                >{{
                  `${this.isDeletable ? "mdi-delete" : "mdi-delete-off"}`
                }}</v-icon
              >
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <v-divider v-if="activeWaiters.length > 1"></v-divider>

        <v-list>
          <v-list-item>
            <v-list-item-content class="pa-0">
              <v-text-field
                class="title"
                color="primary"
                :id="waiterName"
                :ref="waiterName"
                :label="$t('label.name')"
                v-model="waiterName"
                :dense="true"
                :full-width="true"
                :clearable="true"
                :rules="waiterNameRules"
                @focus="displayKeyboard = true"
              ></v-text-field>
              <div
                :class="{
                  displayKeyboardWaiterSelection: displayKeyboard,
                  hideKeyboardWaiterSelection: !displayKeyboard,
                }"
              >
                <div class="waiterSelectionKeyboard">
                  <SimpleKeyboard
                    @onChange="onChange"
                    @onKeyPress="onKeyPress"
                    :inputName="waiterName"
                    :inputValue="waiterName"
                    classname="waiterkeyboard"
                  />
                </div>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text @click="closeWaiterMenu">{{ $t("label.cancel") }}</v-btn>
          <v-btn
            :disabled="!waiterSavable"
            class="title"
            color="primary"
            text
            @click="save"
            >{{ $t("label.save") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import SimpleKeyboard from "@/components/Utils/SimpleKeyboard.vue";

export default {
  name: "waiterSelection",
  components: {
    SimpleKeyboard,
  },
  props: {
    waitersInfo: {
      type: Array,
      default: () => [],
    },
    waitersFeedback: {
      type: Array,
      default: () => [],
    },
    unremovableWaiters: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    initialWaiterId: {
      type: [Number, String], //TODO: it should be String. remove backwards compatibility once > v1.0.6 ?
      default: 0,
    },
  },
  data() {
    return {
      availableWaiters: this.$estore.get("tenant.availableWaiters", []),
      activeWaiters: this.$estore.get("tenant.activeWaiters", []),
      currentWaiterTabId: 0,

      waiterMenu: false,
      waiterName: "",
      waiterNameRules: [
        (value) =>
          (!!value && value.trim().length > 0) || this.$t("message.required"),
      ],
      displayKeyboard: false,
    };
  },
  methods: {
    findWaiterInfo(tab) {
      const info = this.waitersInfo
        ? this.waitersInfo.find((i) => i.id === this.getWaiterUuid(tab))
        : undefined;
      return info ? info : {};
    },
    showWaiterMenu(tabId) {
      if (this.currentWaiterTabId === tabId) {
        this.waiterName = this.getWaiterName(tabId);
        this.displayKeyboard = false;
        this.waiterMenu = true;
        this.$emit("waiterMenu", true);
      }
    },
    closeWaiterMenu() {
      this.waiterMenu = false;
      this.$emit("waiterMenu", false);
    },
    onChange(input) {
      this.waiterName = input;
    },
    onKeyPress(button) {
      if (button === "{ent}") {
        this.save();
      }
    },
    save() {
      if (this.waiterSavable) {
        this.closeWaiterMenu();
        const previous = this.currentWaiterTabId;

        const waiterId = this.getWaiterUuid(this.currentWaiterTabId);
        const waiter = this.activeWaiters.find((w) => w.id === waiterId);
        waiter.name = this.waiterName;

        this.$estore.set("tenant.activeWaiters", this.activeWaiters);

        this.currentWaiterTabId = 0;
        this.$nextTick(() => (this.currentWaiterTabId = previous));
      }
    },
    addWaiter() {
      const newWaiterName = this.availableWaiters.find(
        (element) => !this.activeWaiters.map((w) => w.name).includes(element)
      );
      const waiter = {
        id: this.$uuid.v4(),
        name: newWaiterName,
      };
      this.$estore.appendList("tenant.activeWaiters", waiter);
      this.currentWaiterTabId = this.activeWaiters.length - 1;
      this.selectWaiterId(this.currentWaiterTabId);
    },
    selectWaiterId(tabId) {
      this.$emit("selectedWaiterId", this.getWaiterUuid(tabId));
    },
    removeWaiter() {
      if (this.activeWaiters.length > 1) {
        const unremovableWaiter = this.unremovableWaiters.find(
          (w) => w.id === this.getWaiterUuid()
        );
        if (!unremovableWaiter) {
          const nextSelectedId = this.currentWaiterTabId === 0 ? 1 : 0;
          this.$emit("removedWaiterId", {
            waiterIndexRemoved: this.getWaiterUuid(),
            waiterIndexSelected: this.getWaiterUuid(nextSelectedId),
          });
          this.$estore.removeFromListAtIndex(
            "tenant.activeWaiters",
            this.currentWaiterTabId
          );
          this.currentWaiterTabId = nextSelectedId;
          this.closeWaiterMenu();
        }
      }
    },
    showBadge(waiterTabId) {
      let result = 0;
      if (waiterTabId !== this.currentWaiterTabId) {
        const feedback = this.waitersFeedback.find(
          (f) => f.id === this.getWaiterUuid(waiterTabId)
        );
        if (feedback && feedback.processes && feedback.processes.length > 0) {
          result = 1;
        }
      }
      return result;
    },
    showBadgeColor(waiterTabId) {
      let result = "orange";
      const feedback = this.waitersFeedback.find(
        (f) => f.id === this.getWaiterUuid(waiterTabId)
      );
      if (feedback && feedback.processes && feedback.processes.length > 0) {
        const ok = feedback.processes.findIndex((p) => p.success === true) >= 0;
        const nok =
          feedback.processes.findIndex((p) => p.success === false) >= 0;
        if (ok && !nok) result = "green";
        if (!ok && nok) result = "red";
      }
      return result;
    },
    getWaiterUuid(waiterTabId = this.currentWaiterTabId) {
      const waiter = this.activeWaiters[waiterTabId];
      //TODO: it should be object. remove backwards compatibility once > v1.0.6 ?
      return typeof waiter === "object" ? waiter.id : waiterTabId;
    },
    getWaiterName(waiterTabId = this.currentWaiterTabId) {
      const waiter = this.activeWaiters[waiterTabId];
      //TODO: it should be object. remove backwards compatibility for string once > v1.0.6 ?
      return typeof waiter === "object" ? waiter.name : waiter;
    },
    getWaiterTabId(uuid) {
      let result;
      //TODO: it should be an uuid. remove backwards compatibility for string once > v1.0.6 ?
      if (
        /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/.test(
          uuid
        )
      ) {
        const index = this.activeWaiters.findIndex((w) => w.id === uuid);
        result = index !== -1 ? index : 0;
      } else {
        result = uuid; //it is an index of an array in this case
      }
      return result;
    },
  },
  computed: {
    showAddWaiters() {
      return this.activeWaiters.length < this.availableWaiters.length;
    },
    querySelectorForTab() {
      return `#waiterTab-${this.currentWaiterTabId}`;
    },
    isDeletable() {
      return !this.unremovableWaiters ||
        !this.unremovableWaiters.find((w) => w.id === this.getWaiterUuid())
        ? true
        : false;
    },
    unremovableReason() {
      const waiter = this.unremovableWaiters
        ? this.unremovableWaiters.find((w) => w.id === this.getWaiterUuid())
        : undefined;
      return waiter ? waiter.reason : "undefined";
    },
    waiterSavable() {
      return (
        this.waiterName &&
        this.waiterName.trim().length > 0 &&
        this.waiterName !== this.getWaiterName()
      );
    },
  },
  mounted() {
    if (this.activeWaiters.length === 0) {
      const waiter = {
        id: this.$uuid.v4(),
        name: this.availableWaiters[0],
      };
      this.$estore.appendList("tenant.activeWaiters", waiter);
    }

    this.$emit(
      "selectedWaiterId",
      this.initialWaiterId ? this.initialWaiterId : this.getWaiterUuid(0)
    ); // on startup intial waiter initialize
  },
  created() {
    this.$estore.onDidChange(
      "tenant.activeWaiters",
      (newValue) => (this.activeWaiters = newValue)
    );
  },
  watch: {
    initialWaiterId(newValue) {
      this.currentWaiterTabId = this.getWaiterTabId(newValue);
    },
  },
};
</script>

<style>
.indicateOrders {
  border-bottom: #2196f3 2px solid;
}

.displayKeyboardWaiterSelection {
  visibility: visible;
  /* height: 200px */
}

.hideKeyboardWaiterSelection {
  visibility: hidden;
  /* height: 10px */
}

.waiterSelectionKeyboard > .simple-keyboard {
  height: 200px;
  background-color: #424242 !important;
}

.waiterSelectionKeyboard > .simple-keyboard > .hg-row > .hg-standardBtn,
.waiterSelectionKeyboard > .simple-keyboard > .hg-row > .hg-button-abc,
.waiterSelectionKeyboard > .simple-keyboard > .hg-row > .hg-button-ent,
.waiterSelectionKeyboard > .simple-keyboard > .hg-row > .hg-button-sym,
.waiterSelectionKeyboard > .simple-keyboard > .hg-row > .hg-button-numbers,
.waiterSelectionKeyboard > .simple-keyboard > .hg-row > .hg-button-shift,
.waiterSelectionKeyboard > .simple-keyboard > .hg-row > .hg-button-space,
.waiterSelectionKeyboard > .simple-keyboard > .hg-row > .hg-functionBtn,
.waiterSelectionKeyboard > .simple-keyboard > .hg-row > .hg-button-backspace {
  min-width: 55px !important;
  max-width: 55px !important;
  width: 55px !important;
  min-height: 45px !important;
  max-height: 45px !important;
  height: 45px !important;
  color: #424242 !important;
  font-size: 1.7em !important;
}

.waiterSelectionKeyboard > .simple-keyboard > .hg-row > .hg-functionBtn {
  color: #424242 !important;
  font-size: 1.7em !important;
}

.waiterSelectionKeyboard > .simple-keyboard > .hg-row > .hg-button-backspace {
  min-width: 175px !important;
  max-width: 175px !important;
  width: 175px !important;
}

.waiterSelectionKeyboard > .simple-keyboard > .hg-row > .hg-button-sym {
  min-width: 115px !important;
  max-width: 115px !important;
  width: 115px !important;
}
.waiterSelectionKeyboard > .simple-keyboard > .hg-row > .hg-button-numbers,
.waiterSelectionKeyboard > .simple-keyboard > .hg-row > .hg-button-abc {
  min-width: 115px !important;
  max-width: 115px !important;
  width: 115px !important;
}

.waiterSelectionKeyboard > .simple-keyboard > .hg-row > .hg-button-space {
  min-width: 355px !important;
  max-width: 355px !important;
  width: 355px !important;
}

.waiterSelectionKeyboard > .simple-keyboard > .hg-row > .hg-button-ent {
  min-width: 115px !important;
  max-width: 115px !important;
  width: 115px !important;
}
</style>
