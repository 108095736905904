import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import LongPress from "vue-directive-long-press";
import moment from "moment";
import VueCryptojs from "vue-cryptojs";
import jwt_decode from "jwt-decode";
import axios from "axios";
import UUID from "vue-uuid";
import i18n from "./i18n";
import {createStore} from "@/store";
import {checkForUpdate, initServiceWorker} from "@/app";

initServiceWorker().catch(e => console.log(e))
checkForUpdate().catch(e => console.log(e))

function findObjects (obj, key, val) {
  let objects = [];
  for (let i in obj) {
    if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
    if (typeof obj[i] == "object") {
      objects = objects.concat(findObjects(obj[i], key, val));
    }
    //if key matches and value matches or if key matches and value is not passed (eliminating the case where key matches but passed value does not)
    else if ((i == key && obj[i] == val) || (i == key && val == "")) {
      //
      objects.push(obj);
    } else if (obj[i] == val && key == "") {
      //only add if the object is not already in the array
      if (objects.lastIndexOf(obj) == -1) {
        objects.push(obj);
      }
    }
  }
  return objects;
}

function isEqual (obj1, obj2) {
  const obj1Keys = Object.keys(obj1);
  const obj2Keys = Object.keys(obj2);

  if (obj1Keys.length !== obj2Keys.length) {
    return false;
  }

  for (let objKey of obj1Keys) {
    if (obj1[objKey] !== obj2[objKey]) {
      return false;
    }
  }

  return true;
}

function sortItems (items, key, sortAscending = true) {
  items.sort((i1, i2) => {
    const v1 = getItemProperty(i1, key)
    const v2 = getItemProperty(i2, key)
    if (v1 === v2) {
      return 0
    }
    if (v1 == null) {
      return sortAscending ? 1 : -1
    }
    if (v2 == null) {
      return sortAscending ? -1 : 1
    }
    if (typeof v1 === 'boolean' && v1) {
      return sortAscending ? -1 : 1
    }
    if (typeof v1 === 'boolean' && !v1) {
      return sortAscending ? 1 : -1
    }
    if (v1 > v2) {
      return sortAscending ? 1 : -1
    }
    return sortAscending ? -1 : 1
  })
}

function getItemProperty (item, key) {
  if (!key) {
    return item
  }
  if (key instanceof Function) {
    return key(item)
  }
  return item[key]
}

Vue.use(VueCryptojs);

let $estore = createStore("cashier-config", {
  envVars: {
    localDev: process.env.VUE_APP_LOCAL_DEV,
    envPrefix: process.env.VUE_APP_ENV_PREFIX,
    apiServer: process.env.VUE_APP_API_SERVER,
    authServer: process.env.VUE_APP_AUTH_SERVER,
  },
  heartbeatTimeout: 7000,
  tenant: {
    availableWaiters: [
      "Dopey",
      "Grumpy",
      "Happy",
      "Sleepy",
      "Bashful",
      "Sneezy",
      "Doc"
    ],
    activeWaiters: [],
    nbrRunningProcessAtClose: 0,
    ongoingOrders: [],
    processesToSync: [],
    scanners: [],
    favoriteScanners: [],
    deviceSettings: {
      pinCode: "1111",
      locale: "nl_BE"
    },
    correction: {
      mode: false,
      previousOrderTab: 1,
      waiterId: 0
    },
    orderFilters: {
      initiator: 0,
      status: 0
    }
  }
});

let $pstore = createStore("products-config", {
  tenant: {
    products: [],
    categories: []
  }
});

Vue.mixin({
  beforeCreate () {
    this.$estore = $estore
    this.$pstore = $pstore

    //app related
    let localDevValue = this.$estore.get("envVars.localDev")
      ? this.$estore.get("envVars.localDev")
      : this.$estore.get("localDev");
    let envPrefixValue = this.$estore.get("envVars.envPrefix")
      ? this.$estore.get("envVars.envPrefix")
      : this.$estore.get("envPrefix");

    let apiServerValue = this.$estore.get("envVars.apiServer")
      ? this.$estore.get("envVars.apiServer")
      : this.$estore.get("apiServer");
    let authServerValue = this.$estore.get("envVars.authServer")
      ? this.$estore.get("envVars.authServer")
      : this.$estore.get("authServer");

    this.$app = {};
    this.$app.findObjects = findObjects;
    this.$app.isEqual = isEqual;
    this.$app.sortItems = sortItems;
    this.$app.getItemProperty = getItemProperty;
    this.$app.isLocalDev =
      typeof localDevValue !== "undefined" &&
      (localDevValue === "1" || localDevValue.toLowerCase() === "true");
    this.$app.isProd = this.$app.isLocalDev ? false : !envPrefixValue;
    this.$app.env = this.$app.isLocalDev
      ? "local"
      : envPrefixValue
        ? `${
          envPrefixValue.endsWith(".")
            ? envPrefixValue.slice(0, -1)
            : envPrefixValue
        }`
        : "";
    this.$app.urls = {};

    const protocolPrefix = this.$app.isLocalDev ? "http://" : "https://";
    const tenantPart = "tenants/{tenantId}";

    this.$app.urls.oidcToken = `${protocolPrefix}${
      this.$app.isLocalDev ? "" : this.$app.env ? this.$app.env + "." : ""
    }${authServerValue}/auth/realms/topupz/protocol/openid-connect/token`;

    this.$app.urls.softwareRegistration = `${protocolPrefix}${
      this.$app.isLocalDev ? "" : this.$app.env ? this.$app.env + "." : ""
    }${apiServerValue}/${tenantPart}/software-devices/{deviceId}`;

    this.$app.urls.products = `${protocolPrefix}${
      this.$app.isLocalDev ? "" : this.$app.env ? this.$app.env + "." : ""
    }${apiServerValue}/${tenantPart}/products`;

    this.$app.urls.disableProduct = `${protocolPrefix}${
      this.$app.isLocalDev ? "" : this.$app.env ? this.$app.env + "." : ""
    }${apiServerValue}/${tenantPart}/cashiers/{cashierId}/products/{productId}`;

    this.$app.urls.productLogos = `${protocolPrefix}${
      this.$app.isLocalDev ? "" : this.$app.env ? this.$app.env + "." : ""
    }${apiServerValue}/product-logos`;

    this.$app.urls.productCategories = `${protocolPrefix}${
      this.$app.isLocalDev ? "" : this.$app.env ? this.$app.env + "." : ""
    }${apiServerValue}/${tenantPart}/product-categories`;

    this.$app.urls.deviceCommands = `${protocolPrefix}${
      this.$app.isLocalDev ? "" : this.$app.env ? this.$app.env + "." : ""
    }${apiServerValue}/${tenantPart}/devices/{deviceId}/events`;

    this.$app.urls.deviceOnline = `${protocolPrefix}${
      this.$app.isLocalDev ? "" : this.$app.env ? this.$app.env + "." : ""
    }${apiServerValue}/${tenantPart}/devices/{deviceId}/online`;

    this.$app.urls.membershipRequest = `${protocolPrefix}${
      this.$app.isLocalDev ? "" : this.$app.env ? this.$app.env + "." : ""
    }${apiServerValue}/${tenantPart}/cashiers/{deviceId}/membership-requests/{membershipRequestId}`;

    this.$app.urls.process = {
      currentOrders: `${protocolPrefix}${
        this.$app.isLocalDev ? "" : this.$app.env ? this.$app.env + "." : ""
      }${apiServerValue}/${tenantPart}/processes/{processName}/instances`,
      unassignedTasksCount: `${protocolPrefix}${
        this.$app.isLocalDev ? "" : this.$app.env ? this.$app.env + "." : ""
      }${apiServerValue}/${tenantPart}/processes/{processName}/instances/count-unassigned-tasks`,
      tenantOrderInfo: `${protocolPrefix}${
        this.$app.isLocalDev ? "" : this.$app.env ? this.$app.env + "." : ""
      }${apiServerValue}/${tenantPart}/processes/order/instances/{processUuid}`,
      start: `${protocolPrefix}${
        this.$app.isLocalDev ? "" : this.$app.env ? this.$app.env + "." : ""
      }${apiServerValue}/${tenantPart}/cashiers/{cashierId}/processes/{processName}/instances/{processUuid}`,
      completeOffline: `${protocolPrefix}${
        this.$app.isLocalDev ? "" : this.$app.env ? this.$app.env + "." : ""
      }${apiServerValue}/${tenantPart}/cashiers/{cashierId}/processes/{processName}/instances/{processUuid}/complete-offline`,
      cancel: `${protocolPrefix}${
        this.$app.isLocalDev ? "" : this.$app.env ? this.$app.env + "." : ""
      }${apiServerValue}/${tenantPart}/cashiers/{cashierId}/processes/{processName}/instances/{processUuid}/cancel`,
      events: `${protocolPrefix}${
        this.$app.isLocalDev ? "" : this.$app.env ? this.$app.env + "." : ""
      }${apiServerValue}/${tenantPart}/cashiers/{cashierId}/processes/{processName}/instances/{processUuid}/events`,
      items: `${protocolPrefix}${
        this.$app.isLocalDev ? "" : this.$app.env ? this.$app.env + "." : ""
      }${apiServerValue}/${tenantPart}/cashiers/{cashierId}/processes/{processName}/instances/{processUuid}/items`,
      completeEntry: `${protocolPrefix}${
        this.$app.isLocalDev ? "" : this.$app.env ? this.$app.env + "." : ""
      }${apiServerValue}/${tenantPart}/cashiers/{cashierId}/processes/{processName}/instances/{processUuid}/complete-entry`,
      delegatePreparation: `${protocolPrefix}${
        this.$app.isLocalDev ? "" : this.$app.env ? this.$app.env + "." : ""
      }${apiServerValue}/${tenantPart}/cashiers/{cashierId}/processes/{processName}/instances/{processUuid}/delegate-preparation`,
      startPreparation: `${protocolPrefix}${
        this.$app.isLocalDev ? "" : this.$app.env ? this.$app.env + "." : ""
      }${apiServerValue}/${tenantPart}/cashiers/{cashierId}/processes/{processName}/instances/{processUuid}/start-preparation`,
      completePreparation: `${protocolPrefix}${
        this.$app.isLocalDev ? "" : this.$app.env ? this.$app.env + "." : ""
      }${apiServerValue}/${tenantPart}/cashiers/{cashierId}/processes/{processName}/instances/{processUuid}/complete-preparation`,
      delegateDelivery: `${protocolPrefix}${
        this.$app.isLocalDev ? "" : this.$app.env ? this.$app.env + "." : ""
      }${apiServerValue}/${tenantPart}/cashiers/{cashierId}/processes/{processName}/instances/{processUuid}/delegate-delivery`,
      startDelivery: `${protocolPrefix}${
        this.$app.isLocalDev ? "" : this.$app.env ? this.$app.env + "." : ""
      }${apiServerValue}/${tenantPart}/cashiers/{cashierId}/processes/{processName}/instances/{processUuid}/start-delivery`,
      completeDelivery: `${protocolPrefix}${
        this.$app.isLocalDev ? "" : this.$app.env ? this.$app.env + "." : ""
      }${apiServerValue}/${tenantPart}/cashiers/{cashierId}/processes/{processName}/instances/{processUuid}/complete-delivery`,
      transfer: `${protocolPrefix}${
        this.$app.isLocalDev ? "" : this.$app.env ? this.$app.env + "." : ""
      }${apiServerValue}/${tenantPart}/cashiers/{cashierId}/processes/{processName}/instances/{processUuid}/transfer`,
      confirmTransfer: `${protocolPrefix}${
        this.$app.isLocalDev ? "" : this.$app.env ? this.$app.env + "." : ""
      }${apiServerValue}/${tenantPart}/cashiers/{cashierId}/processes/{processName}/instances/{processUuid}/confirm-transfer`
    };
    Vue.appUrls = this.$app.urls;

    //security related
    this.$security = {};

    const h = "7t6rHZzWRLgZpqXa]Wddab2FeGRCTdGF";
    const theStore = this.$estore;

    function decrypt (value) {
      let result = undefined;
      try {
        result = Vue.CryptoJS.AES.decrypt(
          value,
          h + theStore.get("deviceId")
        ).toString(Vue.CryptoJS.enc.Utf8);
      } catch (err) {
        //ignore
      }
      return result;
    }

    function encrypt (value) {
      return Vue.CryptoJS.AES.encrypt(
        value,
        h + theStore.get("deviceId")
      ).toString();
    }

    async function getAccessToken (givenUsername, givenPassword) {
      const dateTime = moment();
      const client_id = "topupz";
      const client_secret = "d4a4dcbf-9814-47ce-8f87-caa99a84915c";
      const url = Vue.appUrls.oidcToken;
      const storedToken = Vue.security.access_token;
      if (storedToken) {
        //check if valid
        if (dateTime.isBefore(moment(Vue.security.access_token_validity))) {
          console.log("returning acces_token from localStorage");
          return storedToken;
        } else {
          //get new token if refresh_token still valid
          if (dateTime.isBefore(moment(Vue.security.refresh_token_validity))) {
            console.log("refresh token valid, fetching new token");
            try {
              const refreshResponse = await fetch(url, {
                method: "post",
                body: `grant_type=refresh_token&client_id=${client_id}&client_secret=${client_secret}&refresh_token=${Vue.security.refresh_token}`,
                headers: {
                  "Content-Type": "application/x-www-form-urlencoded"
                }
              });
              return saveTokens(await refreshResponse.json());
            } catch (err) {
              //ignore, access token will be fetched again
            }
          }
        }
      }
      console.log("Getting a new access_token");
      const username = encodeURIComponent(givenUsername ? givenUsername : theStore.get("deviceId"));
      const password = encodeURIComponent(givenPassword ? givenPassword :
        decrypt(theStore.get("devicePassword"))
      );
      let respPayload = {};
      try {
        const response = await fetch(url, {
          method: "post",
          body: `username=${username}&password=${password}&grant_type=password&client_id=${client_id}&client_secret=${client_secret}`,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          }
        });
        respPayload = await response.json();
      } catch (err) {
        console.log(`Error fetching acces_token at ${url}: ${err}`);
      }
      return saveTokens(respPayload);
    }

    function saveTokens (json) {
      const dateTime = moment();
      const accessToken = json["access_token"];
      Vue.security.access_token = accessToken;
      Vue.security.access_token_validity = dateTime
        .add(json["expires_in"] - 30, "seconds")
        .toISOString();
      Vue.security.refresh_token = json["refresh_token"];
      Vue.security.refresh_token_validity = dateTime
        .add(json["refresh_expires_in"] - 30, "seconds")
        .toISOString();
      return accessToken;
    }

    function isGivenTokenStillValid (token) {
      let result = false;
      if (token) {
        try {
          const theToken = token.startsWith("Bearer ")
            ? token.substring(7)
            : token;
          var decoded = jwt_decode(theToken);
          if (Date.now() < decoded.exp * 1000) {
            result = true;
          }
        } catch (error) {
          //do nothing
        }
      }
      return result;
    }

    this.$security.encrypt = encrypt;
    this.$security.decrypt = decrypt;
    this.$security.getAccessToken = getAccessToken;
    this.$security.saveTokens = saveTokens;
    this.$security.isGivenTokenStillValid = isGivenTokenStillValid;
    Vue.security = this.$security;
  }
});

Vue.directive("long-press", LongPress);
Vue.config.productionTip = false;

// Add a request interceptor
axios.interceptors.request.use(
  async config => {
    const token = await Vue.security.getAccessToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

Vue.http = Vue.prototype.$http = axios;
Vue.use(UUID);

Vue.use(i18n);

new Vue({
  i18n,
  router,
  vuetify,
  render: h => h(App)
}).$mount("#app");
