import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi"
  },
  theme: {
    dark: true,
    options: {
      customProperties: true
    },
    themes: {
      dark: {
        primary: "#FFC045",
        accent: "#FFD545",
        secondary: "#0a5471",
        success: "#4CAF50",
        info: "#99CA39",
        warning: "#FB8C00",
        error: "#FF5252"
      }
    }
  }
});
