<template>
  <v-dialog v-model="show" persistent max-width="300">
    <v-card max-width="320" class="pinCodeDialog">
      <v-list class="pa-0">
        <v-list-item>
          <v-list-item-content class="pa-0">
            <v-text-field
              class="centered-input headline pa-3 pl-6 pr-6"
              color="primary"
              id="pinCode"
              ref="pinCode"
              v-model="pinCode"
              :full-width="true"
              :append-icon="showPin ? 'mdi-eye-off' : 'mdi-eye'"
              :type="showPin ? 'text' : 'password'"
              @click:append="showPin = !showPin"
              outlined
              :hide-details="true"
              :autofocus="true"
              placeholder="pincode"
              :background-color="pinBackground"
            ></v-text-field>
            <div class="pinCodeKeyboard">
              <SimpleKeyboard
                @onChange="onChange"
                :inputName="pinCode"
                :inputValue="pinCode"
                :layout="numpadLayout"
                classname="pinkeyboard"
              />
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-card-actions class="d-flex justify-center mt-3 pb-1">
        <v-btn text @click="show = false">{{ $t("label.cancel") }}</v-btn>
        <v-btn
          :disabled="!pinCode"
          class="pin-code-confirm-button title"
          color="primary"
          text
          @click="check"
          >{{ $t("label.check") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SimpleKeyboard from "@/components/Utils/SimpleKeyboard.vue";
export default {
  name: "pin-dialog",
  components: {
    SimpleKeyboard
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      numpadLayout: {
        default: ["1 2 3", "4 5 6", "7 8 9", "{backspace} 0 {backspace}"]
      },
      showPin: false,
      pinCode: "",
      pinBackground: "unset"
    };
  },
  methods: {
    onChange(input) {
      this.pinCode = input;
      this.pinBackground = "unset";
    },
    check() {
      if (this.pinCode === this.$estore.get("tenant.deviceSettings.pinCode")) {
        this.pinBackground = "unset";
        this.show = false;
        this.$emit("pinCorrect");
      } else {
        this.pinBackground = "red";
      }
    }
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
        this.pinCode = "";
      }
    }
  }
};
</script>

<style>
.centered-input input {
  text-align: center !important;
}

.pinCodeKeyboard > .simple-keyboard {
  height: 290px !important;
  width: 215px !important;
  background-color: #424242 !important;
  padding: 0;
}

.pinCodeKeyboard > .simple-keyboard > .hg-row > .hg-standardBtn,
.pinCodeKeyboard > .simple-keyboard > .hg-row > .hg-button-ent,
.pinCodeKeyboard > .simple-keyboard > .hg-row > .hg-button-backspace {
  min-width: 65px !important;
  max-width: 65px !important;
  min-height: 65px !important;
  max-height: 65px !important;
  width: 65px !important;
  height: 65px !important;
}

.pinCodeKeyboard > .simple-keyboard > .hg-row > .hg-button {
  color: #424242;
  font-size: 1.7em;
}

.pinCodeKeyboard > .simple-keyboard .hg-row .hg-button:not(:last-child) {
  margin-right: 10px;
}

.pinCodeKeyboard > .simple-keyboard .hg-row:not(:last-child) {
  margin-bottom: 10px;
}
</style>
