<template>
  <v-dialog
    v-model="show"
    fullscreen
    persistent
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar color="grey darken-3">
        <v-toolbar-title class="headline">{{
          this.$t("label.settings")
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
            class="title"
            color="primary"
            text
            @click.stop="closeSettings"
            >{{ this.$t("label.close") }}</v-btn
          >
        </v-toolbar-items>
      </v-toolbar>

      <div class="px-4 pt-4">
        <v-text-field
          disabled
          outlined
          :label="$t('label.applicationVersion')"
          :value="applicationVersion"
        />
      </div>

      <Scanners
        :scanners="scanners"
        @favoriteScannersUpdated="$emit('favoriteScannersUpdated')"
      />
      <v-divider />
      <v-list subheader flat>
        <v-subheader>{{ $t("label.reset") }}</v-subheader>
        <v-list-item>
          <v-list-item-action>
            <div>
              <v-btn color="red" @click="confirmDialog = true">{{
                $t("label.factoryReset")
              }}</v-btn>
            </div>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card>
    <v-dialog max-width="800" v-model="confirmDialog" persistent>
      <v-card>
        <v-list class="pa-4">
          <v-list-item class="d-flex flex-column">
            <v-list-item-content
              class="d-flex justify-center display-1 primary--text"
            >
              {{ $t("message.warning") }}
            </v-list-item-content>
            <v-list-item-content class="d-flex justify-center title">
              {{ $t("message.resetExplanation") }}
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-card-actions class="d-flex justify-center mt-3 pb-5">
          <v-btn text @click="confirmDialog = false">{{
            $t("label.cancel")
          }}</v-btn>
          <v-btn color="red" @click="factoryReset">{{
            $t("label.confirm")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import Scanners from "@/components/Settings/Scanners";
export default {
  name: "settings-dialog",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    scanners: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      confirmDialog: false,
      applicationVersion: process.env.VUE_APP_VERSION,
    };
  },
  components: {
    Scanners,
  },
  methods: {
    closeSettings() {
      this.show = false;
    },
    factoryReset() {
      this.show = false;
      this.$emit("factoryReset");
    },
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
};
</script>

<style scoped></style>
