<template>
  <div class="d-flex flex-column pa-1 pl-0">
    <v-card elevation="0">
      <div
        class="d-flex justify-center align-center display-4 font-weight-medium pa-2 amountDisplay"
      >
        €
        {{
          (
            "" +
            (order ? order.totalAmount.toFixed(2) : parseInt("0").toFixed(2))
          ).replace(".", ",")
        }}
      </div>
      <v-container class="ma-0 pa-1 pl-2 pr-2 containerDisplay">
        <v-row no-gutters>
          <v-col
            class="d-flex flex-column justify-space-between ma-0 pr-1 contentDisplay"
            cols="6"
          >
            <v-list class="pa-0">
              <v-list-item>
                <v-list-item-content class="pa-0">
                  <div class="d-flex">
                    <v-text-field
                      class="centered-input title mb-1 mr-1"
                      :color="
                        amountNumber != 0 && amountTooShort
                          ? 'white'
                          : 'primary'
                      "
                      :background-color="
                        amountNumber != 0 && amountTooShort ? 'red' : 'unset'
                      "
                      id="amountReceived"
                      ref="amountReceived"
                      v-model="amountReceived"
                      prepend-inner-icon="mdi-currency-eur"
                      outlined
                      :hide-details="true"
                      :autofocus="true"
                      placeholder="IN"
                      :disabled="order.totalAmount < 0"
                    ></v-text-field>
                    <v-text-field
                      class="centered-input title mb-1 ml-1"
                      v-model="amountReturn"
                      prepend-inner-icon="mdi-currency-eur"
                      outlined
                      :hide-details="true"
                      placeholder="OUT"
                      :disabled="true"
                    ></v-text-field>
                  </div>
                  <div class="returnChangeKeyboard">
                    <SimpleKeyboard
                      @onChange="onChange"
                      :amount="true"
                      :inputName="amountReceived"
                      :inputValue="amountReceived"
                      :layout="amountLayout"
                      classname="changekeyboard"
                    />
                  </div>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-btn
              color="#424242"
              class="pa-8 title font-weight-medium"
              @click="returnToOrders"
            >
              {{ $t("label.selectProducts") }}
            </v-btn>
          </v-col>
          <v-col
            class="d-flex flex-column justify-space-between ma-0 pl-1 contentDisplay"
            cols="6"
          >
            <v-btn
              block
              color="primary darken-1"
              class="order-cash-button black--text headline font-weight-medium"
              @click="confirmCashPayment"
            >
              <div class="pl-6 pr-6 d-flex maxWidth justify-space-between">
                <div><v-icon left x-large>mdi-account-cash</v-icon></div>
                <div>Cash</div>
                <div>
                  <v-icon right x-large>mdi-just-to-fill-space</v-icon>
                </div>
              </div>
            </v-btn>
            <v-btn
              :disabled="!onlineOrdersEnabled || !qrCodePayment"
              block
              color="primary darken-1"
              class="order-online-button mt-3 black--text headline font-weight-medium"
              :class="{
                'disabled-order-button': !onlineOrdersEnabled || !qrCodePayment,
              }"
              @click="confirmQrPayment"
            >
              <div class="d-flex flex-column maxWidth pl-6 pr-6">
                <div class="d-flex justify-space-between">
                  <div><v-icon left x-large>mdi-qrcode-scan</v-icon></div>
                  <div>QR sticker</div>
                  <div>
                    <v-icon right x-large>mdi-just-to-fill-space</v-icon>
                  </div>
                </div>
                <div v-if="!onlineOrdersEnabled" class="subtitle-1">
                  ({{ $t("message.disabledByTenant") }})
                </div>
                <div v-else-if="!qrCodePayment" class="subtitle-1">
                  ({{ $t("message.noQrCodePayment") }})
                </div>
                <div v-else class="subtitle-1">
                  {{ qrCodePayment.replace(/([a-zA-Z0-9]{2})(?!$)/g, "$1 ") }}
                </div>
              </div>
            </v-btn>
            <v-btn
              :disabled="isNoScannerAvailable()"
              block
              color="primary darken-1"
              class="order-topupz-button mt-3 black--text headline font-weight-medium"
              :class="{ 'disabled-order-button': isNoScannerAvailable() }"
              @click="confirmTopupzPayment"
            >
              <div class="d-flex flex-column maxWidth pl-6 pr-6">
                <div class="d-flex justify-space-between">
                  <div><v-icon left x-large>mdi-credit-card-scan</v-icon></div>
                  <div>Topupz</div>
                  <div>
                    <v-icon right x-large>mdi-just-to-fill-space</v-icon>
                  </div>
                </div>
                <div v-if="!isOwningScanner()" class="subtitle-1">
                  ({{ $t("message.noScannersConfigured") }})
                </div>
                <div v-else-if="isNoScannerAvailable()" class="subtitle-1">
                  ({{ $t("message.scannerBusy") }})
                </div>
              </div>
            </v-btn>
            <v-btn
              :disabled="
                !onlinePaymentForOrdersEnabled ||
                order.totalAmount < minOrderAmountOnlinePayment ||
                isNoScannerAvailable()
              "
              block
              color="primary darken-1"
              class="order-bancontact-button mt-3 black--text headline font-weight-medium"
              :class="{
                'disabled-order-button':
                  !onlinePaymentForOrdersEnabled ||
                  order.totalAmount < minOrderAmountOnlinePayment ||
                  isNoScannerAvailable(),
              }"
              @click="confirmBancontactPayment"
            >
              <div class="d-flex flex-column maxWidth pl-6 pr-6">
                <div class="d-flex justify-space-between">
                  <div><v-icon left x-large>mdi-credit-card-scan</v-icon></div>
                  <div>Bancontact</div>
                  <div>
                    <v-icon right x-large>mdi-just-to-fill-space</v-icon>
                  </div>
                </div>
                <div v-if="!onlinePaymentForOrdersEnabled" class="subtitle-1">
                  ({{ $t("message.disabledByTenant") }})
                </div>
                <div v-else-if="!isOwningScanner()" class="subtitle-1">
                  ({{ $t("message.noScannersConfigured") }})
                </div>
                <div v-else-if="isNoScannerAvailable()" class="subtitle-1">
                  ({{ $t("message.scannerBusy") }})
                </div>
                <div
                  v-else-if="order.totalAmount < minOrderAmountOnlinePayment"
                  class="subtitle-1"
                >
                  (Min €
                  {{
                    minOrderAmountOnlinePayment.toFixed(2).replace(".", ",")
                  }})
                </div>
              </div>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <SelectScannerDialog
      :visible="scannerDialog"
      :availableScanners="availableScanners"
      @scannerSelected="confirmPaymentWithScannerSelection"
      @close="scannerDialog = false"
    ></SelectScannerDialog>
  </div>
</template>

<script>
import SelectScannerDialog from "@/components/Cashier/SelectScannerDialog.vue";
import SimpleKeyboard from "@/components/Utils/SimpleKeyboard.vue";

export default {
  name: "WaiterPayment",
  props: {
    order: {
      type: Object,
      required: true,
    },
    availableScanners: {
      type: Array,
      required: true,
    },
    onlinePaymentForOrdersEnabled: {
      type: Boolean,
      required: true,
    },
    minOrderAmountOnlinePayment: {
      type: Number,
      required: true,
    },
    onlineOrdersEnabled: {
      type: Boolean,
      required: true,
    },
    qrCodePayment: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      scannerDialog: false,
      scannerDialogPaymentHandler: null,
      amountLayout: {
        default: ["1 2 3", "4 5 6", "7 8 9", "{backspace} 0 ,"],
      },
      amountReceived: "",
      amountReturn: "",
      amountNumber: 0,
      amountTooShort: false,
    };
  },
  components: {
    SelectScannerDialog,
    SimpleKeyboard,
  },
  methods: {
    returnToOrders() {
      this.$emit("cancel");
    },
    confirmCashPayment() {
      this.$emit("cash");
    },
    confirmTopupzPayment() {
      this.confirmPaymentWithScanner((scannerId) => {
        this.$emit("topupz", scannerId);
      });
    },
    confirmBancontactPayment() {
      this.confirmPaymentWithScanner((scannerId) => {
        this.$emit("bancontact", scannerId);
      });
    },
    confirmQrPayment() {
      this.$emit("qr");
    },
    confirmPaymentWithScanner(paymentHandler) {
      if (this.availableScanners && this.availableScanners.length > 0) {
        if (
          this.availableScanners.length === 1 &&
          this.availableScanners[0].joinable
        ) {
          paymentHandler(this.availableScanners[0].id);
        } else {
          this.scannerDialogPaymentHandler = paymentHandler;
          this.scannerDialog = true;
        }
      }
    },
    confirmPaymentWithScannerSelection(scnnerId) {
      this.scannerDialogPaymentHandler(scnnerId);
    },
    isNoScannerAvailable() {
      return (
        !this.availableScanners ||
        this.availableScanners.length === 0 ||
        (this.availableScanners.length === 1 &&
          !this.availableScanners[0].joinable)
      );
    },
    isOwningScanner() {
      return this.availableScanners && this.availableScanners.length !== 0;
    },
    onChange(input) {
      this.amountReceived = input;
    },
  },
  watch: {
    amountReceived(newValue, oldValue) {
      // needed in case keyboard is used, touchscreen is handled by SimpleKeyboard
      if (newValue && this.order.totalAmount > 0) {
        const regex = /^(0|[1-9]\d*),?(\d{1,2})?$/g;
        const result = newValue.match(regex);
        if (result === null) {
          this.$nextTick(() => (this.amount = oldValue));
        } else {
          this.amountNumber = parseFloat(newValue.replace(",", "."));
          this.amountTooShort =
            this.order && this.amountNumber <= this.order.totalAmount;
          this.amountReturn =
            this.amountNumber > 0 &&
            this.order &&
            this.amountNumber > this.order.totalAmount
              ? (
                  "" + (this.amountNumber - this.order.totalAmount).toFixed(2)
                ).replace(".", ",")
              : "";
        }
      } else {
        this.amountNumber = 0;
        this.$nextTick(() => {
          this.amountReceived = "";
          this.amountReturn = "";
        });
      }
    },
  },
};
</script>

<style>
.amountDisplay {
  height: calc((100vh - var(--var-header-height) - 8px) / 3);
}

.containerDisplay {
  min-width: unset !important;
  max-width: unset !important;
}

.contentDisplay {
  height: calc(((100vh - var(--var-header-height) - 8px) / 3 * 2) - 8px);
}
.returnChangeKeyboard > .simple-keyboard {
  background-color: #212121 !important;
  padding: 0;
}

.returnChangeKeyboard > .simple-keyboard > .hg-row {
  height: 45px !important;
}

.returnChangeKeyboard > .simple-keyboard > .hg-row > .hg-standardBtn,
.returnChangeKeyboard > .simple-keyboard > .hg-row > .hg-button-ent,
.returnChangeKeyboard > .simple-keyboard > .hg-row > .hg-button-backspace {
  width: 30% !important;
  height: auto !important;
}

.returnChangeKeyboard > .simple-keyboard > .hg-row > .hg-button {
  color: #424242;
  font-size: 1.7em;
}

.returnChangeKeyboard > .simple-keyboard .hg-row .hg-button:not(:last-child) {
  margin-right: 5px;
}

.returnChangeKeyboard > .simple-keyboard .hg-row:not(:last-child) {
  margin-bottom: 5px;
}

.maxWidth {
  width: 100%;
}

.disabled-order-button {
  border: solid 2px;
}
</style>
