<template>
  <div :class="classname" style="margin: auto"></div>
</template>

<script>
import Keyboard from "simple-keyboard";
import "simple-keyboard/build/css/index.css";

export default {
  name: "SimpleKeyboard",
  props: {
    amount: {
      amount: Boolean,
      default: false
    },
    inputName: {
      type: String
    },
    inputValue: {
      type: String
    },
    classname: {
      type: String,
      default: "simple-keyboard"
    },
    layout: {
      type: Object,
      required: false
    }
  },
  data: () => ({
    keyboard: null,
    defaultLayout: {
      default: [
        "a z e r t y u i o p",
        "q s d f g h j k l m",
        "{shift} w x c v b n {backspace}",
        "{numbers} {space} {ent}"
      ],
      shift: [
        "A Z E R T Y U I O P",
        "Q S D F G H J K L M",
        "{shift} W X C V B N {backspace}",
        "{numbers} {space} {ent}"
      ],
      numbers: [
        "1 2 3 4 5 6 7 8 9 0",
        '- / : ; ( ) € & @ "',
        "{sym} . , ? ! ' {backspace}",
        "{abc} {space} {ent}"
      ],
      sym: [
        "[ ] { } # % ^ * + =",
        "_ | ~ < > $ £ ¥ • _",
        "{numbers} . , ? ! ' {backspace}",
        "{abc} {space} {ent}"
      ]
    }
  }),
  mounted() {
    this.keyboard = new Keyboard(`.${this.classname}`, {
      theme: "simple-keyboard hg-theme-default hg-layout-default",
      onChange: this.onChange,
      onKeyPress: this.onKeyPress,
      mergeDisplay: true,
      layoutName: "default",
      layout: this.layout ? this.layout : this.defaultLayout,
      display: {
        "{numbers}": "123",
        "{ent}": "⏎",
        "{escape}": "esc ⎋",
        "{tab}": "tab ⇥",
        "{backspace}": "⌫",
        "{capslock}": "caps lock ⇪",
        "{shift}": "⇧",
        "{controlleft}": "ctrl ⌃",
        "{controlright}": "ctrl ⌃",
        "{altleft}": "alt ⌥",
        "{altright}": "alt ⌥",
        "{metaleft}": "cmd ⌘",
        "{metaright}": "cmd ⌘",
        "{abc}": "ABC",
        "{sym}": "#+="
      }
    });

    let options = {
      inputName: this.inputName
    };
    if (this.amount) {
      options.inputPattern = /^(0|[1-9]\d*),?(\d{1,2})?$/;
    }
    this.keyboard.setOptions(options);
  },
  methods: {
    onChange(input) {
      this.$emit("onChange", input);
    },
    onKeyPress(button) {
      this.$emit("onKeyPress", button);

      /**
       * If you want to handle the shift and caps lock buttons
       */
      if (button === "{shift}" || button === "{lock}") this.handleShift();
      if (button === "{numbers}" || button === "{abc}" || button === "{sym}")
        this.handleOthers(button);
    },
    handleShift() {
      const currentLayout = this.keyboard.options.layoutName;
      const shiftToggle = currentLayout === "default" ? "shift" : "default";

      this.keyboard.setOptions({
        layoutName: shiftToggle,
        inputName: this.inputName
      });
    },
    handleOthers(button) {
      let layout = "";
      switch (button) {
        case "{numbers}":
          layout = "numbers";
          break;
        case "{abc}":
          layout = "default";
          break;
        case "{sym}":
          layout = "sym";
          break;
        default:
          layout = this.keyboard.options.layoutName;
      }

      this.keyboard.setOptions({
        layoutName: layout,
        inputName: this.inputName
      });
    }
  },
  watch: {
    inputName(value) {
      this.keyboard.options["inputName"] = value;
      this.keyboard.setInput(this.inputValue, value);
    },
    inputValue(value) {
      this.keyboard.setInput(value, this.inputName);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
