<template>
  <v-dialog v-model="show" persistent>
    <v-card>
      <v-card-title class="d-flex justify-center headline">{{
        $t("label.whichScanner")
      }}</v-card-title>

      <v-card-text>
        <v-container class="py-0">
          <v-row class="scanner-selection" align="center" justify="center">
            <v-col
              v-for="scanner in availableScanners"
              :key="scanner.id"
              class="scanner shrink"
            >
              <v-chip
                :disabled="!scanner.online || !scanner.joinable"
                large
                label
                @click="scannerSelected(scanner)"
              >
                {{ scanner.name }}
                <v-icon v-if="!scanner.online || !scanner.joinable" right>{{
                  !scanner.online ? "mdi-flash-circle" : "mdi-timer-sand"
                }}</v-icon>
              </v-chip>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey" text @click="show = false">
          {{ $t("label.cancel") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "selectScannerDialog",
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    availableScanners: {
      type: Array,
      required: true
    }
  },
  data() {
    return {};
  },
  methods: {
    scannerSelected(scanner) {
      this.show = false;
      this.$emit("scannerSelected", scanner.id);
    }
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      }
    }
  }
};
</script>

<style scoped></style>
