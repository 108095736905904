export default {
  label: {
    activate: "Activeer",
    addMember: "Lid toevoegen",
    all: "Alles",
    allTabsWithOrders: "Alles, ook bestellingen",
    amount: "Bedrag",
    applicationVersion: "Applicatie versie",
    calculateReturn: "Bereken Teruggave",
    cancel: "Annuleren",
    cashier: "Kassa",
    cashierSettings: "Kassa Instellingen",
    cashReceived: "Cash Ontvangen",
    cashToReturn: "Cash Teruggave",
    check: "Controleer",
    close: "Sluiten",
    closeApp: "Afsluiten",
    configuration: "Instellingen",
    confirm: "Ja, ik ben zeker",
    confirm_pickup: "Afhaling OK",
    confirm_service: "Bediening OK",
    correction: "Correctie",
    currentOrders: "Lopende bestellingen",
    delegatePreparation: "Klaarmaken delegeren",
    delegateServiceDelivery: "Te bedienen",
    delivered: "Afgeleverd",
    deviceName: "Toestelnaam",
    disabledProduct: "Gedeactiveerd Product",
    emailAddress: "Email address",
    factoryReset: "Opnieuw Instellen",
    forceQuit: "Geforceerd Afsluiten",
    freePlan: "Gratis Abonnement",
    initiator: "Initiatiefnemer",
    locale: "Taal",
    login: "Inloggen",
    name: "Naam",
    newWaiter: "Nieuwe ober",
    noFavorite: "Geen voorkeur",
    noTabs: "Geen",
    orderedOnline: "Online besteld",
    orderReference: "Referentie",
    orderShortId: "Nummer",
    otherCashier: "Andere kassa",
    password: "Wachtwoord",
    pay: "Betaal",
    pickup_ongoing: "Afhaling bezig",
    pincode: "Pincode",
    preparation_ongoing: "Klaarmaken bezig",
    preparedAndDelivered: "Klaar en afgeleverd",
    product: "Product",
    products: "Producten",
    proposeAll: "Stel alle vrije kaartlezers voor bij Topupz acties",
    proposeFavorites: "Stel enkel deze kaartlezers voor indien beschikbaar",
    refund: "Terug",
    register: "Registreer",
    requestPickup: "Af te halen",
    reset: "Gevarenzone",
    save: "Bewaar",
    scanners: "Kaartlezers",
    search: "Zoeken",
    selectProducts: "Selectie Producten",
    serial: "Serienummer",
    service_ongoing: "Bediening bezig",
    settings: "Instellingen",
    status: "Status",
    switchDeliveryTo_pickup: "Verander naar 'af te halen'",
    switchDeliveryTo_service: "Verander naar 'te bedienen'",
    tabsOngoing: "Tabs met verwerking of feedback",
    tabsWithOrders: "Tabs met bestellingen",
    tenantId: "Club ID",
    tenantShortName: "Verkorte clubnaam",
    timestamp: 'Tijdstip',
    to_enter: "In te geven",
    to_prepare: "Klaar te maken",
    to_serve: "Te bedienen",
    topup: "Topup",
    topupAmount: "Kaart opladen: €{amount}",
    updateLater: "Later",
    updateNow: "Nu bijwerken",
    username: "Gebruikersnaam",
    waiting_for_card_scan: "Kaart scannen",
    waiting_for_online_payment: "Online betaling",
    waiting_for_qr_payment: "QR betaling",
    waiting_for_pickup: "Af te halen",
    whichScanner: "Selecteer de kaartlezer waarmee afgerekend moet worden."
  },
  message: {
    activityDuringClose: "O-oh! Er was nog activiteit bij het afsluiten",
    allTabsUsed: "Alle tabs zijn in gebruik!",
    autoRetry: "We blijven proberen...",
    canceled_by_cashier: "Geannuleerd door ober",
    canceled_by_user: "Geannuleerd door gebruiker",
    cancelFailed: "Annulatie mislukt, probeer opnieuw",
    card_scan_timeout: "Tijd verstreken",
    changeSubscription: "U kan het plan van uw abonnement aanpassen in Topupz voor clubs.",
    chooseRestoreMethod: "Welke tabs dienen hersteld te worden?",
    confirmClosePendingProcesses: "Er zijn nog verwerkingen bezig of feedback dat nog niet bevestigd is!",
    confirmTransfer: "Bent u zeker dat u de bestelling hier wil overnemen? Deze bestelling zal op het bestaande tabblad van de huidige ober verdwijnen.",
    connectionIssue: "Fout met verbinding",
    disabledByTenant: "Optie niet actief",
    disabled_card: "Kaart niet Actief",
    disabledProduct: "Het geselecteerde product is gedeactiveerd en kan dus niet verkocht worden. Gelieve op onderstaande knop te klikken om het opnieuw te activeren.",
    errorTryAgain: "Fout! Gelieve opnieuw te proberen",
    insufficient_funds: "Saldo Ontoereikend",
    lastTimestampBeforeClose: "Laatst gestarte verwerking was op {timestamp}.",
    limitedOfflineActions: "De kassa is offline, enkel beperkte acties mogelijk",
    membershipRequestAlreadySent: "Een lidmaatschapsverzoek is al verzonden",
    membershipRequestError: "Er is een fout opgetreden bij het verzenden van het lidmaatschapsverzoek",
    membershipRequestForMember: "Iemand met hetzelfde e-mailadres is al een lid",
    membershipRequestSent: "Een lidmaatschapsverzoek is verzonden",
    noCurrentOrders: "Geen lopende bestellingen",
    noProducts: "Wacht op Producten",
    noProductsInCategory: "Geen producten in deze categorie. Deze kunnen via Topupz voor clubs worden ingesteld.",
    noQrCodePayment: "Geen gekoppelde QR",
    noScanners: "Er zijn momenteel geen scanners. Als er wel zouden moeten zijn, gelieve de verbinding te controleren",
    noScannersConfigured: "Geen scanners",
    offlineOrderCancel: "De bestelling zal geannuleerd worden als er terug verbinding is",
    offlineOrderProcessing: "Bestelling offline bewaard voor latere verwerking...",
    offlineTopupCancel: "De topup zal geannuleerd worden als er terug verbinding is",
    online_payment_canceled: "De online betaling is geannuleerd",
    online_payment_failed: "De online betaling is gefaald",
    online_payment_timeout: "De online betaling is verlopen",
    onlyOffline: "Gelieve enkel CASH betalingen uit te voeren (offline mode)",
    orderIsBeingProcessed: "Bestelling is reeds in behandeling",
    orderIsNotTransferable: "De bestelling kan niet overgenomen worden omdat de desbetreffende kassa offline is",
    orderOk: "Bestelling is verwerkt en betaling OK",
    performOfflineCash: "Vraag de klant om cash te betalen",
    pickup_ongoing: "Gelieve het nummer of de referentie te controleren alvorens de bestelling aan de klant te overhandigen",
    preparation_ongoing: "Gelieve de bestelling klaar te maken",
    processedByOtherCashier: "De bestelling is niet meer te verwerken op deze kassa",
    productUpdate: "Joepie, een product notificatie! Wil je ze onmiddellijk bijwerken?",
    registration1: "Gelieve de kassa te registreren door éénmalig als beheerder in te loggen.",
    registration2: "Nadien kan u de naam van de kassa instellen, andere instellingen zijn beschikbaar in Topupz voor clubs.",
    registration3: "De standaard pincode is '1111'.",
    remainingFreeOrders: "Alle bestellingen in het gratis abonnement zijn uitgevoerd voor vandaag en zullen morgen terug beschikbaar zijn. | Er is vandaag nog 1 bestelling te plaatsen in het gratis abonnement. | Er zijn vandaag nog {remainingFreeOrders} bestellingen te plaatsen in het gratis abonnement.",
    removeWaiter: "Ober verwijderen",
    required: "Verplicht in te vullen",
    resetExplanation: "Alle instellingen zullen verloren gaan, alsook eventuele synchronisatie en feedback op lopende transacties!",
    restoreNbrNotStarted: "Er waren {nbrNotStarted} tabs met ingegeven bestellingen.",
    restoreNbrNotStartedOne: "Er was 1 tab met een ingegeven bestelling.",
    restoreNbrOngoing: "{nbrOngoing} verwerkingen of onbevestigde feedbacks.",
    restoreNbrOngoingOne: "1 verwerking of onbevestigde feedback.",
    restoreTopupBusy: "Topup van kaart was bezig",
    retry: "Gelieve opnieuw te proberen",
    retryOrReturnCash: "Gelieve opnieuw te proberen of het geld terug aan de klant te geven",
    returnCash: "Gelieve het geld terug aan de klant te geven",
    scanner_busy: "Kaartlezer is Bezig",
    scannerBusy: "Scanner bezet",
    scanner_gone: "Kaartlezer niet Beschikbaar",
    selectWaiterToContinueOrder: "Selecteer de ober (tab) om op deze kassa de bestelling verder af te handelen",
    service_ongoing: "Gelieve de bestelling naar de klant te brengen",
    to_prepare: "Gelieve de bestelling klaar te maken of dit te delegeren",
    topupOk: "Kaart opladen is verwerkt en betaling OK",
    unknown: "Ongekende Fout",
    unknown_card: "Ongekende Kaart",
    unremovableWaiter: {
      error: "Ober kan niet verwijderd worden",
      reason_pendingOrders: "Er zijn nog bestellingen in verwerking of onbevestigde feedback"
    },
    waiting_for_card_scan: "Vraag klant om kaart te scannen",
    waiting_for_online_payment: "Wachten op online betaling",
    waiting_for_qr_payment: "Wachten op QR betaling",
    waitingForMatchingCashierId: "Gelieve even te wachten totdat de bestelling naar deze kassa getransfereerd is",
    warning: "Opgelet!"
  }
}
