import { render, staticRenderFns } from "./AddMemberDialog.vue?vue&type=template&id=8e35b2b4&"
import script from "./AddMemberDialog.vue?vue&type=script&lang=js&"
export * from "./AddMemberDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBottomSheet } from 'vuetify/lib/components/VBottomSheet';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBottomSheet,VBtn,VSheet,VTextField})
