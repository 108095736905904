<template>
  <div class="cashier">
    <v-app-bar
      height="30"
      max-height="var(--var-header-height)"
      extension-height="50"
    >
      <div class="align-self-center">
        <img class="pt-1" :src="require('@/assets/logo.png')" height="30" />
      </div>
      <div
        v-if="!$app.isProd"
        class="d-flex pa-3 title align-self-center text-uppercase red--text"
      >
        <v-icon color="red">mdi-test-tube</v-icon>
        <div>{{ $app.env }}</div>
        <v-icon color="red">mdi-test-tube</v-icon>
      </div>
      <v-spacer></v-spacer>
      <div class="align-self-center text-uppercase cashierTitle">
        {{ tenantShortName }} - {{ cashierNickname }}
      </div>
      <div class="ml-2" v-if="isFreePlan">
        ({{ this.$t("label.freePlan") }})
      </div>
      <v-badge
        :content="processesToSync.length"
        :value="processesToSync.length"
        right
        color="'blue blue--text"
        offset-y="27"
        offset-x="17"
      >
        <v-btn icon class="ml-1">
          <v-icon
            :color="
              connected ? (processesToSync.length > 0 ? 'blue' : 'grey') : 'red'
            "
            >{{
              syncing
                ? "mdi-cloud-sync"
                : connected
                ? "mdi-cloud-check"
                : "mdi-cloud-alert"
            }}</v-icon
          >
        </v-btn>
      </v-badge>
      <v-spacer></v-spacer>
      <div>
        <v-btn icon class="topup-button mr-1" @click="openTopupAfterCorrectPin">
          <v-icon :color="this.correctionMode ? 'red' : 'primary'">{{
            this.correctionMode
              ? "mdi-credit-card-minus"
              : "mdi-credit-card-plus"
          }}</v-icon>
        </v-btn>
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn class="menu-button" icon v-on="on">
              <v-icon color="primary">mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
                class="add-member-menu-item pa-2 pl-4 pr-4"
                @click="openAddMemberAfterCorrectPin"
            >
              <v-list-item-icon>
                <v-icon class="grey--text text--lighten-2">mdi-account-plus</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="grey--text text--lighten-2 title">
                {{ $t("label.addMember") }}
              </v-list-item-title>
            </v-list-item>
            <v-divider />
            <v-list-item
              class="settings-menu-item pa-2 pl-4 pr-4"
              @click="openSettingsAfterCorrectPin"
            >
              <v-list-item-icon>
                <v-icon class="grey--text text--lighten-2">mdi-cog</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="grey--text text--lighten-2 title">
                {{ $t("label.settings") }}
              </v-list-item-title>
            </v-list-item>
            <v-divider />
            <v-list-item
              class="correction-menu-item pa-2 pl-4 pr-4"
              :class="{ red: this.correctionMode }"
              @click="correctionModeAfterCorrectPin"
            >
              <v-list-item-icon>
                <v-icon class="grey--text text--lighten-2">{{
                  `mdi-toggle-switch${this.correctionMode ? "" : "-off"}`
                }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="grey--text text--lighten-2 title">
                {{ $t("label.correction") }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <template v-slot:extension>
        <div class="waiters d-flex align-center">
          <v-badge
            :content="ordersToPrepareCount"
            :value="ordersToPrepareCount > 0"
            offset-y="10"
            offset-x="20"
            color="red"
            left
            class="font-weight-bold"
            bordered
          >
            <v-badge
              :content="ordersToServeCount"
              :value="ordersToServeCount > 0"
              offset-y="10"
              offset-x="25"
              color="orange darken-2"
              class="font-weight-bold"
              bordered
            >
              <v-btn
                color="primary"
                class="mr-2"
                :disabled="correctionMode"
                @click="currentOrdersDialog = true"
              >
                <v-icon color="black">mdi-clipboard-list-outline</v-icon>
              </v-btn>
            </v-badge>
          </v-badge>
          <WaiterSelection
            ref="waiterSelection"
            :waitersInfo="waitersInfo"
            :waitersFeedback="waitersFeedback"
            :unremovableWaiters="unremovableWaiters"
            :initialWaiterId="currentWaiterId"
            :disabled="correctionMode"
            @selectedWaiterId="selectWaiter"
            @removedWaiterId="removeWaiter"
            @waiterMenu="showOverlay"
          ></WaiterSelection>
        </div>
      </template>
    </v-app-bar>

    <v-main>
      <v-row no-gutters class="fill-height">
        <v-col cols="4">
          <WaiterOrders
            :nbrOfTabs="nbrOfWaiterTabs"
            :currentTab="currentOrderTab"
            :orders="currentWaiterOrders"
            :correctionMode="correctionMode"
            :isFreePlan="isFreePlan"
            :remainingFreeOrders="remainingFreeOrders"
            @orderTabSelected="orderTabSelected"
            @selectOrderItem="selectOrderItem"
            @unselectOrderItem="unselectOrderItem"
            @minusOrderItem="minusOrderItem"
            @plusOrderItem="plusOrderItem"
            @paymentRequested="setPaymentRequested"
            @deleteOrder="deleteOrder"
          ></WaiterOrders>
        </v-col>
        <v-col cols="8">
          <Products
            v-if="currentOrder.paymentRequested === false"
            :products="products"
            :categories="categories"
            :tabNr="currentCategoryTabNr"
            :productViewingMode="getWaiterEntry().currentProductViewingMode"
            :orderId="currentOrder.processUuid"
            @productSelected="productSelected"
            @categorySelected="categorySelected"
            @productViewingModeChanged="productViewingModeChanged"
          ></Products>
          <WaiterPayment
            v-if="
              currentOrder.paymentRequested === true &&
              currentOrder.paymentOngoing === false
            "
            :order="currentOrder"
            :availableScanners="availableScanners"
            :onlinePaymentForOrdersEnabled="onlinePaymentForOrdersEnabled"
            :minOrderAmountOnlinePayment="minOrderAmountOnlinePayment"
            :onlineOrdersEnabled="onlineOrdersEnabled"
            :qrCodePayment="qrCodePayment"
            @cash="requestCashPayment"
            @topupz="requestTopupzPayment"
            @bancontact="requestBancontactPayment"
            @qr="requestQrPayment"
            @cancel="cancelPaymentRequested"
          ></WaiterPayment>
          <div
            class="processPanelHeight"
            v-if="currentOrder.paymentOngoing === true"
          >
            <WaiterProcessStatus
              processName="order"
              :process="currentOrder.process"
              :showRemainingFreeOrders="isFreePlan"
              :remainingFreeOrders="remainingFreeOrders"
              :connected="connected"
              :correctionMode="correctionMode"
              @cancelPayment="cancelOrderProcess"
              @retry="resetProcesStatus"
              @done="clearOrder"
              @delegatePreparation="
                orderAction('delegatePreparation', currentOrder, undefined)
              "
              @preparedAndRequestPickup="
                completePreparation(false, false, 'PICKUP')
              "
              @preparedAndRequestService="
                completePreparation(false, false, 'SERVICE')
              "
              @preparedAndDelivered="completePreparation(true, true)"
              @delegateDelivery="
                orderAction('delegateDelivery', currentOrder, {})
              "
              @switchDeliveryType="
                (deliveryType) =>
                  orderAction('delegateDelivery', currentOrder, {
                    deliveryType,
                  })
              "
              @delivered="
                orderAction('completeDelivery', currentOrder, undefined, true)
              "
            ></WaiterProcessStatus>
          </div>
        </v-col>
      </v-row>
    </v-main>
    <v-bottom-sheet v-model="productUpdateReceived" persistent>
      <v-sheet class="text-center">
        <v-btn
          class="mt-6 mr-2"
          color="grey darken-1"
          @click="updateProductsLater"
          >{{ this.$t("label.updateLater") }}
        </v-btn>

        <v-btn
          class="mt-6 ml-2 black--text"
          color="primary"
          @click="updateProductsNow"
          >{{ $t("label.updateNow") }}
        </v-btn>
        <div class="pt-6 pb-6">
          {{ $t("message.productUpdate") }}
        </div>
      </v-sheet>
    </v-bottom-sheet>
    <v-bottom-sheet v-model="generalError.show" persistent>
      <v-sheet class="text-center">
        <v-btn
          class="mt-6 ml-2 black--text"
          color="primary"
          @click="generalError.show = false"
          >OK
        </v-btn>
        <div class="pt-6 pb-6">
          {{ generalError.message1 }}
        </div>
        <div>
          {{ generalError.message2 }}
        </div>
      </v-sheet>
    </v-bottom-sheet>
    <v-dialog max-width="800" v-model="closeDialog" persistent>
      <v-card>
        <v-list class="pa-4">
          <v-list-item>
            <v-list-item-content class="d-flex justify-center title">
              {{ $t("message.confirmClosePendingProcesses") }}
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-card-actions class="d-flex justify-center mt-3 pb-5">
          <v-btn text @click="closeDialog = false">{{
            $t("label.cancel")
          }}</v-btn>
          <v-btn class="force-quit-button" color="red" @click="sendCloseApp">{{
            $t("label.forceQuit")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      max-width="800"
      v-model="noRemainingFreeOrdersDialog"
      persistent
      class="pa-4"
    >
      <v-card class="pa-4">
        <div class="d-flex flex-column text-center">
          <div class="display-1 primary--text mt-3 mb-3">
            {{ $tc("message.remainingFreeOrders", 0) }}
            <br />
            {{ $t("message.changeSubscription") }}
          </div>
          <div>
            <v-btn class="ma-2" @click="closeApp"
              >{{ $t("label.closeApp") }}
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <PinDialog
      :visible="pinDialog"
      @pinCorrect="callActionAfterCorrectPin"
      @close="pinDialog = false"
    />
    <SettingsDialog
      :visible="settingsDialog"
      :scanners="scanners"
      @factoryReset="factoryReset"
      @favoriteScannersUpdated="favoriteScannersUpdated"
      @close="settingsDialog = false"
    />
    <AddMemberDialog
        :visible="addMemberDialog"
        :connected="connected"
        @close="addMemberDialog = false"
    />
    <TopupDialog
      :visible="topupDialog"
      :topup="topupData"
      :availableScanners="availableScanners"
      :correctionMode="correctionMode"
      :connected="connected"
      @topupCard="prepareAndSendTopup"
      @cancelPayment="cancelTopupProcess"
      @retry="topupDone"
      @done="topupDone(true)"
      @close="topupDialog = false"
    />
    <CurrentOrdersDialog
      :visible="currentOrdersDialog"
      :connected="connected"
      :log="log"
      :cashierBook="cashierBook"
      @close="currentOrdersDialog = false"
      @orderToWaiter="orderToWaiter"
      @orderToNewWaiter="orderToNewWaiter"
    />
    <DeviceCommands
      @isConnected="setConnected"
      @scannersUpdate="scannersUpdate"
      @deviceUpdate="deviceUpdate"
      @settingsUpdate="settingsUpdate"
      @productsUpdate="productsUpdate"
      @productCategoriesUpdate="productCategoriesUpdate"
      @orderTaskUpdate="fetchUnassignedTasksCount"
      @orderTransferRequestUpdate="startConfirmOrderTransfer"
      @factoryReset="factoryReset"
    />
    <v-overlay z-index="0" :value="overlay" />
  </div>
</template>

<script>
// @ is an alias to /src
import moment from "moment-timezone";
import WaiterSelection from "@/components/Cashier/WaiterSelection.vue";
import WaiterOrders from "@/components/Cashier/WaiterOrders.vue";
import Products from "@/components/Cashier/Products.vue";
import WaiterPayment from "@/components/Cashier/WaiterPayment.vue";
import WaiterProcessStatus from "@/components/Cashier/WaiterProcessStatus.vue";
import ClickOutside from "vue-click-outside";
import PinDialog from "@/components/Cashier/PinDialog";
import SettingsDialog from "@/components/Cashier/SettingsDialog";
import TopupDialog from "@/components/Cashier/TopupDialog";
import CurrentOrdersDialog from "@/components/Cashier/CurrentOrdersDialog";
import DeviceCommands from "@/components/Cashier/DeviceCommands";
import Vue from "vue";
import AddMemberDialog from "@/components/Cashier/AddMemberDialog.vue";

export default {
  name: "cashier",
  components: {
    AddMemberDialog,
    WaiterSelection,
    WaiterOrders,
    Products,
    WaiterPayment,
    WaiterProcessStatus,
    PinDialog,
    SettingsDialog,
    TopupDialog,
    CurrentOrdersDialog,
    DeviceCommands,
  },
  data() {
    return {
      ordersToPrepareCount: 0,
      ordersToServeCount: 0,
      fetchUnassignedTasksCountCancel: undefined,
      fetchUnassignedTasksCountTimeoutId: undefined,
      correctionMode: this.$estore.get("tenant.correction.mode"),
      orderTabBeforeCorrection: this.$estore.get(
        "tenant.correction.previousOrderTab"
      ),
      overlay: false,
      recovered: false,
      connected: false,
      restore: {},
      pollingProcesses: [],
      processesToSync: [],
      syncingProcesses: [],
      syncHandle: undefined,
      generalError: {
        //TODO: make specfic for cancelFailed?
        show: false,
        message1: "",
        message2: "",
      },
      closeDialog: false,
      settingsDialog: false,
      addMemberDialog: false,
      topupDialog: false,
      pinDialog: false,
      currentOrdersDialog: false,
      functionAfterCorrectPin: undefined,

      tenantShortName: "",
      cashierNickname: "",
      cashierModel: "",
      products: [],
      categories: [],
      currentCategoryTabNr: 0,
      cashierBook: [],
      currentWaiterId: 0,
      currentWaiterOrders: [],
      waitersInfo: [],
      waitersFeedback: [],
      unremovableWaiters: [],
      nbrOfWaiterTabs: 3,
      currentOrderTab: this.correctionMode ? -1 : 1,
      currentOrder: {},
      paymentRequested: false,
      availableScanners: [],
      loadedProductCategories: [],
      loadedProducts: [],
      productUpdateReceived: false,
      productUpdateTimer: undefined,
      topupData: {
        process: {},
      },
      productsRetryHandler: undefined,
      productCategoriesRetryHandler: undefined,
      scanners: this.$estore.get("tenant.scanners", []),
      onlinePaymentForOrdersEnabled: false,
      onlinePaymentForTopupsEnabled: false,
      minOrderAmountOnlinePayment: undefined,
      minTopupAmountOnlinePayment: undefined,
      onlineOrdersEnabled: false,
      qrCodePayment: undefined,
      isFreePlan: true,
      remainingFreeOrders: 0,
      noRemainingFreeOrdersDialog: false,
      syncingCashierBookOrders: [],
      cashierBookOrdersToSync: [],
      verifyCashierBookHandle: undefined,
    };
  },
  methods: {
    showOverlay(value) {
      this.overlay = value;
    },
    resetTopupData() {
      this.topupData = {
        processUuid: undefined,
        waiterId: 0,
        tabId: 1,
        paymentOngoing: false,
        amount: 0,
        timestamp: undefined,
        process: {
          success: false,
          loading: true,
          started: false,
          message1: "",
          message2: "",
        },
      };
    },
    topupDone(shouldClose) {
      this.$estore.delete("tenant.ongoingTopup");
      if (shouldClose) {
        this.topupDialog = false;
        if (this.correctionMode) {
          this.orderTabSelected(this.orderTabBeforeCorrection);
          this.$estore.set("tenant.correction.mode", false);
          this.correctionMode = false;
        }
        setTimeout(() => this.resetTopupData(), 2000); //to avoid changing to numpad during close of bottom sheet
      } else {
        this.resetTopupData();
      }
    },
    setConnected(status) {
      this.connected = status;
      console.log(status ? "connected" : "disconnected");
      if (!this.recovered) {
        this.recovered = true;
        this.recoverCashierBook();
      }
      if (status) {
        this.syncToBackend();
        this.verifyCashierBookChange();
      } else {
        this.availableScanners = [];
      }
    },
    favoriteScannersUpdated() {
      this.scannersUpdate(this.$estore.get("tenant.scanners", []));
    },
    scannersUpdate(scanners) {
      this.scanners = scanners ? scanners : [];
      this.$estore.set("tenant.scanners", scanners ? scanners : []);
      if (scanners && scanners.length > 0) {
        const favs = this.$estore.get("tenant.favoriteScanners", []);
        this.availableScanners = scanners
          .filter((s) => {
            let result = false;
            if (favs && favs.length > 0) {
              result = favs.findIndex((f) => f === s.id) >= 0;
            } else {
              result = true;
            }
            return result;
          })
          .map((m) => {
            return {
              id: m.id,
              name: m.name ? m.name : m.id,
              online: m.online,
              joinable: m.joinable,
            };
          })
          .sort((s1, s2) => {
            const s1Sort = (s1.name || s1.id).toLowerCase();
            const s2Sort = (s2.name || s2.id).toLowerCase();
            if (s1Sort > s2Sort) return 1;
            if (s1Sort < s2Sort) return -1;
            if (s1Sort === s2Sort) return 0;
          });
        console.log("SCANNERS", this.availableScanners);
      } else {
        this.availableScanners = [];
      }
    },
    deviceUpdate(device) {
      this.$estore.set("tenant.cashierNickname", device.name);
      this.$estore.set("tenant.cashierModel", device.model);
    },
    settingsUpdate(settings) {
      settings.forEach((s) => {
        switch (s.key) {
          case "PIN_CODE":
            this.$estore.set("tenant.deviceSettings.pinCode", s.value);
            break;
          case "TENANT_SHORT_NAME":
            this.$estore.set("tenant.deviceSettings.tenantShortName", s.value);
            break;
          case "LOCALE":
            this.$estore.set("tenant.deviceSettings.locale", s.value);
            if (
              s.value &&
              s.value.length > 1 &&
              ["nl", "fr", "en"].includes(s.value.substr(0, 2))
            ) {
              this.$root.$i18n.locale = s.value.substr(0, 2);
            }
            break;
          case "ONLINE_PAYMENT_ALLOWED_FOR_ORDERS":
            this.onlinePaymentForOrdersEnabled =
              s.value === "true" || s.value === "TRUE";
            this.$estore.set(
              "tenant.deviceSettings.onlinePaymentAllowedForOrders",
              this.onlinePaymentForOrdersEnabled
            );
            break;
          case "ONLINE_PAYMENT_ALLOWED_FOR_TOPUPS":
            this.onlinePaymentForTopupsEnabled =
              s.value === "true" || s.value === "TRUE";
            this.$estore.set(
              "tenant.deviceSettings.onlinePaymentAllowedForTopups",
              this.onlinePaymentForTopupsEnabled
            );
            break;
          case "ONLINE_PAYMENT_MINIMUM_ORDER_AMOUNT":
            this.minOrderAmountOnlinePayment = +s.value || 0.02;
            this.$estore.set(
              "tenant.deviceSettings.onlinePaymentMinimumOrderAmount",
              this.minOrderAmountOnlinePayment
            );
            break;
          case "ONLINE_PAYMENT_MINIMUM_TOPUP_AMOUNT":
            this.minTopupAmountOnlinePayment = +s.value || 0.02;
            this.$estore.set(
              "tenant.deviceSettings.onlinePaymentMinimumTopupAmount",
              this.minTopupAmountOnlinePayment
            );
            break;
          case "SUBSCRIPTION_PLAN":
            this.isFreePlan = s.value === "FREE";
            break;
          case "REMAINING_FREE_ORDERS":
            this.remainingFreeOrders = parseInt(s.value);
            break;
          case "ONLINE_ORDERS_ENABLED":
            this.onlineOrdersEnabled = s.value === "true" || s.value === "TRUE";
            this.$estore.set(
              "tenant.deviceSettings.onlineOrdersEnabled",
              this.onlineOrdersEnabled
            );
            break;
          case "PAYMENT_QR_CODE_ID":
            if (s.value) {
              this.qrCodePayment = s.value;
              this.$estore.set(
                  "tenant.deviceSettings.qrCodePayment",
                  this.qrCodePayment
              );
            } else {
              this.qrCodePayment = undefined;
              this.$estore.delete("tenant.deviceSettings.qrCodePayment");
            }
            break;
          default:
            console.log(
              `TODO: warn about unwatched setting? '${s.key}' - '${s.value}'`
            );
        }
      });

      this.noRemainingFreeOrdersDialog =
        this.isFreePlan &&
        this.remainingFreeOrders === 0 &&
        this.currentOrder.totalItems === 0;
    },
    productsUpdate() {
      if (this.cashierBook.filter((e) => e.hasOrderItems()).length > 0) {
        this.productUpdateReceived = true;
      } else {
        this.updateProductsNow();
      }
    },
    productCategoriesUpdate() {
      if (this.cashierBook.filter((e) => e.hasOrderItems()).length > 0) {
        this.productUpdateReceived = true;
      } else {
        this.updateProductsNow();
      }
    },
    fetchCurrentOrders() {
      // TODO: fetch running orders
      // use boolean if fetching ongoing, cancel on next call, on error setTimeout and try again
      this.log("TODO: fetch currentOrders");
    },
    fetchUnassignedTasksCount() {
      if (this.fetchUnassignedTasksCountCancel) {
        this.log(`About to cancel request for fetchUnassignedTasksCount`);
        try {
          this.fetchUnassignedTasksCountCancel.cancel();
        } catch (e) {
          this.log(
            "Cancel issue for fetchUnassignedTasksCount, ignoring... " + e
          );
        }
        this.fetchUnassignedTasksCountCancel = undefined;
      }
      if (this.fetchUnassignedTasksCountTimeoutId) {
        this.log(`About to cancel timeout for fetchUnassignedTasksCount`);
        clearTimeout(this.fetchUnassignedTasksCountTimeoutId);
        this.fetchUnassignedTasksCountTimeoutId = undefined;
      }

      this.log(`About to send request for fetchUnassignedTasksCount`);

      let url = this.$app.urls.process.unassignedTasksCount
        .replace("{tenantId}", this.$estore.get("tenant.tenantId"))
        .replace("{processName}", "order");

      this.fetchUnassignedTasksCountCancel = Vue.prototype.$http.CancelToken.source();

      this.$http({
        url: url,
        method: "post",
        cancelToken: this.fetchUnassignedTasksCountCancel.token,
      }).then(
        (response) => {
          this.log(
            `Response received for fetchUnassignedTasksCount: ${response.status} - ${response.statusText}`
          );
          this.fetchUnassignedTasksCountCancel = undefined;
          this.ordersToPrepareCount = response.data.toPrepare;
          this.ordersToServeCount = response.data.toServe;
        },
        (error) => {
          this.log(
            `Error response for fetchUnassignedTasksCount, retrying in 5s: ${error}`
          );
          this.fetchUnassignedTasksCountTimeoutId = setTimeout(
            () => this.fetchUnassignedTasksCount(),
            5000
          );
        }
      );
    },
    startConfirmOrderTransfer(payload) {
      const order = this.getOrderById(payload.instanceId);
      if (order) {
        order.process.confirmTransferRetryCount = 0;
        this.confirmOrderTransfer(order, payload.destinationDeviceId);
      } else {
        this.log(
          `!!! Order '${payload.instanceId}' not found to transfer to device '${payload.destinationDeviceId}'`
        );
        // TODO: other action/feedback required?
      }
    },
    confirmOrderTransfer(order, destinationDeviceId) {
      this.log(
        `About to send request for confirmTransfer of order '${order.processUuid}' to device '${destinationDeviceId}'`
      );

      let url = this.$app.urls.process.confirmTransfer
        .replace("{tenantId}", this.$estore.get("tenant.tenantId"))
        .replace("{cashierId}", this.$estore.get("deviceId"))
        .replace("{processName}", "order")
        .replace("{processUuid}", order.processUuid);

      this.$http({
        url: url,
        method: "post",
        data: { destinationDeviceId },
      }).then(
        (response) => {
          this.log(
            `Response received for confirmTransfer: ${response.status} - ${response.statusText}`
          );
          this.removeFromSyncCashierBook(
            "confirmOrderTransfer",
            order.processUuid,
            true,
            true
          );
          this.clearOrder(order);
        },
        (error) => {
          this.log(`Error response for confirmTransfer: ${error}`);
          // cancel on other cashier will not be transferred to this cashier, so just retry 3 times and stop
          order.process.confirmTransferRetryCount = order.process
            .confirmTransferRetryCount
            ? order.process.confirmTransferRetryCount + 1
            : 1;
          if (order.process.confirmTransferRetryCount <= 3) {
            this.confirmOrderTransfer(order, destinationDeviceId);
          }
        }
      );
    },
    addUnremovableWaiter(id, reason) {
      const entry = this.unremovableWaiters.find(
        (w) => w.id === id && w.reason === reason
      );
      if (!entry) {
        this.unremovableWaiters.push({
          id: id,
          reason: reason,
        });
      }
    },
    deleteUnremovableWaiter(id, reason) {
      const index = this.unremovableWaiters.findIndex(
        (w) => w.id === id && w.reason === reason
      );
      if (index >= 0) {
        this.unremovableWaiters.splice(index, 1);
      }
    },
    selectWaiter(id) {
      this.currentWaiterId = id;
      let waiterEntry = this.getWaiterEntry();
      if (!waiterEntry) {
        waiterEntry = this.createWaiterEntry(id);
      }

      //all openOrders in order to be able to give feedback on other tabs as well
      this.currentWaiterOrders = waiterEntry.openOrders;
      this.currentOrderTab = waiterEntry.currentOrderTab;
      this.currentOrder = this.currentWaiterOrders.find(
        (i) => i.tabId === this.currentOrderTab
      );
      this.currentCategoryTabNr = 0;
    },
    getOrderById(uuid) {
      let result = undefined;
      this.cashierBook.forEach((c) => {
        const order = c.openOrders.find((o) => o.processUuid === uuid);
        if (order) {
          result = order;
        }
      });
      return result;
    },
    createWaiterEntry(waiterId) {
      let emptyWaiterOrders = [];
      emptyWaiterOrders.push(this.createEmptyOrderForTab(waiterId, -1));
      for (let i = 1; i <= this.nbrOfWaiterTabs; i++) {
        emptyWaiterOrders.push(this.createEmptyOrderForTab(waiterId, i));
      }
      let newWaiterEntry = {
        waiterId: waiterId,
        openOrders: emptyWaiterOrders,
        currentOrderTab: 1,
        currentProductViewingMode: 0,
        hasOrderItems() {
          return this.openOrders.filter((o) => o.totalItems > 0).length > 0;
        },
      };
      this.cashierBook.push(newWaiterEntry);
      return newWaiterEntry;
    },
    removeWaiter(data) {
      this.cashierBook.splice(
        this.cashierBook.findIndex(
          (item) => item.waiterId === data.waiterIndexRemoved
        ),
        1
      );
      this.selectWaiter(data.waiterIndexSelected);
    },
    productSelected(product) {
      let order = this.currentOrder;
      let creationNeeded = false;
      if (!order.processUuid) {
        order.processUuid = this.$uuid.v4();
        order.dateTime = new Date().toJSON();
        order.orderReference = this.generateCashierOrderReference(order);
        creationNeeded = true;
      }
      const orderItems = order.orderItems;
      let orderItem = orderItems.find((o) => o.id === product.id);
      if (orderItem) {
        // use the latest product data as it might have changed during the order
        orderItem.name = product.name;
        orderItem.price = product.price;
        orderItem.total += this.correctionMode ? -1 : 1;
        orderItem.totalAmount = orderItem.total * orderItem.price;
      } else {
        orderItems.push({
          id: product.id,
          name: product.name,
          total: this.correctionMode ? -1 : 1,
          price: product.price,
          totalAmount: product.price * (this.correctionMode ? -1 : 1),
        });
      }
      order.totalItems += this.correctionMode ? -1 : 1;
      order.lastAddedProductId = product.id;
      let newTotal = 0;
      orderItems.forEach((o) => (newTotal += o.totalAmount));
      order.totalAmount = newTotal;

      if (creationNeeded) {
        //try to create the new order in the backend, ignore if it fails, as it will by synced later on
        const that = this;
        const onCompleteFunction = () => {
          const cbo = that.getOrderById(order.processUuid);
          if (cbo) {
            that.log(`Created order '${order.processUuid}' on first item successfully`);
            cbo.processCreated = true;
          }
        };

        const onErrorFunction = (error) => {
          that.log(
              `Ignoring error while trying to create order on first item '${order.processUuid}': ${error}`
          );
        };

        const payload = this.createOrderPayload(order);
        this.createOrder(
            order.processUuid,
            payload,
            undefined,
            onCompleteFunction,
            onErrorFunction
        );
      }

      //set feedback about all tabs
      this.changeWaiterTotalItemsWithNormalState(
        this.currentWaiterId,
        this.calculateTotalWaiterOrderItemsInNormalState()
      );

      this.addUnremovableWaiter(order.waiterId, "pendingOrders");
    },
    categorySelected(tabNr) {
      this.currentCategoryTabNr = tabNr;
    },
    productViewingModeChanged(mode) {
      this.getWaiterEntry().currentProductViewingMode = mode;
    },
    orderTabSelected(tabNbr) {
      this.getWaiterEntry().currentOrderTab = tabNbr;
      this.selectWaiter(this.currentWaiterId);
    },
    selectOrderItem(orderItem) {
      let order = this.currentOrder;
      order.selectedOrderItem = {
        id: orderItem.id,
        name: orderItem.name,
      };
      order.lastAddedProductId = undefined;
    },
    unselectOrderItem() {
      this.currentOrder.selectedOrderItem = undefined;
    },
    minusOrderItem(orderItem) {
      const itemIndex = this.currentOrder.orderItems.findIndex(
        (i) => i.id === orderItem.id
      );
      let item = this.currentOrder.orderItems[itemIndex];

      if (Math.abs(item.total) === 1) {
        item = this.currentOrder.orderItems.splice(itemIndex, 1); //possible old price
        this.currentOrder.selectedOrderItem = undefined;
        item.total = 0;
        item.totalAmount = 0;
      } else {
        item.total -= this.correctionMode ? -1 : 1;
        //use the latest price for all products, as prices might have changed during the order
        item.totalAmount = item.total * orderItem.price;
      }
      this.currentOrder.totalItems -= this.correctionMode ? -1 : 1;
      let newTotal = 0;
      this.currentOrder.orderItems.forEach((o) => (newTotal += o.totalAmount));
      this.currentOrder.totalAmount = newTotal;
      this.changeWaiterTotalItemsWithNormalState(
        this.currentWaiterId,
        this.calculateTotalWaiterOrderItemsInNormalState()
      );
      if (this.calculateTotalWaiterOrderItems() === 0) {
        this.deleteUnremovableWaiter(this.currentWaiterId, "pendingOrders");
      }
      if (this.currentOrder.totalItems === 0) {
        this.deleteOrder();
      }
    },
    plusOrderItem(orderItem) {
      let item = this.currentOrder.orderItems.find(
        (i) => i.id === orderItem.id
      );
      item.total += this.correctionMode ? -1 : 1;
      //use the latest price for all products, as prices might have changed during the order
      item.totalAmount = item.total * orderItem.price;
      this.currentOrder.totalItems += this.correctionMode ? -1 : 1;
      let newTotal = 0;
      this.currentOrder.orderItems.forEach((o) => (newTotal += o.totalAmount));
      this.currentOrder.totalAmount = newTotal;
      this.changeWaiterTotalItemsWithNormalState(
        this.currentWaiterId,
        this.calculateTotalWaiterOrderItemsInNormalState()
      );
      if (this.calculateTotalWaiterOrderItems() === 0) {
        this.deleteUnremovableWaiter(this.currentWaiterId, "pendingOrders");
      }
      if (this.currentOrder.totalItems === 0) {
        this.deleteOrder();
      }
    },
    setPaymentRequested() {
      this.currentOrder.paymentRequested = true;
    },
    calculateTotalWaiterOrderItemsInNormalState(waiterId) {
      let count = 0;
      this.getWaiterEntry(waiterId).openOrders.forEach((o) => {
        if (o.status === "normal") count += o.totalItems;
      });
      return count;
    },
    calculateTotalWaiterOrderItems(waiterId) {
      let count = 0;
      this.getWaiterEntry(waiterId).openOrders.forEach((o) => {
        count += o.totalItems;
      });
      return count;
    },
    deleteOrder(order = this.currentOrder) {
      this.log(
        `About to put delete order '${order.processUuid}' on processesToSync`
      );
      this.removeFromSyncCashierBook(
        "deleteOrder",
        order.processUuid,
        true,
        true
      );
      this.$estore.appendList("tenant.processesToSync", {
        process: "order",
        action: "cancel",
        uuid: order.processUuid,
        ignoreStatusCodes: [404],
        payload: {
          cancelInitiator: "CASHIER",
        },
      });
      this.clearOrder();
    },
    clearOrder(order = this.currentOrder) {
      const orderId = order.processUuid;
      const waiterId = order.waiterId;
      const tabId = order.tabId;
      const isCurrentOrder = this.currentOrder.processUuid === orderId;

      this.log(
        `About to clear ${
          isCurrentOrder ? "currentOrder" : "order"
        } with id '${orderId}' for waiterId '${waiterId}' on tab '${tabId}'`
      );

      this.stopPollProcess(orderId);
      // TODO cancel sync, remove from sync list

      let closeCorrectionMode = false;
      if (order.paymentOngoing) {
        this.orderFinished(order);
        closeCorrectionMode =
          isCurrentOrder && this.correctionMode ? true : false;
      }

      const openOrders = this.getWaiterEntry(waiterId).openOrders;
      openOrders.splice(
        openOrders.findIndex((i) => i.tabId === tabId),
        1
      );

      const emptyOrder = this.createEmptyOrderForTab(waiterId, tabId);
      if (isCurrentOrder) {
        this.currentOrder = emptyOrder;
        this.currentCategoryTabNr = 0;
      }
      openOrders.push(emptyOrder);

      this.changeWaiterTotalItemsWithNormalState(
        waiterId,
        this.calculateTotalWaiterOrderItemsInNormalState(waiterId)
      );
      if (this.calculateTotalWaiterOrderItems(waiterId) === 0) {
        this.deleteUnremovableWaiter(waiterId, "pendingOrders");
      }
      if (closeCorrectionMode) {
        this.orderTabSelected(this.orderTabBeforeCorrection);
        this.$estore.set("tenant.correction.mode", false);
        this.correctionMode = false;
      }
      this.noRemainingFreeOrdersDialog =
        this.isFreePlan && this.remainingFreeOrders === 0;
    },
    requestCashPayment() {
      this.payOrder("CASH");
    },
    requestTopupzPayment(scannerId) {
      this.payOrder("TOPUPZ", scannerId);
    },
    requestBancontactPayment(scannerId) {
      this.payOrder("BANCONTACT", scannerId);
    },
    requestQrPayment() {
      this.payOrder();
    },
    payOrder(paymentMethod, scannerId) {
      let order = this.currentOrder;
      order.paymentOngoing = true;
      this.$estore.appendList("tenant.ongoingOrders", order);

      this.removeFromSyncCashierBook("payOrder", order.processUuid, true, true);

      let orderPayload = this.createOrderPayload(order);

      const errorHandler = this.orderOfflineErrorFunction(
        order,
        orderPayload,
        paymentMethod
      );
      const feedbackFunction = this.orderFeedbackFunction(order);

      if (!order.processCreated) {
        const functionAfterCreateSuccess = (uuid, payload, processData) => {
          order.processCreated = true;
          if (paymentMethod) {
            payload.paymentMethod = paymentMethod.toUpperCase();
          } else {
            payload.paymentQrCodeId = this.qrCodePayment;
          }
          if (scannerId) {
            payload.scannerId = scannerId;
          }
          this.completeEntry(
            uuid,
            payload,
            processData,
            feedbackFunction,
            errorHandler
          );
        };

        this.createOrder(
          order.processUuid,
          orderPayload,
          order.process,
          functionAfterCreateSuccess,
          errorHandler
        );
      } else {
        if (paymentMethod) {
          orderPayload.paymentMethod = paymentMethod.toUpperCase();
        } else {
          orderPayload.paymentQrCodeId = this.qrCodePayment;
        }
        if (scannerId) {
          orderPayload.scannerId = scannerId;
        }
        this.completeEntry(
          order.processUuid,
          orderPayload,
          order.process,
          feedbackFunction,
          errorHandler
        );
      }
    },
    createOrderPayload(order) {
      const requestPayload = {};
      const orderItems = [];

      if (!order.dateTime) {
        // for older versions of the cashier
        order.dateTime = new Date().toJSON();
      }
      if (!order.processUuid) {
        // for older versions of the cashier
        order.processUuid = this.$uuid.v4();
      }

      order.orderItems.forEach((item) => {
        orderItems.push({
          productId: item.id,
          productName: item.name,
          unitPrice: item.price,
          quantity: item.total,
        });
      });

      requestPayload.orderReference = order.orderReference;
      requestPayload.items = orderItems;
      requestPayload.dateTime = order.dateTime;

      return requestPayload;
    },
    createOrder(
      uuid,
      payload,
      processData,
      functionAfterSuccess,
      functionAfterError
    ) {
      this.log(`About to send request for createOrder for uuid: ${uuid}`);
      let url = this.$app.urls.process.start
        .replace("{tenantId}", this.$estore.get("tenant.tenantId"))
        .replace("{cashierId}", this.$estore.get("deviceId"))
        .replace("{processName}", "order")
        .replace("{processUuid}", uuid);

      const axiosSource = Vue.prototype.$http.CancelToken.source();

      this.$http({
        url: url,
        method: "put",
        data: payload,
        cancelToken: axiosSource.token,
      }).then(
        (response) => {
          this.log(
            `Response received for createOrder: ${response.status} - ${response.statusText}`
          );
          if (functionAfterSuccess) {
            this.log("createOrder - calling function after success");
            functionAfterSuccess(uuid, payload, processData);
          }
        },
        (error) => {
          if (processData) {
            processData.loading = false;
            processData.success = false;
          }
          this.log(`Error response for createOrder: ${error}`);
          if (functionAfterError) {
            this.log("createOrder - calling function after error");
            functionAfterError(error);
          }
        }
      );
      return axiosSource.cancel;
    },
    setOrderItems(uuid, payload, functionAfterSuccess, functionAfterError) {
      this.log(`About to send request for setOrderItems for uuid: ${uuid}`);
      let url = this.$app.urls.process.items
        .replace("{tenantId}", this.$estore.get("tenant.tenantId"))
        .replace("{cashierId}", this.$estore.get("deviceId"))
        .replace("{processName}", "order")
        .replace("{processUuid}", uuid);

      const axiosSource = Vue.prototype.$http.CancelToken.source();

      this.$http({
        url: url,
        method: "post",
        data: payload,
        cancelToken: axiosSource.token,
      }).then(
        (response) => {
          this.log(
            `Response received for setOrderItems: ${response.status} - ${response.statusText}`
          );
          if (functionAfterSuccess) {
            this.log("setOrderItems - calling function after success");
            functionAfterSuccess(uuid, payload);
          }
        },
        (error) => {
          this.log(`Error response for setOrderItems: ${error}`);
          if (functionAfterError) {
            this.log("setOrderItems - calling function after error");
            functionAfterError(error);
          }
        }
      );
      return axiosSource.cancel;
    },
    completeEntry(uuid, payload, processData, feedbackCallback, errorHandler) {
      this.log(`About to send request for completeEntry for uuid: ${uuid}`);

      // TODO: what if crash here, recovery possible to pay screen (like e.g. status === TO_COMPLETE then button to return to pay screen from process screen?)

      processData.started = false;

      let url = this.$app.urls.process.completeEntry
        .replace("{tenantId}", this.$estore.get("tenant.tenantId"))
        .replace("{cashierId}", this.$estore.get("deviceId"))
        .replace("{processName}", "order")
        .replace("{processUuid}", uuid);

      this.$http({
        url: url,
        method: "post",
        data: payload,
      }).then(
        (response) => {
          processData.started = true;
          this.log(
            `Response received for completeEntry: ${response.status} - ${response.statusText}`
          );
          this.startPollProcess(uuid, "order", processData, feedbackCallback);
        },
        (error) => {
          processData.loading = false;
          processData.success = false;
          this.log(`Error response for completeEntry: ${error}`);
          errorHandler(error);
        }
      );
    },
    completePreparation(delivered = false, syncAllowed = false, deliveryType) {
      this.orderAction(
        "completePreparation",
        this.currentOrder,
        deliveryType
          ? { delivered: delivered, deliveryType: deliveryType }
          : { delivered: delivered },
        syncAllowed
      );
    },
    orderAction(action, order, payload, syncAllowed = false) {
      this.log(
        `About to send request for ${action} for uuid: ${order.processUuid}`
      );

      const errorHandler = this.orderActionErrorFunction(
        action,
        order,
        payload,
        syncAllowed
      );
      const processData = order.process;
      processData.loading = true;
      processData.started = false;
      processData.message1 = undefined;
      processData.message2 = undefined;

      let url = this.$app.urls.process[action]
        .replace("{tenantId}", this.$estore.get("tenant.tenantId"))
        .replace("{cashierId}", this.$estore.get("deviceId"))
        .replace("{processName}", "order")
        .replace("{processUuid}", order.processUuid);

      this.$http({
        url: url,
        method: "post",
        data: payload,
      }).then(
        (response) => {
          processData.started = true;
          processData.loading = false;
          processData.success = true;
          this.log(
            `Response received for ${action}: ${response.status} - ${response.statusText}`
          );
          this.clearOrder();
        },
        (error) => {
          processData.started = true;
          processData.loading = false;
          processData.success = false;
          this.log(`Error response for ${action}: ${error}`);
          errorHandler(error);
        }
      );
    },
    orderActionErrorFunction(action, order, payload, syncAllowed) {
      return (error) => {
        if (error && error.message.includes("Network Error")) {
          if (syncAllowed) {
            this.$estore.appendList("tenant.processesToSync", {
              process: "order",
              action: action,
              uuid: order.processUuid,
              payload: payload,
            });

            this.notifyOrderSuccess(
              true,
              order,
              this.$t("message.offlineOrderProcessing")
            );
            order.process.lastPayload = undefined;
            return;
          } else {
            order.process.message1 = this.$t("message.connectionIssue");
            order.process.message2 = this.$t("message.retry");
          }
        } else {
          this.$security.access_token = undefined; //to be sure it is not invalid in the meantime
          order.process.message1 = error.message;
        }
        this.notifyOrderSuccess(false, order);
      };
    },
    orderOfflineErrorFunction(order, payload, paymentMethod) {
      return (error) => {
        if (error && error.message.includes("Network Error")) {
          if (paymentMethod === "CASH") {
            payload.paymentMethod = paymentMethod.toUpperCase();
            this.$estore.appendList("tenant.processesToSync", {
              process: "order",
              action: "completeOffline",
              uuid: order.processUuid,
              payload: payload,
            });

            this.notifyOrderSuccess(
              true,
              order,
              this.$t("message.offlineOrderProcessing")
            );

            return;
          } else {
            order.process.message1 = this.$t("message.connectionIssue");
            order.process.message2 = this.$t("message.onlyOffline");
          }
        } else {
          this.$security.access_token = undefined; //to be sure it is not invalid in the meantime
          order.process.message1 = error.message;
        }
        this.notifyOrderSuccess(false, order);
      };
    },
    orderFeedbackFunction(order) {
      return (isSuccess, payload, message1) => {
        if (message1) {
          this.notifyOrderSuccess(isSuccess, order, message1);
        } else {
          this.notifyOrderSuccess(isSuccess, order);
          if (isSuccess) {
            order.process.message2 = undefined;
            if (
              payload.status === "PREPARATION_ONGOING" &&
              (payload.preparationDelegated || payload.initiator === "CUSTOMER")
            ) {
              order.process.message1 = this.$t(
                `message.${payload.status.toLowerCase()}`
              );
            } else {
              if (
                payload.status === "SERVICE_ONGOING" ||
                payload.status === "PICKUP_ONGOING"
              ) {
                order.process.message1 = this.$t(
                  `message.${payload.status.toLowerCase()}`
                );
              } else {
                order.process.message1 = this.$t("message.orderOk");
                order.process.message2 = this.$t(
                  `message.${
                    payload.status ? payload.status.toLowerCase() : "unknown"
                  }`
                );
              }
            }
          } else {
            order.process.message1 = this.$t(
              `message.${
                payload.status ? payload.status.toLowerCase() : "unknown"
              }`
            );
          }
        }
      };
    },
    topupFeedbackFunction() {
      return (isSuccess, payload, message1) => {
        this.topupData.process.loading = false;
        this.topupData.process.success = isSuccess;
        if (message1) {
          this.topupData.process.message1 = message1;
        } else {
          if (isSuccess) {
            this.topupData.process.message1 = this.$t("message.topupOk");
          } else {
            this.topupData.process.message1 = this.$t(
              `message.${
                payload.status ? payload.status.toLowerCase() : "unknown"
              }`
            );
          }
        }
      };
    },
    prepareAndSendTopup(scannerId, amount, paymentMethod) {
      let topup = this.topupData;
      topup.dateTime = new Date().toJSON();
      topup.paymentOngoing = true;

      const newTopup = {
        scannerId: scannerId,
        amount: amount,
        paymentMethod: paymentMethod,
      };

      const uuid = this.$uuid.v4();
      topup.processUuid = uuid;
      topup.waiterId = this.currentWaiterId;
      topup.tabId = this.currentOrderTab;
      topup.amount = amount;
      topup.paymentOngoing = true;
      this.$estore.set("tenant.ongoingTopup", topup);

      const feedbackFunction = this.topupFeedbackFunction();

      const errorHandler = (error) => {
        if (error && error.message.includes("Network Error")) {
          topup.process.message1 = this.$t("message.connectionIssue");
          topup.process.message2 = this.$t("message.retryOrReturnCash");
        } else {
          this.$security.access_token = undefined; //to be sure it is not invalid in the meantime
          topup.process.message1 = error.message;
        }
      };

      this.putProcess(
        "topup",
        uuid,
        topup.process,
        newTopup,
        feedbackFunction,
        errorHandler
      );
    },
    putProcess(
      processName,
      uuid,
      processData,
      requestPayload,
      feedbackCallback,
      errorHandler
    ) {
      processData.started = false;

      let url = this.$app.urls.process.start
        .replace("{tenantId}", this.$estore.get("tenant.tenantId"))
        .replace("{cashierId}", this.$estore.get("deviceId"))
        .replace("{processName}", processName)
        .replace("{processUuid}", uuid);

      this.$http({
        url: url,
        method: "put",
        data: requestPayload,
      }).then(
        (response) => {
          processData.started = true;
          this.log(
            `Response received for put ${processName}: ${response.status} - ${response.statusText}`
          );
          this.startPollProcess(
            uuid,
            processName,
            processData,
            feedbackCallback
          );
        },
        (error) => {
          processData.loading = false;
          processData.success = false;
          this.log(`Error response for put ${processName}: ${error}`);
          errorHandler(error);
        }
      );
    },

    startPollProcess(uuid, processName, processData, feedbackCallback) {
      let processToPoll = this.pollingProcesses.find((p) => p.uuid === uuid);
      if (!processToPoll) {
        processData.lastPayload = undefined;
        processToPoll = {
          uuid: uuid,
          pollingBusy: false,
        };
        this.pollingProcesses.push(processToPoll);
        console.log(
          `Added '${uuid}' to polling list${
            processData.waitingForMatchingCashierId
              ? " (waitingForMatchingCashierId)"
              : ""
          }`
        );
      }
      processToPoll.timerHandle = setInterval(() => {
        if (!processToPoll.pollingBusy) {
          let url = this.$app.urls.process[
            processName === "order" ? "tenantOrderInfo" : "start"
          ]
            .replace("{tenantId}", this.$estore.get("tenant.tenantId"))
            .replace("{cashierId}", this.$estore.get("deviceId"))
            .replace("{processName}", processName)
            .replace("{processUuid}", uuid);

          processToPoll.pollingBusy = true;
          this.$http({
            url: url,
          }).then(
            (response) => {
              processData.started = true;
              const payload = response.data;
              processToPoll.pollingBusy = false;
              let isProcessUpdated = false;
              if (!processToPoll.lastPayload) {
                isProcessUpdated = true;
              } else {
                isProcessUpdated =
                  JSON.stringify(payload) !==
                  JSON.stringify(processToPoll.lastPayload);
              }
              processToPoll.lastPayload = payload;
              if (isProcessUpdated) {
                this.log(
                  `Response received for get ${processName}: ${response.status} - ${response.statusText}`
                );
                this.log(payload);
              }
              processData.message1 = "";
              processData.message2 = "";
              processData.lastPayload = payload;

              if (
                processData.waitingForMatchingCashierId &&
                this.$estore.get("deviceId") !== payload.cashierId
              ) {
                processData.message1 = this.$t(
                  "message.waitingForMatchingCashierId"
                );
                return;
              } else {
                if (payload.status === "TO_ENTER") {
                  //stop polling and return to product selection
                  const order = this.getOrderById(uuid);
                  order.paymentRequested = false;
                  order.paymentOngoing = false;
                  this.stopPollProcess(uuid);
                  this.orderFinished(order);

                  return;
                }

                if (
                  [
                    "WAITING_FOR_CARD_SCAN",
                    "WAITING_FOR_ONLINE_PAYMENT",
                    "WAITING_FOR_QR_PAYMENT",
                  ].includes(payload.status)
                ) {
                  processData.message1 = this.$t(
                    `message.${payload.status.toLowerCase()}`
                  );
                  return;
                }

                if (
                  payload &&
                  (payload.error === true ||
                    payload.accepted === true ||
                    (payload.status && payload.status === "DONE") ||
                    (payload.status && payload.status.startsWith("CANCELED")))
                ) {
                  this.stopPollProcess(uuid);

                  if (payload.status.startsWith("CANCELED")) {
                    let message1;
                    switch (payload.status) {
                      case undefined:
                        message1 = this.$t("message.canceled_by_user");
                        break;
                      default:
                        message1 = this.$t(
                          `message.${
                            payload.status
                              ? payload.status.toLowerCase()
                              : "unknown"
                          }`
                        );
                    }
                    feedbackCallback(false, payload, message1);
                    return;
                  }
                  feedbackCallback(!payload.error, payload);
                }
              }
            },
            (error) => {
              this.log(`Error response for get ${processName}: ${error}`);
              processToPoll.pollingBusy = false;
              if (!error || !error.message.includes("Network Error")) {
                this.$security.access_token = undefined; //to be sure it is not invalid in the meantime
                processData.loading = false;
                processData.success = false;
              }
              processData.loading = true;
              processData.message1 = error.message;
              processData.message2 = this.$t("message.autoRetry");
            }
          );
        }
      }, 1000);
    },
    stopPollProcess(processUuid) {
      console.log(`Trying to stop poll for '${processUuid}'`);
      let processToPoll = this.pollingProcesses.find(
        (p) => p.uuid === processUuid
      );
      if (processToPoll) {
        clearInterval(processToPoll.timerHandle);
        this.pollingProcesses.splice(
          this.pollingProcesses.findIndex((p) => p.uuid === processUuid),
          1
        );
        console.log(
          `Stopped polling '${processUuid}' and removed from polling list`
        );
      } else {
        this.log(
          `Polling for processUuid '${processUuid}' cannot be stopped as there was no polling active.`
        );
      }
    },
    cancelPaymentRequested() {
      this.currentOrder.paymentRequested = false;
    },
    cancelOrderProcess() {
      const errorHandler = () =>
        this.notifyOrderSuccess(
          false,
          this.currentOrder,
          this.$t("message.offlineOrderCancel"),
          this.$t("message.performOfflineCash")
        );

      this.cancelProcess(
        "order",
        this.currentOrder.processUuid,
        this.currentOrder.process,
        errorHandler
      );
    },
    cancelTopupProcess() {
      const errorHandler = () => {
        this.topupData.process.loading = false;
        this.topupData.process.success = false;
        this.topupData.process.message1 = this.$t("message.offlineTopupCancel");
        this.topupData.process.message2 = this.$t("message.returnCash");
      };

      this.cancelProcess(
        "topup",
        this.topupData.processUuid,
        this.topupData.process,
        errorHandler
      );
    },
    cancelProcess(processName, uuid, processData, errorHandlerOfflineMode) {
      let url = this.$app.urls.process.cancel
        .replace("{tenantId}", this.$estore.get("tenant.tenantId"))
        .replace("{cashierId}", this.$estore.get("deviceId"))
        .replace("{processName}", processName)
        .replace("{processUuid}", uuid);

      const payload = {
        cancelInitiator: "CASHIER",
      };

      this.$http({
        url: url,
        method: "post",
        data: payload,
      }).then(
        (response) => {
          this.log(
            `Response received for cancel ${processName}: ${response.status} - ${response.statusText}`
          );
          processData.started = false;
        },
        (error) => {
          this.log(`Error response for cancel ${processName}: ${error}`);
          if (!error || !error.message.includes("Network Error")) {
            this.$security.access_token = undefined; //to be sure it is not invalid in the meantime
            //TODO: propose to force cancel and keep track in estore list: processesToCancel which will be retried once connection is up
            //TODO: in general treat 401 and 403 in a specific manner, i.e. prevent using the app?
            this.generalError.message1 = this.$t("message.cancelFailed");
            this.generalError.message2 = error ? error.mesage : "";
            this.generalError.show = true;
          } else {
            this.$estore.appendList("tenant.processesToSync", {
              process: processName,
              action: "cancel",
              uuid: uuid,
              payload: payload,
            });

            this.stopPollProcess(uuid);

            errorHandlerOfflineMode();
          }
        }
      );
    },
    notifyOrderSuccess(successful, order, message1, message2) {
      order.status = successful ? "success" : "error";
      this.addWaiterFeedback(order, successful);
      order.process.loading = false;
      order.process.success = successful;
      if (message1) {
        order.process.message1 = message1;
      }
      if (message2) {
        order.process.message2 = message2;
      }
    },
    orderFinished(order) {
      // const order = this.$estore.removeFromListAtIndex(
      this.$estore.removeFromListAtIndex(
        "tenant.ongoingOrders",
        this.$estore
          .get("tenant.ongoingOrders", [])
          .findIndex((o) => o.processUuid === order.processUuid)
      );
      this.removeWaiterFeedback(order);
      // this.deleteUnremovableWaiter(order.waiterId, "pendingOrders");
    },
    resetProcesStatus() {
      this.orderFinished(this.currentOrder);
      this.currentOrder.status = "normal";

      this.currentOrder.processUuid = this.$uuid.v4();
      this.currentOrder.orderReference = this.generateCashierOrderReference(
        this.currentOrder
      );
      this.currentOrder.processCreated = false;

      //TODO: check what todo about sync mechanism for orderCreated and entryItems

      this.currentOrder.process.lastPayload = undefined;
      this.currentOrder.process.success = false;
      this.currentOrder.process.loading = true;
      this.currentOrder.process.started = false;
      this.currentOrder.process.message1 = "";
      this.currentOrder.process.message2 = "";
      this.currentOrder.paymentOngoing = false;
    },
    changeWaiterTotalItemsWithNormalState(waiterId, number) {
      let info = this.waitersInfo.find((f) => f.id === waiterId);
      let previous;
      if (!info) {
        info = {
          id: waiterId,
          processes: [],
          unremovable: false,
          totalOrderItems: number,
        };
      } else {
        //remove previous, needed in order to notify the watches
        const index = this.waitersInfo.findIndex((f) => f.id === waiterId);
        if (index >= 0) {
          previous = this.waitersInfo.splice(index, 1);
          info = {
            id: waiterId,
            processes: previous.processes,
            unremovable: previous.feedback,
            totalOrderItems: number,
          };
        }
      }
      this.waitersInfo.push(info);
    },
    addWaiterFeedback(order, success) {
      const waiterId = order.waiterId;
      const uuid = order.processUuid;
      order.waiterFeedback = true;
      let feedback = this.waitersFeedback.find((f) => f.id === waiterId);
      if (!feedback) {
        feedback = {
          id: waiterId,
          processes: [],
        };
        this.waitersFeedback.push(feedback);
      } else {
        //remove previous
        const index = feedback.processes.findIndex((p) => p.uuid === uuid);
        if (index >= 0) {
          feedback.processes.splice(index, 1);
        }
      }
      feedback.processes.push({
        uuid: uuid,
        success: success,
      });
      this.changeWaiterTotalItemsWithNormalState(
        waiterId,
        this.calculateTotalWaiterOrderItemsInNormalState(waiterId)
      );
    },
    removeWaiterFeedback(order) {
      if (order.processUuid && order.waiterFeedback) {
        const waiterFeedback = this.waitersFeedback.find(
          (f) => f.id === order.waiterId
        );
        if (waiterFeedback) {
          const feedbackProcesses = waiterFeedback.processes;

          feedbackProcesses.splice(
            feedbackProcesses.findIndex((p) => p.uuid === order.processUuid),
            1
          );
        }

        order.waiterFeedback = false;
      }
    },
    getWaiterEntry(waiterId = this.currentWaiterId) {
      return this.cashierBook.find((item) => item.waiterId === waiterId);
    },
    createEmptyOrderForTab(waiterId, tabId) {
      const order = {
        waiterId: waiterId,
        dateTime: undefined,
        tabId: tabId,
        orderItems: [],
        lastSentOrderItemsHash: undefined,
        totalAmount: 0,
        totalItems: 0,
        paymentRequested: false,
        paymentOngoing: false,
        selectedOrderItem: undefined,
        lastAddedProductId: undefined,
        status: "normal",
        waiterFeedback: false,
        processCreated: false,
        processUuid: undefined,
        process: {
          success: false,
          loading: true,
          started: false,
          message1: "",
          message2: "",
        },
      };
      order.orderReference = this.generateCashierOrderReference(order);

      return order;
    },
    generateCashierOrderReference(order) {
      const waiter = this.$estore
        .get("tenant.activeWaiters", [])
        .find((aw) => aw.id === order.waiterId);
      const waiterName = waiter ? waiter.name : "???";
      return order.tabId === -1
        ? `${waiterName} (${this.$t("label.correction")})`
        : `${waiterName} (${order.tabId})`;
    },
    log(msg) {
      console.log(msg);
      //TODO: add to this.$app.messages the last 50 messages??? useful?
    },
    updateProductsNow() {
      if (this.productUpdateTimer) {
        //possible that an earlier notification was snoozed
        clearTimeout(this.productUpdateTimer);
      }
      if (this.productsRetryHandler) {
        clearTimeout(this.productsRetryHandler);
      }
      this.fetchProductCategories();
      this.fetchProducts();
      this.productUpdateReceived = false;
    },
    updateProductsLater() {
      this.productUpdateTimer = setTimeout(() => {
        this.productUpdateReceived = true;
      }, 5 * 60 * 1000);
      this.productUpdateReceived = false;
    },
    factoryReset() {
      this.$pstore.clear();
      this.$estore.clear();
      this.$router.push("/setup");
    },
    syncProcess(offline) {
      let url = this.$app.urls.process[offline.action]
        .replace("{tenantId}", this.$estore.get("tenant.tenantId"))
        .replace("{cashierId}", this.$estore.get("deviceId"))
        .replace("{processName}", offline.process)
        .replace("{processUuid}", offline.uuid);

      this.syncingProcesses.push(offline.uuid);

      this.$http({
        url: url,
        method: "post",
        data: offline.payload,
      }).then(
        (response) => {
          this.log(
            `Synced successfully process '${offline.uuid}' with status '${response.status}'`
          );
          this.removeFromSyncProcess("syncProcess (success)", offline.uuid);
        },
        (error) => {
          if (
            !error ||
            (error.message && !error.message.includes("Network Error"))
          ) {
            this.log(
              `Error syncing process '${offline.uuid}'${
                error && error.message ? ": " + error : ""
              }`
            );
            //TODO: should we keep it in a seperate list, have some other notification, as it could be a structural issue which blocks the syncing icon forever?
            this.removeFromSyncProcess(
              "syncProcess (error)",
              offline.uuid,
              !error ||
                !error.response ||
                !error.response.status ||
                (offline.ignoreStatusCodes &&
                  offline.ignoreStatusCodes.includes(error.response.status))
            );
          } else {
            this.removeFromSyncProcess(
              "syncProcess (error)",
              offline.uuid,
              false
            );
          }
        }
      );
    },
    removeFromSyncProcess(requester, processId, removeFromQueue = true) {
      const syncingIndex = this.syncingProcesses.findIndex(
        (s) => s === processId
      );
      if (syncingIndex >= 0) {
        this.syncingProcesses.splice(syncingIndex, 1);
        this.log(
          `${requester} - removed process '${processId}' from syncingProcesses`
        );
      }

      if (removeFromQueue) {
        const waitingSyncIndex = this.$estore
          .get("tenant.processesToSync", [])
          .findIndex((p) => p.uuid === processId);
        if (waitingSyncIndex >= 0) {
          this.$estore.removeFromListAtIndex(
            "tenant.processesToSync",
            waitingSyncIndex
          );
          this.log(
            `${requester} - removed process '${processId}' from processesToSync`
          );
        }
      }
    },
    openSettingsAfterCorrectPin() {
      this.functionAfterCorrectPin = () => (this.settingsDialog = true);
      this.pinDialog = true;
    },
    openAddMemberAfterCorrectPin() {
      this.functionAfterCorrectPin = () => (this.addMemberDialog = true);
      this.pinDialog = true;
    },
    prepareCorrectionMode() {
      this.orderTabBeforeCorrection = this.currentOrderTab;
      this.$estore.set("tenant.correction.mode", true);
      this.$estore.set(
        "tenant.correction.previousOrderTab",
        this.orderTabBeforeCorrection
      );
      this.$estore.set("tenant.correction.waiterId", this.currentWaiterId);
      this.orderTabSelected(-1);
      this.correctionMode = true;
    },
    correctionModeAfterCorrectPin() {
      if (!this.correctionMode) {
        this.functionAfterCorrectPin = () => {
          this.prepareCorrectionMode();
        };
        this.pinDialog = true;
      } else {
        this.clearOrder();
        this.orderTabSelected(this.orderTabBeforeCorrection);
        this.$estore.set("tenant.correction.mode", false);
        this.correctionMode = false;
      }
    },
    openTopupAfterCorrectPin() {
      this.functionAfterCorrectPin = () => {
        this.resetTopupData();
        this.topupDialog = true;
      };
      if (this.correctionMode) {
        this.functionAfterCorrectPin();
      } else {
        this.pinDialog = true;
      }
    },
    callActionAfterCorrectPin() {
      if (this.functionAfterCorrectPin) {
        this.functionAfterCorrectPin();
      }
    },
    closeApp() {
      if (
        this.$estore.get("tenant.ongoingOrders", []).length > 0 ||
        this.$estore.get("tenant.ongoingTopup")
      ) {
        this.closeDialog = true;
      } else {
        this.sendCloseApp();
      }
    },
    sendCloseApp() {
      this.$estore.set(
        "tenant.nbrRunningProcessAtClose",
        this.pollingProcesses.length
      );
      this.$estore.set("tenant.restore", {
        waiterId: this.currentWaiterId,
        tabId: this.currentOrderTab,
      });

      this.$estore.set("tenant.restoreCashierBook", this.cashierBook); //separate in case write failed during close
      // setTimeout(() => ipcRenderer.send("closed"), 500); //give some time to write to the file
    },
    verifyCashierBookChange() {
      if (!this.verifyCashierBookHandle) {
        const that = this;
        // TODO: what if cashierBook was not saved to store after clean close? E.g crash or other? bummer? :-)
        // in case process created, it can be recovered from running orders ?

        this.verifyCashierBookHandle = setInterval(() => {
          that.cashierBook.forEach((cb) => {
            cb.openOrders.forEach((o) => {
              if (
                !this.$estore
                  .get("tenant.ongoingOrders", [])
                  .find((oo) => oo.processUuid === o.processUuid) &&
                !that.cashierBookOrdersToSync.find(
                  (s) => s.id === o.processUuid
                ) &&
                ((!o.processCreated && o.totalItems !== 0) ||
                  (o.lastSentOrderItemsHash &&
                    o.lastSentOrderItemsHash !==
                      btoa(JSON.stringify(o.orderItems))))
              ) {
                console.log(
                  `Added order '${o.processUuid}' to the cashierBookOrdersToSync`
                );
                that.cashierBookOrdersToSync.push(o.processUuid);
              }
            });
          });

          // the syncCashierBook will make sure to continue with the next order to sync if there are any
          // make sure to keep it going in case there weren't any orders to sync anymore...
          this.feedSyncCashierBook();
        }, 5000);
      }
    },
    removeFromSyncCashierBook(
      requester,
      orderId,
      removeFromQueue = true,
      cancelSync = false
    ) {
      const syncingIndex = this.syncingCashierBookOrders.findIndex(
        (s) => s.id === orderId
      );
      if (syncingIndex >= 0) {
        const syncingItem = this.syncingCashierBookOrders[syncingIndex];
        if (cancelSync && syncingItem && syncingItem.request) {
          try {
            this.log(
              `${requester} - trying to cancel sync request for order '${orderId}'`
            );
            syncingItem.request.cancel();
          } catch (e) {
            // ignore
          }
        }
        this.syncingCashierBookOrders.splice(syncingIndex, 1);
        this.log(
          `${requester} - removed order '${orderId}' from syncingCashierBookOrders`
        );
      }

      if (removeFromQueue) {
        const waitingSyncIndex = this.cashierBookOrdersToSync.findIndex(
          (s) => s === orderId
        );
        if (waitingSyncIndex >= 0) {
          this.cashierBookOrdersToSync.splice(waitingSyncIndex, 1);
          this.log(
            `${requester} - removed order '${orderId}' from cashierBookOrdersToSync`
          );
        }
      }
    },
    feedSyncCashierBook() {
      try {
        const maxOrders = 3;

        if (
          this.connected &&
          this.syncingCashierBookOrders.length < maxOrders &&
          this.cashierBookOrdersToSync.length > 0
        ) {
          let count = 0;
          //start a sync for the defined maxOrders
          this.cashierBookOrdersToSync
            .filter(
              (t) =>
                this.syncingCashierBookOrders.find((s) => s.id !== t.id) ===
                  undefined && count++ < maxOrders
            )
            .forEach((id) => this.syncCashierBookOrder(id));
        }
      } catch (err) {
        this.log(`Error in feedSyncCashierBook: ${err}`);
      }
    },
    syncCashierBookOrder(orderId) {
      console.log(`Syncing cashierBook order '${orderId}'`);
      const order = this.getOrderById(orderId);
      const orderItems = Array.from(order.orderItems); //shallow copy
      const that = this;

      const onCompleteFunction = () => {
        // is it possible that in the meantime the order has been deleted? localTransfer is possible, so search again, other object ref in that case
        const cbo = that.getOrderById(orderId);
        if (cbo) {
          that.log(`Synced cashierBook order '${orderId}' successfully`);
          cbo.processCreated = true;
          cbo.lastSentOrderItemsHash = btoa(JSON.stringify(orderItems));
        } else {
          that.log(
            `Synced cashierBook order '${orderId}' successfully, but the order doesn't exist anymore in the cashierBook...`
          );
        }
        that.removeFromSyncCashierBook("syncCashierBookOrder", orderId);
        that.feedSyncCashierBook();
      };

      const onErrorFunction = (error) => {
        that.log(
          `Error occured while syncing cashierBook order '${orderId}': ${error}`
        );

        if (
          error &&
          error.response &&
          (error.response.status === 404 || error.response.status === 409)
        ) {
          that.removeFromSyncCashierBook("syncCashierBookOrder", orderId);
        } else {
          // just remove from the syncing list in order to retry with the next run
          that.removeFromSyncCashierBook(
            "syncCashierBookOrder",
            orderId,
            false
          );
        }
      };

      const payload = this.createOrderPayload(order);
      const scbo = {
        id: orderId,
      };
      this.syncingCashierBookOrders.push(scbo);

      if (!order.processCreated) {
        scbo.request = this.createOrder(
          orderId,
          payload,
          undefined,
          onCompleteFunction,
          onErrorFunction
        );
      } else {
        scbo.request = this.setOrderItems(
          orderId,
          { items: payload.items },
          onCompleteFunction,
          onErrorFunction
        );
      }
    },
    syncToBackend() {
      if (!this.syncHandle) {
        this.processesToSync = this.$estore.get("tenant.processesToSync", []);

        const that = this;
        this.syncHandle = setInterval(() => {
          try {
            if (
              that.connected &&
              that.processesToSync &&
              that.processesToSync.length > 0
            ) {
              let count = 0;
              //start a sync for each offline process which is not yet in the list of syncingProcesses
              that.processesToSync
                .filter(
                  (p) =>
                    that.syncingProcesses.find((s) => s !== p.uuid) ===
                      undefined && count++ < 2
                )
                .forEach((e) => that.syncProcess(e));
            }
          } catch (err) {
            that.log(`Error in syncOfflineStoredProcesses: ${err}`);
          }
        }, 5000);
      }
    },
    recoverCashierBook() {
      const ongoingOrders = this.$estore.get("tenant.ongoingOrders", []);
      const ongoingTopup = this.$estore.get("tenant.ongoingTopup");
      const restoredCashierBook = this.$estore.get("tenant.restoreCashierBook"); //separate in case write failed during close
      const restored = this.$estore.get("tenant.restore");

      if (restoredCashierBook) {
        this.cashierBook = restoredCashierBook;
      }

      //TODO: >>>> start remove me after v1.0.6 and compatibility is not required anymore
      const migratedActiveWaiters = [];
      this.$estore.get("tenant.activeWaiters", []).forEach((aw) => {
        if (typeof aw === "string") {
          migratedActiveWaiters.push({
            id: this.$uuid.v4(),
            name: aw,
          });
        }
      });
      if (migratedActiveWaiters.length > 0) {
        this.$estore.set("tenant.activeWaiters", migratedActiveWaiters);

        // replace in cashierbook
        this.cashierBook.forEach((cb) => {
          const waiterId = migratedActiveWaiters[cb.waiterId].id;
          cb.waiterId = waiterId;
          cb.openOrders.forEach((o) => (o.waiterId = waiterId));
        });
        this.$estore.set("tenant.restoreCashierBook", this.cashierBook);

        // replace in restore
        if (restored) {
          restored.waiterId = migratedActiveWaiters[restored.waiterId].id;
          this.$estore.set("tenant.restore", restored);
        }

        // replace in correction
        const correctionData = this.$estore.get("tenant.correction");
        if (correctionData) {
          correctionData.waiterId =
            migratedActiveWaiters[correctionData.waiterId].id;
          this.$estore.set("tenant.correction", correctionData);
        }

        // replace in ongoingOrders
        ongoingOrders.forEach(
          (o) => (o.waiterId = migratedActiveWaiters[o.waiterId].id)
        );
        this.$estore.set("tenant.ongoingOrders", ongoingOrders);

        // replace in ongoingTopup
        if (ongoingTopup) {
          ongoingTopup.waiterId =
            migratedActiveWaiters[ongoingTopup.waiterId].id;
          this.$estore.set("tenant.ongoingTopup", ongoingTopup);
        }
      }
      //TODO: <<<< end remove me after v1.0.6 and compatibility is not required anymore

      //make sure the activeWaiters are in the cashierBook, otherwise create empty tabs
      this.$estore.get("tenant.activeWaiters", []).forEach((aw) => {
        const waiterId = aw.id;
        const entry = this.getWaiterEntry(waiterId);
        if (!entry) {
          this.log(`Creating missing waiter entry ${waiterId}`);
          this.createWaiterEntry(waiterId);
        }
      });

      if (ongoingOrders.length > 0 || ongoingTopup) {
        //restore orders
        ongoingOrders.forEach((o) => {
          let waiterEntry = this.cashierBook.find(
            (item) => item.waiterId === o.waiterId
          );
          if (!waiterEntry) {
            waiterEntry = this.createWaiterEntry(o.waiterId);
          }
          waiterEntry.openOrders.splice(
            waiterEntry.openOrders.findIndex((i) => i.tabId === o.tabId),
            1
          );
          o.process.loading = true;
          o.process.started = false;
          waiterEntry.openOrders.push(o);
          waiterEntry.currentOrderTab = o.tabId;

          this.addUnremovableWaiter(o.waiterId, "pendingOrders");
          //start polling the process in order to get the latest feedback

          this.startPollProcess(
            o.processUuid,
            "order",
            o.process,
            this.orderFeedbackFunction(o)
          );
        });

        //restore topup
        if (ongoingTopup) {
          this.resetTopupData();
          this.topupData.process.loading = true;
          this.topupData.process.started = false;
          this.topupData.paymentOngoing = ongoingTopup.paymentOngoing;
          this.topupData.waiterId = ongoingTopup.waiterId;
          this.topupData.tabId = ongoingTopup.tabId;
          this.topupData.processUuid = ongoingTopup.processUuid;
          this.topupData.amount = ongoingTopup.amount;

          this.startPollProcess(
            this.topupData.processUuid,
            "topup",
            this.topupData.process,
            this.topupFeedbackFunction()
          );
        }
      }

      //set info for totalOrderItems
      this.cashierBook.forEach((c) => {
        c.hasOrderItems = () => {
          return c.openOrders.filter((o) => o.totalItems !== 0).length > 0;
        };
        this.changeWaiterTotalItemsWithNormalState(
          c.waiterId,
          this.calculateTotalWaiterOrderItemsInNormalState(c.waiterId)
        );
      });

      //reset to last waiterId/tabId before app was closed/crashed/killed
      const waiterId = this.correctionMode
        ? this.$estore.get("tenant.correction.waiterId")
        : restored
        ? restored.waiterId
        : undefined;
      if (waiterId) {
        const tabId = this.correctionMode ? -1 : restored ? restored.tabId : 1;
        this.selectWaiter(waiterId);
        this.orderTabSelected(tabId);
      }

      this.topupDialog = ongoingTopup !== undefined;

      //cleanup restore
      this.$estore.delete("tenant.restore");
      this.$estore.delete("tenant.restoreCashierBook"); //separate in case write failed during close
    },
    fetchProductCategories() {
      const url = this.$app.urls.productCategories.replace(
        "{tenantId}",
        this.$estore.get("tenant.tenantId")
      );
      const that = this;
      that.log("About to fetch list of productCategories");
      this.$http({
        url: url,
      }).then(
        (response) => {
          const productCategories = response.data;
          that.log(`Received ${productCategories.length} productCategories`);
          that.$pstore.set(
            "tenant.categories",
            productCategories.sort(
              (c1, c2) => c1.sequenceNumber - c2.sequenceNumber
            )
          );
        },
        (error) => {
          that.log(
            `Cannot fetch productCategories: ${error}. Retrying in 5 secs`
          );
          that.productCategoriesRetryHandler = setTimeout(
            () => that.fetchProductCategories(),
            5000
          );
        }
      );
    },
    fetchProducts() {
      const url = this.$app.urls.products.replace(
        "{tenantId}",
        this.$estore.get("tenant.tenantId")
      );
      const that = this;
      that.log("About to fetch list of products");
      this.$http({
        url: url,
      }).then(
        (response) => {
          const products = response.data;
          that.log(`Received ${products.length} products`);
          that.$pstore.set("tenant.products", products);
        },
        (error) => {
          that.log(`Cannot fetch products: ${error}. Retrying in 5 secs`);
          that.productsRetryHandler = setTimeout(
            () => that.fetchProducts(),
            5000
          );
        }
      );
    },
    orderToWaiter(event) {
      const waiter = event.waiter;
      const order = event.order;

      if (event.localTransfer) {
        this.clearOrder(this.getOrderById(order.instanceId));
      }

      this.selectWaiter(waiter.waiterId);
      if (order.amount < 0) {
        this.prepareCorrectionMode();
      } else {
        this.orderTabSelected(waiter.tabId);
      }
      this.currentOrder.dateTime = moment.utc(order.dateTime).local().toJSON();
      this.currentOrder.processCreated = true;
      this.currentOrder.orderReference = order.orderReference;
      this.currentOrder.processUuid = order.instanceId;
      this.currentOrder.totalItems = order.numberOfProducts;
      this.currentOrder.totalAmount = order.amount;
      this.currentOrder.orderItems = order.items.map((o) => {
        return {
          id: o.productId,
          name: o.productName,
          price: o.unitPrice,
          total: o.quantity,
          totalAmount: o.quantity * o.unitPrice,
        };
      });
      this.currentOrder.lastSentOrderItemsHash = btoa(
        JSON.stringify(this.currentOrder.orderItems)
      );

      if (event.remoteTransfer) {
        this.currentOrder.process.waitingForMatchingCashierId = true;
      }

      if (!(event.localTransfer && order.status === "TO_ENTER")) {
        this.currentOrder.paymentRequested = true;
        this.currentOrder.paymentOngoing = true;
        this.$estore.appendList("tenant.ongoingOrders", this.currentOrder);
        this.addUnremovableWaiter(waiter.waiterId, "pendingOrders");

        this.startPollProcess(
          this.currentOrder.processUuid,
          "order",
          this.currentOrder.process,
          this.orderFeedbackFunction(this.currentOrder)
        );
      }
    },
    orderToNewWaiter(event) {
      this.$refs.waiterSelection.addWaiter();
      this.orderToWaiter({
        waiter: {
          waiterId: this.currentWaiterId,
          tabId: 1,
        },
        order: event.order,
        localTransfer: event.localTransfer,
        remoteTransfer: event.remoteTransfer,
      });
    },
  },
  computed: {
    syncing() {
      return (
        this.connected &&
        this.processesToSync &&
        this.processesToSync.length > 0
      );
    },
  },
  mounted() {
    // ipcRenderer.on("app-close", this.closeApp);

    this.tenantShortName = this.$estore.get(
      "tenant.deviceSettings.tenantShortName"
    )
      ? this.$estore.get("tenant.deviceSettings.tenantShortName")
      : this.$estore.get("tenant.tenantId");
    this.cashierNickname = this.$estore.get("tenant.cashierNickname")
      ? this.$estore.get("tenant.cashierNickname")
      : this.$estore.get("deviceId");
    this.cashierModel = this.$estore.get("tenant.cashierModel");
    this.products = this.$pstore.get("tenant.products", []);
    this.categories = this.$pstore.get("tenant.categories", []);
    this.processesToSync = this.$estore.get("tenant.processesToSync", []);
    this.fetchUnassignedTasksCount();
  },
  beforeDestroy() {
    // ipcRenderer.removeListener("app-close", this.closeApp);
    if (this.syncHandle) {
      clearInterval(this.syncHandle);
    }
    if (this.verifyCashierBookHandle) {
      clearInterval(this.verifyCashierBookHandle);
    }
    this.pollingProcesses.forEach((p) => {
      if (p.timerHandle) {
        clearInterval(p.timerHandle);
      }
    });

    if (this.productsRetryHandler) {
      clearTimeout(this.productsRetryHandler);
    }

    if (this.productCategoriesRetryHandler) {
      clearTimeout(this.productCategoriesRetryHandler);
    }

    if (this.productUpdateTimer) {
      clearTimeout(this.productUpdateTimer);
    }
  },
  created() {
    this.$estore.onDidChange(
      "tenant.deviceSettings.tenantShortName",
      (newValue) =>
        (this.tenantShortName = newValue
          ? newValue
          : this.$estore.get("tenant.tenantId"))
    );
    this.$estore.onDidChange(
      "tenant.cashierNickname",
      (newValue) =>
        (this.cashierNickname = newValue
          ? newValue
          : this.$estore.get("deviceId"))
    );
    this.$estore.onDidChange(
      "tenant.cashierModel",
      (newValue) => (this.cashierModel = newValue)
    );
    this.$pstore.onDidChange(
      "tenant.products",
      (newValue) => (this.products = newValue ? newValue : [])
    );
    this.$pstore.onDidChange(
      "tenant.categories",
      (newValue) => (this.categories = newValue ? newValue : [])
    );
    this.$estore.onDidChange(
      "tenant.processesToSync",
      (newValue) => (this.processesToSync = newValue ? newValue : [])
    );

    this.fetchProductCategories();
    this.fetchProducts();
  },
  directives: {
    ClickOutside,
  },
};
</script>

<style scoped>
.cashierTitle {
  color: #bdbdbd;
}

.waiters {
  max-width: calc(100vw - 64px) !important;
}

.processPanelHeight {
  height: calc(100vh - var(--var-header-height));
}
</style>
