export function createStore (name, defaults) {
  const store = {}
  const listeners = []

  const localData = localStorage.getItem(name)
  if (localData) {
    store.data = JSON.parse(localData)
  } else {
    store.data = defaults || {}
  }

  store.get = (property, defaultValue) => {
    return property.split('.').reduce((i, p) => i ? i[p] : null, store.data) || defaultValue
  }

  store.set = (property, value) => {
    const propertyTree = property.split('.')
    let parentData = store.data

    for (let i = 0; i < propertyTree.length; i++) {
      if (i < propertyTree.length - 1) {
        if (!parentData[propertyTree[i]]) {
          parentData[propertyTree[i]] = {}
        }
        parentData = parentData[propertyTree[i]]
      } else {
        parentData[propertyTree[i]] = value
      }
    }

    localStorage.setItem(name, JSON.stringify(store.data));

    listeners
      .filter(listener => listener.property === property)
      .forEach(listener => listener.callback(value))
  }

  store.delete = property => {
    const propertyTree = property.split('.')
    let parentData = store.data

    for (let i = 0; i < propertyTree.length; i++) {
      if (i < propertyTree.length - 1) {
        parentData = parentData ? parentData[propertyTree[i]] : null
      } else if (parentData) {
        delete parentData[propertyTree[i]]
      }
    }

    localStorage.setItem(name, JSON.stringify(store.data));
  }

  store.onDidChange = (property, callback) => {
    listeners.push({
      property,
      callback
    })
  }

  store.appendList = (listName, valueToAppend) => {
    const list = store.get(listName);
    if (list) {
      list.push(valueToAppend);
      store.set(listName, list);
    } else {
      store.set(listName, [valueToAppend]);
    }
  };

  store.removeFromListAtIndex = (listName, index) => {
    let removed;
    const list = store.get(listName);
    if (list && list.length > 0) {
      if (list.length > 0) {
        removed = list.splice(index, 1);
        store.set(listName, list);
      } else {
        store.set(listName, []);
      }
    }
    return removed && removed.length === 1 ? removed[0] : undefined;
  };

  store.replaceInListAtIndex = (listName, index, data) => {
    const list = store.get(listName);
    if (list && list.length > 0 && index >= 0) {
      list[index] = data;
      store.set(listName, list);
    }
  };

  store.setOrDelete = (key, value) => {
    if (value) {
      store.set(key, value);
    } else {
      store.delete(key);
    }
  };

  store.clear = () => {
    localStorage.setItem(name, null)
  };

  return store;
}
