import Vue from "vue";
import VueI18n from "vue-i18n";
import messagesEn from "@/messages/messages.en"
import messagesNl from "@/messages/messages.nl"
import messagesFr from "@/messages/messages.fr"

Vue.use(VueI18n);

const i18n = {
  en: messagesEn,
  nl: messagesNl,
  fr: messagesFr
};

export default new VueI18n({
  locale: "nl",
  messages: i18n
});
