<template>
  <v-container
    class="d-flex flex-column align-center justify-center"
    fill-height
  >
    <v-progress-circular :size="250" :width="3" indeterminate color="primary"
      ><img :src="require('@/assets/logo_bol.png')" height="250" class="mr-1"
    /></v-progress-circular>
  </v-container>
</template>

<script>
export default {
  name: "loading",
  mounted() {
    setTimeout(() => this.$router.push("/cashier"), 1000); //in order to get the loading visible, because component takes some time to create?
  }
};
</script>

<style scoped>
.content {
  width: 100vw;
}
</style>
