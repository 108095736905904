export default {
  label: {
    activate: "Activate",
    addMember: "Add member",
    all: "All",
    allTabsWithOrders: "Everything, also orders",
    amount: "Amount",
    applicationVersion: "Application versie",
    calculateReturn: "Calculate Return",
    cancel: "Cancel",
    cashier: "Cashier",
    cashierSettings: "Cashier Settings",
    cashReceived: "Cash Received",
    cashToReturn: "Cash To Return",
    check: "Verify",
    close: "Close",
    closeApp: "Afsluiten",
    configuration: "Configuration",
    confirm: "Yes, I'm sure",
    confirm_pickup: "Pick up OK",
    confirm_service: "Delivery OK",
    correction: "Correction",
    currentOrders: "Current Orders",
    delegatePreparation: "Delegate Preparation",
    delegateServiceDelivery: "To Deliver",
    delivered: "Delivered",
    deviceName: "Device Name",
    disabledProduct: "Disabled Product",
    emailAddress: "Email address",
    factoryReset: "Reset",
    forceQuit: "Force Quit",
    freePlan: "Free Plan",
    initiator: "Initiator",
    locale: "Language",
    login: "Inloggen",
    name: "Name",
    newWaiter: "New waiter",
    noFavorite: "No favorite",
    noTabs: "None",
    orderedOnline: "Ordered Online",
    orderReference: "Reference",
    orderShortId: "Number",
    otherCashier: "Other Cashier",
    password: "Password",
    pay: "Pay",
    pickup_ongoing: "Pick up ongoing",
    pincode: "Pincode",
    preparation_ongoing: "Preparation ongoing",
    preparedAndDelivered: "Ready and delivered",
    product: "Product",
    products: "Products",
    proposeAll: "Propose all free scanners for Topupz actions",
    proposeFavorites: "Only those scanners will be proposed when available",
    refund: "Refund",
    register: "Register",
    requestPickup: "To pick up",
    reset: "Destructive Actions",
    save: "Save",
    scanners: "Scanners",
    search: "Search",
    selectProducts: "Select Products",
    serial: "Serial Number",
    service_ongoing: "Service ongoing",
    settings: "Settings",
    status: "Status",
    switchDeliveryTo_pickup: "Change to 'to pick up'",
    switchDeliveryTo_service: "Change to 'to serve'",
    tabsOngoing: "Tabs with pending or feedback",
    tabsWithOrders: "Tabs with orders",
    tenantId: "Club Id",
    tenantShortName: "Shortened club name",
    timestamp: 'Timestamp',
    to_enter: "To Enter",
    to_prepare: "To Prepare",
    to_serve: "To Serve",
    topup: "Topup",
    topupAmount: "Topup card: €{amount}",
    updateLater: "Update Later",
    updateNow: "Update Now",
    username: "Username",
    waiting_for_card_scan: "Card scanning",
    waiting_for_online_payment: "Online payment",
    waiting_for_qr_payment: "QR payment",
    waiting_for_pickup: "To Pick Up",
    whichScanner: "Please select the scanner to fullfil the payment."
  },
  message: {
    activityDuringClose: "O-oh! There was still activity when closing the application",
    allTabsUsed: "All tabs are in use!",
    autoRetry: "We keep trying...",
    canceled_by_cashier: "Canceled by cashier",
    canceled_by_user: "Canceled by user",
    cancelFailed: "Cancel failed, please try again",
    card_scan_timeout: "Card Scan Timeout",
    changeSubscription: "You can change your subscription plan in Topupz for clubs.",
    chooseRestoreMethod: "Which tabs should be restored?",
    confirmClosePendingProcesses: "There are ongoing transactions or unconfirmed feedback!",
    confirmTransfer: "Are you sure you want to take over the order here? This order will disappear on the existing tab of the current waiter.",
    connectionIssue: "Connection issue",
    disabledByTenant: "Option inactive",
    disabled_card: "Disabled Card",
    disabledProduct: "The selected product is disabled so it cannot be sold. In order to activate it again, click the button below",
    errorTryAgain: "Error! Please try again",
    insufficient_funds: "Insufficient Funds",
    lastTimestampBeforeClose: "Last transaction started at {timestamp}.",
    limitedOfflineActions: "The cash register is offline, only limited actions are possible",
    membershipRequestAlreadySent: "A membership request has already been sent",
    membershipRequestError: "An error occurred while sending the membership request",
    membershipRequestForMember: "There is already a member registered with the same email address",
    membershipRequestSent: "We sent a membership request",
    noCurrentOrders: "No current orders",
    noProducts: "Waiting for Products",
    noProductsInCategory: "No products in this category. Use Topupz for clubs to assign them.",
    noQrCodePayment: "No linked QR",
    noScanners: "There are currently no scanners. If there should, check the connection status",
    noScannersConfigured: "No scanners",
    offlineOrderCancel: "The order will be canceled once the connection is up again",
    offlineOrderProcessing: "Order saved offline for postponed processing...",
    offlineTopupCancel: "The topup will be canceled once the connection is up again",
    online_payment_canceled: "The online payment was canceled",
    online_payment_failed: "The online payment has failed",
    online_payment_timeout: "The online payment has timed out",
    onlyOffline: "Please perform only CASH payments (offline mode)",
    orderIsBeingProcessed: "Order is already being processed",
    orderIsNotTransferable: "The order can't be transferred because the corresponding cash register is offline",
    orderOk: "Order has been processed and payment OK",
    performOfflineCash: "Please ask the client for a cash payment",
    pickup_ongoing: "Please check the number or the reference before the customer picks up the order",
    preparation_ongoing: "Please prepare the order",
    processedByOtherCashier: "The order should not be processed on this cashier anymore",
    productUpdate: "Hooray, a product notification! Do you want to update them right away?",
    registration1: "Please register the cashier by logging in once as administrator.",
    registration2: "Afterwards you can configure the cashier name, other settings are available in Topupz for clubs.",
    registration3: "The default PIN code is '1111'.",
    remainingFreeOrders: "All orders in the free subscription have been executed for today and will be available again tomorrow. | There is 1 more order available for today in the free subscription. | There are {remainingFreeOrders} more orders available for today in the free subscription.",
    removeWaiter: "Remove waiter",
    required: "Required field",
    resetExplanation: "All settings will be lost, even synchronisation and transaction feedbacks!",
    restoreNbrNotStarted: "There were {nbrNotStarted} tabs with entered orders.",
    restoreNbrNotStartedOne: "There was 1 tab with an entered order.",
    restoreNbrOngoing: "{nbrOngoing} ongoing transactions or unconfirmed feedbacks.",
    restoreNbrOngoingOne: "1 ongoing transaction unconfirmed feedback.",
    restoreTopupBusy: "Topup card was pending",
    retry: "Please try again",
    retryOrReturnCash: "Please try again or return the money to the client",
    returnCash: "Please return the money to the client",
    scanner_busy: "Scanner is Busy",
    scannerBusy: "Scanner busy",
    scanner_gone: "Scanner is Gone",
    selectWaiterToContinueOrder: "Select the waiter (tab) to continue processing the order on this cash register",
    service_ongoing: "Please bring the order to the customer",
    to_prepare: "Please prepare the order or delegate it",
    topupOk: "Topup has been processed and payment OK",
    unknown: "Unknown Error",
    unknown_card: "Unknown Card",
    unremovableWaiter: {
      error: "Waiter cannot be removed",
      reason_pendingOrders: "There are still pending orders or non confirmed feedback"
    },
    waiting_for_card_scan: "Ask client to scan card",
    waiting_for_online_payment: "Waiting for online payment",
    waiting_for_qr_payment: "Waiting for QR payment",
    waitingForMatchingCashierId: "Please wait until the order has been transferred to this cash register",
    warning: "Warning!"
  }
}
