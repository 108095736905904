<template>
  <div class="d-flex flex-column pa-1 pl-0 fill-height">
    <v-card
      v-if="process.lastPayload && process.lastPayload.orderReference"
      flat
      class="d-flex flex-column justify-center mb-1"
    >
      <div class="d-flex flex-column text-left pa-2 subtitle-1">
        <div class="d-flex">
          <div class="font-weight-bold pr-1">{{ $t("label.timestamp") }}:</div>
          <div>{{ payloadDateTime }}</div>
        </div>
        <div v-if="process.lastPayload.initiator !== 'CASHIER'" class="d-flex">
          <div class="font-weight-bold pr-1">
            {{ $t("label.orderReference") }}:
          </div>
          <div>{{ process.lastPayload.orderReference }}</div>
        </div>
        <div class="d-flex">
          <div class="font-weight-bold pr-1">
            {{ $t("label.orderShortId") }}:
          </div>
          <div>{{ process.lastPayload.instanceId.substring(0, 7) }}</div>
        </div>
      </div>
    </v-card>
    <v-card flat class="d-flex flex-column justify-center mb-1 fill-height">
      <div class="d-flex flex-column text-center mb-6 title">
        {{ title }}
      </div>
      <div class="d-flex justify-center">
        <v-progress-circular
          v-if="process.loading === true"
          :size="150"
          :width="2"
          indeterminate
          color="primary"
        ></v-progress-circular>
        <v-icon v-else :color="iconColor" size="150">{{ iconName }}</v-icon>
      </div>
      <div
        v-if="
          processName === 'order' &&
          process.lastPayload &&
          !process.waitingForMatchingCashierId &&
          $estore.get('deviceId') !== process.lastPayload.cashierId
        "
        class="d-flex flex-column text-center mt-6 title process-message-1"
      >
        {{ $t("message.processedByOtherCashier") }}
      </div>
      <div
        v-else-if="process.message1"
        class="d-flex flex-column text-center mt-6 title process-message-1"
      >
        {{ process.message1 }}
      </div>
      <div v-else class="placeholder d-flex flex-column text-center mt-6 title">
        hidden placeholder
      </div>
      <div
        v-if="
          !correctionMode &&
          process.lastPayload &&
          $estore.get('deviceId') === process.lastPayload.cashierId &&
          process.message2
        "
        class="d-flex flex-column text-center mt-0 title process-message-2"
      >
        {{ process.message2 }}
      </div>
      <div v-else class="placeholder d-flex flex-column text-center mt-2 title">
        hidden placeholder
      </div>
      <div
        v-if="process.success === true && showRemainingFreeOrders"
        class="d-flex flex-column text-center mt-0 title free-order-message"
      >
        {{
          $tc("message.remainingFreeOrders", remainingFreeOrders, {
            remainingFreeOrders,
          })
        }}
        <br />
        {{ $t("message.changeSubscription") }}
      </div>
    </v-card>
    <v-btn
      v-if="
        processName === 'order' &&
        !correctionMode &&
        !process.waitingForMatchingCashierId &&
        process.lastPayload &&
        $estore.get('deviceId') !== process.lastPayload.cashierId
      "
      block
      class="process-button"
      color="primary"
      :class="className('actionButton')"
      @click="$emit('done')"
      >OK</v-btn
    >
    <v-btn
      v-else-if="
        processName === 'order' &&
        process.waitingForMatchingCashierId &&
        process.lastPayload &&
        $estore.get('deviceId') !== process.lastPayload.cashierId
      "
      block
      class="process-button"
      color="red darken-3"
      :class="className('actionButton')"
      @click="$emit('done')"
      >{{ $t("label.cancel") }}</v-btn
    >
    <div
      v-else-if="
        processName === 'order' &&
        !correctionMode &&
        process.lastPayload &&
        process.lastPayload.status === 'PREPARATION_ONGOING' &&
        !process.lastPayload.preparationDelegated &&
        process.lastPayload.initiator !== 'CUSTOMER'
      "
      class="d-flex"
    >
      <v-btn
        class="process-button flex-grow-1"
        color="primary"
        :class="className('actionButton')"
        :disabled="
          !connected || (process.loading === true && process.started !== true)
        "
        @click="$emit('delegatePreparation')"
        >{{ $t("label.delegatePreparation") }}</v-btn
      >
      <v-btn
        class="process-button flex-grow-1 ml-1"
        color="primary"
        :class="className('actionButton')"
        :disabled="process.loading === true && process.started !== true"
        @click="$emit('preparedAndDelivered')"
        >{{ $t("label.preparedAndDelivered") }}</v-btn
      >
    </div>
    <div
      v-else-if="
        processName === 'order' &&
        !correctionMode &&
        process.lastPayload &&
        process.lastPayload.status === 'PREPARATION_ONGOING'
      "
      class="d-flex"
    >
      <v-btn
        class="process-button"
        color="primary"
        :class="className('actionButton')"
        :disabled="
          !connected || (process.loading === true && process.started !== true)
        "
        @click="$emit('delegatePreparation')"
        ><v-icon :large="this.$vuetify.breakpoint.width > 1200"
          >mdi-arrow-left-circle</v-icon
        ></v-btn
      >
      <v-btn
        class="process-button flex-grow-1 ml-1"
        color="primary"
        :class="className('actionButton')"
        :disabled="
          !connected || (process.loading === true && process.started !== true)
        "
        @click="$emit('preparedAndRequestPickup')"
        >{{ $t("label.requestPickup") }}</v-btn
      >
      <v-btn
        class="process-button flex-grow-1 ml-1"
        color="primary"
        :class="className('actionButton')"
        :disabled="
          !connected || (process.loading === true && process.started !== true)
        "
        @click="$emit('preparedAndRequestService')"
        >{{ $t("label.delegateServiceDelivery") }}</v-btn
      >
      <v-btn
        class="process-button flex-grow-1 ml-1"
        color="primary"
        :class="className('actionButton')"
        :disabled="process.loading === true && process.started !== true"
        @click="$emit('preparedAndDelivered')"
        >{{ $t("label.delivered") }}</v-btn
      >
    </div>
    <div
      v-else-if="
        processName === 'order' &&
        !correctionMode &&
        process.lastPayload &&
        (process.lastPayload.status === 'SERVICE_ONGOING' ||
          process.lastPayload.status === 'PICKUP_ONGOING')
      "
      class="d-flex"
    >
      <v-btn
        class="process-button"
        color="primary"
        :class="className('actionButton')"
        :disabled="
          !connected || (process.loading === true && process.started !== true)
        "
        @click="$emit('delegateDelivery')"
        ><v-icon :large="this.$vuetify.breakpoint.width > 1200"
          >mdi-arrow-left-circle</v-icon
        ></v-btn
      >
      <v-btn
        class="process-button ml-1"
        color="primary"
        :class="className('actionButton')"
        :disabled="process.loading === true && process.started !== true"
        @click="
          $emit(
            'switchDeliveryType',
            process.lastPayload.deliveryType === 'PICKUP' ? 'SERVICE' : 'PICKUP'
          )
        "
        >{{
          $t(
            `label.switchDeliveryTo_${
              process.lastPayload.deliveryType === "PICKUP"
                ? "service"
                : "pickup"
            }`
          )
        }}</v-btn
      >
      <v-btn
        class="process-button flex-grow-1 ml-1"
        color="primary"
        :class="className('actionButton')"
        :disabled="process.loading === true && process.started !== true"
        @click="$emit('delivered')"
        >{{
          $t(`label.confirm_${process.lastPayload.deliveryType.toLowerCase()}`)
        }}</v-btn
      >
    </div>
    <v-btn
        v-else-if="processName === 'order' &&
          correctionMode &&
          process.lastPayload &&
          process.lastPayload.status === 'PREPARATION_ONGOING' &&
          !process.lastPayload.preparationDelegated &&
          process.lastPayload.initiator !== 'CUSTOMER'"
        block
        class="process-button"
        :color="process.loading === true ? 'red darken-3' : 'primary'"
        :class="className('actionButton')"
        :disabled="process.loading === true && process.started !== true"
        @click="$emit('preparedAndDelivered')"
    >OK</v-btn>
    <v-btn
      v-else
      block
      class="process-button"
      :color="process.loading === true ? 'red darken-3' : 'primary'"
      :class="className('actionButton')"
      :disabled="process.loading === true && process.started !== true"
      @click="
        process.loading === true
          ? $emit('cancelPayment')
          : process.success === true
            ? $emit('done')
            : $emit('retry')
      "
      >{{ process.loading === true ? $t("label.cancel") : "OK" }}</v-btn
    >
  </div>
</template>

<script>
import moment from "moment-timezone";

export default {
  name: "WaiterProcessStatus",
  props: {
    processName: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    process: {
      type: Object,
      required: true,
    },
    showRemainingFreeOrders: {
      type: Boolean,
      required: true,
    },
    connected: {
      type: Boolean,
      required: true,
    },
    correctionMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    remainingFreeOrders: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    cancel() {
      this.$emit("cancelPayment");
    },
    className(name) {
      return `${name}-${this.$vuetify.breakpoint.name} ${
        this.$vuetify.breakpoint.mdAndUp ? "title" : ""
      } ${this.process.loading === false ? "black--text" : ""}`;
    },
  },
  computed: {
    iconColor() {
      if (
        this.processName === "order" &&
        this.process.lastPayload &&
        this.$estore.get("deviceId") !== this.process.lastPayload.cashierId
      ) {
        return "red";
      }

      let color =
        this.process.success === true ? "light-green accent-3" : "red";
      if (this.process.success && this.process.lastPayload) {
        if (
          (this.process.lastPayload.status === "PREPARATION_ONGOING" &&
            (this.process.lastPayload.preparationDelegated ||
              this.process.lastPayload.initiator === "CUSTOMER")) ||
          this.process.lastPayload.status === "SERVICE_ONGOING" ||
          this.process.lastPayload.status === "PICKUP_ONGOING"
        ) {
          color = "grey darken-1";
        }
      }
      return color;
    },
    iconName() {
      if (
        this.processName === "order" &&
        this.process.lastPayload &&
        this.$estore.get("deviceId") !== this.process.lastPayload.cashierId
      ) {
        return "mdi-transfer";
      }

      let name =
        this.process.success === true
          ? "mdi-checkbox-marked-circle"
          : "mdi-close-circle";
      if (this.process.success && this.process.lastPayload) {
        if (
          this.process.lastPayload.status === "PREPARATION_ONGOING" &&
          (this.process.lastPayload.preparationDelegated ||
            this.process.lastPayload.initiator === "CUSTOMER")
        ) {
          name = "mdi-kettle-steam";
        } else {
          switch (this.process.lastPayload.status) {
            case "PICKUP_ONGOING":
              name = "mdi-hand-heart";
              break;
            case "SERVICE_ONGOING":
              name = "mdi-roller-skate";
              break;
            default:
              break;
          }
        }
      }
      return name;
    },
    payloadDateTime() {
      return this.process.lastPayload
        ? moment
            .utc(this.process.lastPayload.dateTime)
            .local()
            .format("DD/MM/YYYY HH:mm:ss")
        : "";
    },
  },
};
</script>

<style scoped>
.placeholder {
  visibility: hidden;
}

.actionButton-sm {
  min-height: calc(var(--var-order-btn-group-height) - 4px);
}

.actionButton-md,
.actionButton-lg,
.actionButton-xl {
  min-height: calc(1.5 * var(--var-order-btn-group-height) - 4px);
}
</style>
