var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column pa-1 pl-0 fill-height"},[(_vm.process.lastPayload && _vm.process.lastPayload.orderReference)?_c('v-card',{staticClass:"d-flex flex-column justify-center mb-1",attrs:{"flat":""}},[_c('div',{staticClass:"d-flex flex-column text-left pa-2 subtitle-1"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"font-weight-bold pr-1"},[_vm._v(_vm._s(_vm.$t("label.timestamp"))+":")]),_c('div',[_vm._v(_vm._s(_vm.payloadDateTime))])]),(_vm.process.lastPayload.initiator !== 'CASHIER')?_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"font-weight-bold pr-1"},[_vm._v(" "+_vm._s(_vm.$t("label.orderReference"))+": ")]),_c('div',[_vm._v(_vm._s(_vm.process.lastPayload.orderReference))])]):_vm._e(),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"font-weight-bold pr-1"},[_vm._v(" "+_vm._s(_vm.$t("label.orderShortId"))+": ")]),_c('div',[_vm._v(_vm._s(_vm.process.lastPayload.instanceId.substring(0, 7)))])])])]):_vm._e(),_c('v-card',{staticClass:"d-flex flex-column justify-center mb-1 fill-height",attrs:{"flat":""}},[_c('div',{staticClass:"d-flex flex-column text-center mb-6 title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',{staticClass:"d-flex justify-center"},[(_vm.process.loading === true)?_c('v-progress-circular',{attrs:{"size":150,"width":2,"indeterminate":"","color":"primary"}}):_c('v-icon',{attrs:{"color":_vm.iconColor,"size":"150"}},[_vm._v(_vm._s(_vm.iconName))])],1),(
        _vm.processName === 'order' &&
        _vm.process.lastPayload &&
        !_vm.process.waitingForMatchingCashierId &&
        _vm.$estore.get('deviceId') !== _vm.process.lastPayload.cashierId
      )?_c('div',{staticClass:"d-flex flex-column text-center mt-6 title process-message-1"},[_vm._v(" "+_vm._s(_vm.$t("message.processedByOtherCashier"))+" ")]):(_vm.process.message1)?_c('div',{staticClass:"d-flex flex-column text-center mt-6 title process-message-1"},[_vm._v(" "+_vm._s(_vm.process.message1)+" ")]):_c('div',{staticClass:"placeholder d-flex flex-column text-center mt-6 title"},[_vm._v(" hidden placeholder ")]),(
        !_vm.correctionMode &&
        _vm.process.lastPayload &&
        _vm.$estore.get('deviceId') === _vm.process.lastPayload.cashierId &&
        _vm.process.message2
      )?_c('div',{staticClass:"d-flex flex-column text-center mt-0 title process-message-2"},[_vm._v(" "+_vm._s(_vm.process.message2)+" ")]):_c('div',{staticClass:"placeholder d-flex flex-column text-center mt-2 title"},[_vm._v(" hidden placeholder ")]),(_vm.process.success === true && _vm.showRemainingFreeOrders)?_c('div',{staticClass:"d-flex flex-column text-center mt-0 title free-order-message"},[_vm._v(" "+_vm._s(_vm.$tc("message.remainingFreeOrders", _vm.remainingFreeOrders, { remainingFreeOrders: _vm.remainingFreeOrders, }))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$t("message.changeSubscription"))+" ")]):_vm._e()]),(
      _vm.processName === 'order' &&
      !_vm.correctionMode &&
      !_vm.process.waitingForMatchingCashierId &&
      _vm.process.lastPayload &&
      _vm.$estore.get('deviceId') !== _vm.process.lastPayload.cashierId
    )?_c('v-btn',{staticClass:"process-button",class:_vm.className('actionButton'),attrs:{"block":"","color":"primary"},on:{"click":function($event){return _vm.$emit('done')}}},[_vm._v("OK")]):(
      _vm.processName === 'order' &&
      _vm.process.waitingForMatchingCashierId &&
      _vm.process.lastPayload &&
      _vm.$estore.get('deviceId') !== _vm.process.lastPayload.cashierId
    )?_c('v-btn',{staticClass:"process-button",class:_vm.className('actionButton'),attrs:{"block":"","color":"red darken-3"},on:{"click":function($event){return _vm.$emit('done')}}},[_vm._v(_vm._s(_vm.$t("label.cancel")))]):(
      _vm.processName === 'order' &&
      !_vm.correctionMode &&
      _vm.process.lastPayload &&
      _vm.process.lastPayload.status === 'PREPARATION_ONGOING' &&
      !_vm.process.lastPayload.preparationDelegated &&
      _vm.process.lastPayload.initiator !== 'CUSTOMER'
    )?_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"process-button flex-grow-1",class:_vm.className('actionButton'),attrs:{"color":"primary","disabled":!_vm.connected || (_vm.process.loading === true && _vm.process.started !== true)},on:{"click":function($event){return _vm.$emit('delegatePreparation')}}},[_vm._v(_vm._s(_vm.$t("label.delegatePreparation")))]),_c('v-btn',{staticClass:"process-button flex-grow-1 ml-1",class:_vm.className('actionButton'),attrs:{"color":"primary","disabled":_vm.process.loading === true && _vm.process.started !== true},on:{"click":function($event){return _vm.$emit('preparedAndDelivered')}}},[_vm._v(_vm._s(_vm.$t("label.preparedAndDelivered")))])],1):(
      _vm.processName === 'order' &&
      !_vm.correctionMode &&
      _vm.process.lastPayload &&
      _vm.process.lastPayload.status === 'PREPARATION_ONGOING'
    )?_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"process-button",class:_vm.className('actionButton'),attrs:{"color":"primary","disabled":!_vm.connected || (_vm.process.loading === true && _vm.process.started !== true)},on:{"click":function($event){return _vm.$emit('delegatePreparation')}}},[_c('v-icon',{attrs:{"large":this.$vuetify.breakpoint.width > 1200}},[_vm._v("mdi-arrow-left-circle")])],1),_c('v-btn',{staticClass:"process-button flex-grow-1 ml-1",class:_vm.className('actionButton'),attrs:{"color":"primary","disabled":!_vm.connected || (_vm.process.loading === true && _vm.process.started !== true)},on:{"click":function($event){return _vm.$emit('preparedAndRequestPickup')}}},[_vm._v(_vm._s(_vm.$t("label.requestPickup")))]),_c('v-btn',{staticClass:"process-button flex-grow-1 ml-1",class:_vm.className('actionButton'),attrs:{"color":"primary","disabled":!_vm.connected || (_vm.process.loading === true && _vm.process.started !== true)},on:{"click":function($event){return _vm.$emit('preparedAndRequestService')}}},[_vm._v(_vm._s(_vm.$t("label.delegateServiceDelivery")))]),_c('v-btn',{staticClass:"process-button flex-grow-1 ml-1",class:_vm.className('actionButton'),attrs:{"color":"primary","disabled":_vm.process.loading === true && _vm.process.started !== true},on:{"click":function($event){return _vm.$emit('preparedAndDelivered')}}},[_vm._v(_vm._s(_vm.$t("label.delivered")))])],1):(
      _vm.processName === 'order' &&
      !_vm.correctionMode &&
      _vm.process.lastPayload &&
      (_vm.process.lastPayload.status === 'SERVICE_ONGOING' ||
        _vm.process.lastPayload.status === 'PICKUP_ONGOING')
    )?_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"process-button",class:_vm.className('actionButton'),attrs:{"color":"primary","disabled":!_vm.connected || (_vm.process.loading === true && _vm.process.started !== true)},on:{"click":function($event){return _vm.$emit('delegateDelivery')}}},[_c('v-icon',{attrs:{"large":this.$vuetify.breakpoint.width > 1200}},[_vm._v("mdi-arrow-left-circle")])],1),_c('v-btn',{staticClass:"process-button ml-1",class:_vm.className('actionButton'),attrs:{"color":"primary","disabled":_vm.process.loading === true && _vm.process.started !== true},on:{"click":function($event){return _vm.$emit(
          'switchDeliveryType',
          _vm.process.lastPayload.deliveryType === 'PICKUP' ? 'SERVICE' : 'PICKUP'
        )}}},[_vm._v(_vm._s(_vm.$t( ("label.switchDeliveryTo_" + (_vm.process.lastPayload.deliveryType === "PICKUP" ? "service" : "pickup")) )))]),_c('v-btn',{staticClass:"process-button flex-grow-1 ml-1",class:_vm.className('actionButton'),attrs:{"color":"primary","disabled":_vm.process.loading === true && _vm.process.started !== true},on:{"click":function($event){return _vm.$emit('delivered')}}},[_vm._v(_vm._s(_vm.$t(("label.confirm_" + (_vm.process.lastPayload.deliveryType.toLowerCase())))))])],1):(_vm.processName === 'order' &&
        _vm.correctionMode &&
        _vm.process.lastPayload &&
        _vm.process.lastPayload.status === 'PREPARATION_ONGOING' &&
        !_vm.process.lastPayload.preparationDelegated &&
        _vm.process.lastPayload.initiator !== 'CUSTOMER')?_c('v-btn',{staticClass:"process-button",class:_vm.className('actionButton'),attrs:{"block":"","color":_vm.process.loading === true ? 'red darken-3' : 'primary',"disabled":_vm.process.loading === true && _vm.process.started !== true},on:{"click":function($event){return _vm.$emit('preparedAndDelivered')}}},[_vm._v("OK")]):_c('v-btn',{staticClass:"process-button",class:_vm.className('actionButton'),attrs:{"block":"","color":_vm.process.loading === true ? 'red darken-3' : 'primary',"disabled":_vm.process.loading === true && _vm.process.started !== true},on:{"click":function($event){_vm.process.loading === true
        ? _vm.$emit('cancelPayment')
        : _vm.process.success === true
          ? _vm.$emit('done')
          : _vm.$emit('retry')}}},[_vm._v(_vm._s(_vm.process.loading === true ? _vm.$t("label.cancel") : "OK"))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }