<template>
  <v-bottom-sheet :value="visible" persistent>
    <v-sheet class="addMemberDialog text-center">
      <div class="d-flex flex-column justify-center">
        <v-text-field
            class="userEmail centered-input headline pa-3 pl-5 pr-5"
            color="primary"
            v-model="userEmail"
            :full-width="true"
            outlined
            :hide-details="true"
            :autofocus="true"
            :placeholder="this.$t('label.emailAddress')"
        />

        <div
            class="message d-flex flex-column text-center title"
            :style="{'min-height': '2em'}"
            :class="{
              'text-primary': messageClass === 'primary',
              'text-warning': messageClass === 'warning',
              'text-error': messageClass === 'error'
            }"
        >
          {{ message }}
        </div>

        <div class="flex flex-row">
          <v-btn
              color="primary"
              class="addMemberButton black--text ma-2"
              @click="addUser"
              :disabled="addMemberDisabled">
            {{ $t("label.addMember") }}
          </v-btn>
          <v-btn
              color="error"
              class="cancelButton black--text ma-2"
              @click="close">
            {{ $t("label.cancel") }}
          </v-btn>
        </div>
      </div>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    connected: {
      type: Boolean,
      required: true,
    },
  },
  data () {
    return {
      userEmail: '',
      message: '',
      messageClass: '',
    };
  },
  methods: {
    close () {
      this.userEmail = ''
      this.message = ''
      this.messageClass = ''
      this.$emit("close");
    },

    async addUser () {
      let url = this.$app.urls.membershipRequest
      url = url.replace("{tenantId}", this.$estore.get("tenant.tenantId"))
      url = url.replace("{membershipRequestId}", this.$uuid.v4());

      const response = await this.$http({
        url: url,
        method: "put",
        validateStatus: () => true,
        data: {
          userEmail: this.userEmail,
        },
      })
      console.log('response', response)

      if (response.status === 200) {
        this.message = this.$t("message.membershipRequestSent")
        this.messageClass = 'primary'
        this.userEmail = ''
      } else {
        const exceptionCode = response.headers['x-exception-code']
        if (exceptionCode === 'EXISTING_REQUEST') {
          this.message = this.$t("message.membershipRequestAlreadySent")
          this.messageClass = 'warning'
        } else if (exceptionCode === 'EXISTING_MEMBER') {
          this.message = this.$t("message.membershipRequestForMember")
          this.messageClass = 'warning'
        } else {
          this.message = this.$t("message.membershipRequestError")
          this.messageClass = 'error'
        }
      }
    }
  },
  computed: {
    addMemberDisabled () {
      let validEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.userEmail || '');
      return !this.connected || !validEmail
    }
  }
};
</script>