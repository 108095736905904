<template>
  <div>
    <v-list subheader two-line flat>
      <v-subheader>{{ $t("label.scanners") }}</v-subheader>
      <v-alert
        v-if="!scanners || scanners.length === 0"
        dense
        text
        type="info"
        color="primary"
      >
        {{ $t("message.noScanners") }}
      </v-alert>
      <v-list-item-group v-model="scannerMatters" mandatory>
        <v-list-item>
          <template v-slot:default="{ active, toggle }">
            <v-list-item-action>
              <v-checkbox
                :value="active"
                color="primary"
                @click="toggle"
              ></v-checkbox>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{
                $t("label.noFavorite")
              }}</v-list-item-title>
              <v-list-item-subtitle>{{
                $t("label.proposeAll")
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-list-item>
        <v-divider inset></v-divider>
        <v-list-item :disabled="!scanners || scanners.length === 0">
          <template v-slot:default>
            <v-list-item-action> </v-list-item-action>

            <v-list-item-content>
              <v-list-item-subtitle>{{
                $t("label.proposeFavorites")
              }}</v-list-item-subtitle>
              <v-select
                v-model="favoriteScanners"
                :items="scanners"
                :item-text="o => (o.name ? o.name : o.id)"
                :item-value="o => o.id"
                :disabled="!scanners || scanners.length === 0"
                chips
                deletable-chips
                multiple
                outlined
                clearable
                @blur="checkSelection()"
              ></v-select>
            </v-list-item-content>
          </template>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
export default {
  name: "Scanners",
  props: {
    scanners: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      favoriteScanners: this.$estore.get("tenant.favoriteScanners", []),
      scannerMatters:
        this.$estore.get("tenant.favoriteScanners", []).length === 0 ? 0 : 1
    };
  },
  methods: {
    checkSelection() {
      if (this.favoriteScanners.length === 0) this.scannerMatters = 0;
    }
  },
  created() {
    this.$estore.onDidChange("tenant.favoriteScanners", () =>
      this.$emit("favoriteScannersUpdated")
    );
  },
  watch: {
    scannerMatters(value) {
      if (value === 0) {
        this.favoriteScanners = [];
        this.$estore.set("tenant.favoriteScanners", []);
      }
    },
    favoriteScanners(value) {
      if (value.length === 0) {
        this.scannerMatters = 0;
      } else {
        this.$estore.set("tenant.favoriteScanners", value);
      }
    }
  }
};
</script>

<style scoped></style>
