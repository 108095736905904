<template>
  <div v-if="false"></div>
</template>

<script>
import { EventSourcePolyfill } from "event-source-polyfill";

export default {
  name: "DeviceCommands",
  data() {
    return {
      commandsSubscription: null,
      commandsReconnectOnCloseWanted: true,
      commandsLastErrorStatusCode: 0,
      reconnectHandler: undefined,
      onlineHandler: undefined,
    };
  },
  methods: {
    log(msg) {
      console.log(msg);
      //TODO: add to this.$app.messages the last 50 messages
    },
    deviceOnline() {
      let url = this.$app.urls.deviceOnline.replace(
        "{tenantId}",
        this.$estore.get("tenant.tenantId")
      );
      url = url.replace("{deviceId}", this.$estore.get("deviceId"));
      const that = this;
      that.log("About to post device online");
      this.$http({
        url: url,
        method: "post",
        data: {
          applicationVersion: process.env.VUE_APP_VERSION,
        },
      }).then(
        () => {},
        (error) => {
          that.log(`Cannot post device online: ${error}`);
        }
      );
    },
    async initCommandsSubscription() {
      console.log("starting connection");
      this.commandsReconnectOnCloseWanted = true;
      let url = this.$app.urls.deviceCommands;
      url = url.replace("{deviceId}", this.$estore.get("deviceId"));
      url = url.replace("{tenantId}", this.$estore.get("tenant.tenantId"));
      const token = await this.$security.getAccessToken();
      if (!token || this.commandsLastErrorStatusCode === 403) {
        this.log("Backoff 5s before trying to fetch token again...");
        setTimeout(() => {
          this.commandsLastErrorStatusCode = 0;
          this.initCommandsSubscription();
        }, 5000);
        return;
      }
      this.commandsSubscription = new EventSourcePolyfill(url, {
        heartbeatTimeout: this.$estore.get("heartbeatTimeout"),
        headers: { Authorization: `Bearer ${token}` },
      });
      this.commandsSubscription.onopen = () => this.onSubscriptionOpen();
      this.commandsSubscription.onerror = (data) =>
        this.onSubscriptionError(data);
      this.commandsSubscription.onmessage = (data) =>
        this.handleSubscriptionMessage(data.data);
      this.commandsSubscription.addEventListener("scanners", (data) =>
        this.handleUpdate("scanners", data.data)
      );
      this.commandsSubscription.addEventListener("device", (data) =>
        this.handleUpdate("device", data.data)
      );
      this.commandsSubscription.addEventListener("settings", (data) =>
        this.handleUpdate("settings", data.data)
      );
      this.commandsSubscription.addEventListener("products", (data) =>
        this.handleUpdate("products", data.data)
      );
      this.commandsSubscription.addEventListener("product-categories", (data) =>
        this.handleUpdate("productCategories", data.data)
      );
      this.commandsSubscription.addEventListener("order-task-update", () =>
        this.handleUpdate("orderTask")
      );
      this.commandsSubscription.addEventListener(
        "order-transfer-request",
        (data) => this.handleUpdate("orderTransferRequest", data.data)
      );
      this.commandsSubscription.addEventListener("factory-reset", () =>
        this.$emit("factoryReset", "")
      );
      this.commandsSubscription.addEventListener("soft-reset", () =>
        this.$emit("softReset", "")
      );
    },
    onSubscriptionOpen() {
      this.log(
        `Device commands socket opened and announcing periodically sending online status.`
      );
      this.deviceOnline();
      this.onlineHandler = setInterval(() => {
        this.deviceOnline();
      }, 30000);
      this.commandsLastErrorStatusCode = 0;
    },
    onSubscriptionError(data) {
      // const token = data.target.headers
      //   ? data.target.headers.Authorization
      //   : undefined;
      data.target.headers = undefined;
      this.log(
        `Device commands socket encouterd an error: ${JSON.stringify(data)}`
      );
      this.commandsLastErrorStatusCode = data.status;
      // //if error because of token expired, reconnect
      // if (!isGivenTokenStillValid(token)) {
      //   this.reconnectCommandsSubscription();
      // }
      this.reconnectCommandsSubscription(); //always reconnect to avoid problems
    },
    handleSubscriptionMessage(msg) {
      this.log(`Received msg from device-manager: ${msg}`);
    },
    handleUpdate(type, msg) {
      this.log(`Received ${type} msg from device-manager: ${msg}`);
      const data = msg ? JSON.parse(msg) : undefined;
      this.$emit(`${type}Update`, data);
    },
    closeCommandsSubscription(reconnectWanted = false) {
      if (this.onlineHandler) {
        clearInterval(this.onlineHandler);
      }
      this.commandsReconnectOnCloseWanted = reconnectWanted;
      if (this.commandsSubscription) {
        try {
          this.commandsSubscription.close();
          if (!this.commandsReconnectOnCloseWanted) {
            this.log(`Device commands socket closed.`);
          }
        } catch (error) {
          //ignore
        }
      }
    },
    reconnectCommandsSubscription() {
      this.closeCommandsSubscription(true);
    },
  },
  mounted() {
    this.$emit("isConnected", false);
    this.initCommandsSubscription();
  },
  beforeDestroy() {
    this.closeCommandsSubscription(false);
    if (this.reconnectHandler) clearTimeout(this.reconnectHandler);
  },
  watch: {
    commandsSubscription: {
      deep: true,
      handler(value) {
        if (value) {
          this.$emit("isConnected", value.readyState === 1);
          if (this.commandsReconnectOnCloseWanted && value.readyState === 2) {
            try {
              if (this.onlineHandler) {
                clearInterval(this.onlineHandler);
              }
              this.commandsSubscription.close();
            } catch (error) {
              //ignore
            }
            this.$security.access_token = undefined; //required as user could be disabled in the mean time or session is not active anymore
            this.reconnectHandler = setTimeout(() => {
              this.initCommandsSubscription(); // required as http 500 will close the socket without reconnecting
              this.reconnectHandler = undefined;
            }, 5000);
          }
        } else {
          this.$emit("isConnected", false);
        }
      },
    },
  },
};
</script>

<style scoped></style>
