var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column pa-1 pl-0"},[_c('v-card',{attrs:{"elevation":"0"}},[_c('div',{staticClass:"d-flex justify-center align-center display-4 font-weight-medium pa-2 amountDisplay"},[_vm._v(" € "+_vm._s(( "" + (_vm.order ? _vm.order.totalAmount.toFixed(2) : parseInt("0").toFixed(2)) ).replace(".", ","))+" ")]),_c('v-container',{staticClass:"ma-0 pa-1 pl-2 pr-2 containerDisplay"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex flex-column justify-space-between ma-0 pr-1 contentDisplay",attrs:{"cols":"6"}},[_c('v-list',{staticClass:"pa-0"},[_c('v-list-item',[_c('v-list-item-content',{staticClass:"pa-0"},[_c('div',{staticClass:"d-flex"},[_c('v-text-field',{ref:"amountReceived",staticClass:"centered-input title mb-1 mr-1",attrs:{"color":_vm.amountNumber != 0 && _vm.amountTooShort
                        ? 'white'
                        : 'primary',"background-color":_vm.amountNumber != 0 && _vm.amountTooShort ? 'red' : 'unset',"id":"amountReceived","prepend-inner-icon":"mdi-currency-eur","outlined":"","hide-details":true,"autofocus":true,"placeholder":"IN","disabled":_vm.order.totalAmount < 0},model:{value:(_vm.amountReceived),callback:function ($$v) {_vm.amountReceived=$$v},expression:"amountReceived"}}),_c('v-text-field',{staticClass:"centered-input title mb-1 ml-1",attrs:{"prepend-inner-icon":"mdi-currency-eur","outlined":"","hide-details":true,"placeholder":"OUT","disabled":true},model:{value:(_vm.amountReturn),callback:function ($$v) {_vm.amountReturn=$$v},expression:"amountReturn"}})],1),_c('div',{staticClass:"returnChangeKeyboard"},[_c('SimpleKeyboard',{attrs:{"amount":true,"inputName":_vm.amountReceived,"inputValue":_vm.amountReceived,"layout":_vm.amountLayout,"classname":"changekeyboard"},on:{"onChange":_vm.onChange}})],1)])],1)],1),_c('v-btn',{staticClass:"pa-8 title font-weight-medium",attrs:{"color":"#424242"},on:{"click":_vm.returnToOrders}},[_vm._v(" "+_vm._s(_vm.$t("label.selectProducts"))+" ")])],1),_c('v-col',{staticClass:"d-flex flex-column justify-space-between ma-0 pl-1 contentDisplay",attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"order-cash-button black--text headline font-weight-medium",attrs:{"block":"","color":"primary darken-1"},on:{"click":_vm.confirmCashPayment}},[_c('div',{staticClass:"pl-6 pr-6 d-flex maxWidth justify-space-between"},[_c('div',[_c('v-icon',{attrs:{"left":"","x-large":""}},[_vm._v("mdi-account-cash")])],1),_c('div',[_vm._v("Cash")]),_c('div',[_c('v-icon',{attrs:{"right":"","x-large":""}},[_vm._v("mdi-just-to-fill-space")])],1)])]),_c('v-btn',{staticClass:"order-online-button mt-3 black--text headline font-weight-medium",class:{
              'disabled-order-button': !_vm.onlineOrdersEnabled || !_vm.qrCodePayment,
            },attrs:{"disabled":!_vm.onlineOrdersEnabled || !_vm.qrCodePayment,"block":"","color":"primary darken-1"},on:{"click":_vm.confirmQrPayment}},[_c('div',{staticClass:"d-flex flex-column maxWidth pl-6 pr-6"},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',[_c('v-icon',{attrs:{"left":"","x-large":""}},[_vm._v("mdi-qrcode-scan")])],1),_c('div',[_vm._v("QR sticker")]),_c('div',[_c('v-icon',{attrs:{"right":"","x-large":""}},[_vm._v("mdi-just-to-fill-space")])],1)]),(!_vm.onlineOrdersEnabled)?_c('div',{staticClass:"subtitle-1"},[_vm._v(" ("+_vm._s(_vm.$t("message.disabledByTenant"))+") ")]):(!_vm.qrCodePayment)?_c('div',{staticClass:"subtitle-1"},[_vm._v(" ("+_vm._s(_vm.$t("message.noQrCodePayment"))+") ")]):_c('div',{staticClass:"subtitle-1"},[_vm._v(" "+_vm._s(_vm.qrCodePayment.replace(/([a-zA-Z0-9]{2})(?!$)/g, "$1 "))+" ")])])]),_c('v-btn',{staticClass:"order-topupz-button mt-3 black--text headline font-weight-medium",class:{ 'disabled-order-button': _vm.isNoScannerAvailable() },attrs:{"disabled":_vm.isNoScannerAvailable(),"block":"","color":"primary darken-1"},on:{"click":_vm.confirmTopupzPayment}},[_c('div',{staticClass:"d-flex flex-column maxWidth pl-6 pr-6"},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',[_c('v-icon',{attrs:{"left":"","x-large":""}},[_vm._v("mdi-credit-card-scan")])],1),_c('div',[_vm._v("Topupz")]),_c('div',[_c('v-icon',{attrs:{"right":"","x-large":""}},[_vm._v("mdi-just-to-fill-space")])],1)]),(!_vm.isOwningScanner())?_c('div',{staticClass:"subtitle-1"},[_vm._v(" ("+_vm._s(_vm.$t("message.noScannersConfigured"))+") ")]):(_vm.isNoScannerAvailable())?_c('div',{staticClass:"subtitle-1"},[_vm._v(" ("+_vm._s(_vm.$t("message.scannerBusy"))+") ")]):_vm._e()])]),_c('v-btn',{staticClass:"order-bancontact-button mt-3 black--text headline font-weight-medium",class:{
              'disabled-order-button':
                !_vm.onlinePaymentForOrdersEnabled ||
                _vm.order.totalAmount < _vm.minOrderAmountOnlinePayment ||
                _vm.isNoScannerAvailable(),
            },attrs:{"disabled":!_vm.onlinePaymentForOrdersEnabled ||
              _vm.order.totalAmount < _vm.minOrderAmountOnlinePayment ||
              _vm.isNoScannerAvailable(),"block":"","color":"primary darken-1"},on:{"click":_vm.confirmBancontactPayment}},[_c('div',{staticClass:"d-flex flex-column maxWidth pl-6 pr-6"},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',[_c('v-icon',{attrs:{"left":"","x-large":""}},[_vm._v("mdi-credit-card-scan")])],1),_c('div',[_vm._v("Bancontact")]),_c('div',[_c('v-icon',{attrs:{"right":"","x-large":""}},[_vm._v("mdi-just-to-fill-space")])],1)]),(!_vm.onlinePaymentForOrdersEnabled)?_c('div',{staticClass:"subtitle-1"},[_vm._v(" ("+_vm._s(_vm.$t("message.disabledByTenant"))+") ")]):(!_vm.isOwningScanner())?_c('div',{staticClass:"subtitle-1"},[_vm._v(" ("+_vm._s(_vm.$t("message.noScannersConfigured"))+") ")]):(_vm.isNoScannerAvailable())?_c('div',{staticClass:"subtitle-1"},[_vm._v(" ("+_vm._s(_vm.$t("message.scannerBusy"))+") ")]):(_vm.order.totalAmount < _vm.minOrderAmountOnlinePayment)?_c('div',{staticClass:"subtitle-1"},[_vm._v(" (Min € "+_vm._s(_vm.minOrderAmountOnlinePayment.toFixed(2).replace(".", ","))+") ")]):_vm._e()])])],1)],1)],1)],1),_c('SelectScannerDialog',{attrs:{"visible":_vm.scannerDialog,"availableScanners":_vm.availableScanners},on:{"scannerSelected":_vm.confirmPaymentWithScannerSelection,"close":function($event){_vm.scannerDialog = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }